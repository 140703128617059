import React from 'react'
import moment from 'moment'
import PropTypes from "prop-types"

function MessageTimestamp({ date }) {
  return (
    <span>
      {moment(date).format('dddd, MMM Do, YYYY, H:mmA')}
    </span>
  )
}
MessageTimestamp.propTypes = {
  date: PropTypes.any
}
export default MessageTimestamp
