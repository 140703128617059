import React, { useEffect, useState, useContext } from "react";
import FilterCategorySection from "./FilterCategorySection";
import AppliedFilter from "./AppliedFilter";
import SubFilterSection from "./SubFilterSection";
import { AmbassadorContext } from "../pages/Connect";
import classnames from "classnames";
import useWindowSize from "../hooks/useWindowSize";

const Filters = () => {
  //allFilters is an object that holds the Overarching filter like 'service' or 'school' plus the the value which is another object
  //whose key values are subfilters and the specific filters themselves

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [width] = useWindowSize();
  const {
    setCurrentFilters,
    currentFilters,
    subFilters,
    allFilters,
    currentUser
  } = useContext(AmbassadorContext);

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.isAmbassador) {
      setFlag(true);
    }
  }, [currentUser]);

  const removeFilter = ([key, value]) => {
    const stateToChange = { ...currentFilters };
    if (stateToChange[key].length > 1) {
      const updatedArray = stateToChange[key].filter(str => str !== value);
      stateToChange[key] = updatedArray;
    } else {
      delete stateToChange[key];
    }
    setCurrentFilters(stateToChange);
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const applyFiltersClasses = classnames(
    "w-full flex justify-center  text-blue-600 add-pointer-hover mt-1",
    {
      "font-bold": width > 800,
      "text-sm ": width <= 800
    }
  );
  const renderRegularApplyDiv = () => {
    if (filtersOpen && Object.entries(currentFilters).length) return null;
    return (
      <div className={applyFiltersClasses} onClick={() => toggleFilters()}>
        Apply Filters
      </div>
    );
  };
  const mainClasses = classnames(
    "filters-container absolute z-50 flex justify-center pl-2 overflow-scroll h-auto small-margin-top",
    {
      "mt-5": flag,
      "w-3/12 pt-2 ": width > 800,
      "w-4/12 mt-8 pt-0": width <= 800
    }
  );

  const renderAppliedFilters = () => {
    if (!filtersOpen) return null;
    return (
      <div className="w-full flex font-bold text-blue-600 mb-2 add-pointer-hover flex-wrap">
        {Object.entries(currentFilters).map(([category, values]) =>
          values.map((value, j) => (
            <AppliedFilter
              category={category}
              value={value}
              removeFilter={removeFilter}
              key={j}
            />
          ))
        )}
      </div>
    );
  };

  const renderCategories = () => {
    if (!allFilters || subFilters) return null;
    return Object.entries(allFilters).map((filter, i) => (
      <FilterCategorySection label={filter[0]} values={filter[1]} key={i} />
    ));
  };

  const renderSubFilters = () => {
    if (!subFilters) return null;
    return <SubFilterSection key={1} />;
  };

  return (
    <>
      <div className={mainClasses}>
        <div className="filters-box flex shadow bg-white w-full flex-col flex justify-center p-1">
          {renderRegularApplyDiv()}
          {renderAppliedFilters()}

          <div
            className={`filters-expand flex bg-white w-full flex flex-col justify-center ${
              filtersOpen ? "" : "hidden"
            }`}
          >
            <div className="filters-divider mb-1"></div>
            <div className="filter-options w-full">
              {renderCategories()}
              {renderSubFilters()}
              <div
                className="close-filters flex justify-center text-blue-600 add-pointer-hover"
                onClick={() => toggleFilters()}
              >
                Close Filters
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
