import React, { useState, useEffect } from "react";
import EditProfile from "../components/EditProfile";
import ChangeAvailability from "../components/ChangeAvailability";
import RecentActivity from "../components/RecentActivity";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

function ProfileSettings() {
  const [setting, setSetting] = useState("ps");
  const [isLoading, setIsLoading] = useState(true);
  const { type } = useParams();
  useEffect(() => {
    console.log("type: ", type);
    setSetting(type);
    setIsLoading(false);
  }, [type, setIsLoading, setSetting]);
  const renderCorrectInfo = () => {
    if (setting === "ps") {
      return <EditProfile />;
    }
    if (setting === "ra") {
      return <RecentActivity />;
    }
    if (setting === "av") {
      return <ChangeAvailability />;
    } else {
      return <EditProfile />;
    }
  };
  return (
    <Loader isLoading={isLoading}>
      <div className="flex w-full justify-center">
        <div className="w-9/12 pl-6 main-div-padding-alt ">
          {renderCorrectInfo()}
        </div>
      </div>
    </Loader>
  );
}

export default ProfileSettings;
