import React from "react";
import DefaultPin from "../Assets/images/DefaultPin.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../hooks/useAuth";
import PropTypes from "prop-types";

function EditProfileModal({ setPage }) {
  const { setShowNewUserModal } = useAuth();
  const history = useHistory();
  const goToProfilePage = () => {
    setShowNewUserModal(false);
    history.push("/profile_settings");
  };
  return (
    <>
      <div className="flex justify-start">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="add-pointer-hover blue_arrow"
          onClick={() => {
            setPage(1);
          }}
        />
      </div>
      <div className="w-full flex justify-center">
        <img src={DefaultPin} alt="alt" />
      </div>
      <div className="header text-blue-500 font-bold text-xl flex justify-center">
        Privacy Policy
      </div>
      <div>
        <p>
          We can better help match you with Ambassadors with similar backgrounds
          and interests if you add more information to your account profile.
        </p>
        <br />
        <p>
          We take your privacy very seriously and would never share your
          identifiable data outside of the Objective Zero app
        </p>
      </div>
      <div className="flex flex-col items-center p-6">
        <button
          className="bg-oz-blue w-10/12 text-white font-bold p-2 mb-4"
          onClick={goToProfilePage}
        >
          Add to my Profile
        </button>
        <button
          className="bg-oz-blue w-10/12 text-white font-bold p-2"
          onClick={() => setShowNewUserModal(false)}
        >
          No thanks
        </button>
      </div>
    </>
  );
}
EditProfileModal.propTypes = {
  setPage: PropTypes.func
};

export default EditProfileModal;
