import React from "react";
import useErrModal from "../hooks/useErrModal";
import PropTypes from "prop-types";

function ErrorBoundaryWrapper(Component) {
  return function WrappedComponent(props) {
    const errorHandler = useErrModal();
    return <Component {...props} errorHandler={errorHandler} />;
  };
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  render() {
    const errorHandler = this.props.errorHandler;
    if (this.state.hasError) {
      console.log("BOUNDARY:", this.state.error);
      errorHandler(this.state.error);
      return this.props.children;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  errorHandler: PropTypes.func
};

export default ErrorBoundaryWrapper(ErrorBoundary);
