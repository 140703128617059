import React from "react";

function FAQs() {
  return (
    <div>
      <h1 className="text-blue-600 font-bold text-xl">
        Frequently Asked Questions
      </h1>
      <div className="border border-blue-600 mb-8"></div>
      <span className="text-blue-400 font-bold">Q:</span> What is an Ambassador?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Ambassadors are
      volunteer peer supporters who answer phone calls, texts, and/or video
      chats from Veteran and current service member users of the Objective Zero
      mobile app.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Who can become an
      Ambassador?
      <br />
      <span className="text-gray-500 font-bold">A:</span> You do NOT need to be
      a Veteran, current service member, behavioral health professional, or
      counselor in order to become an Objective Zero Ambassador. Anyone can
      become an Objective Zero Ambassador who: is over 18 years of age; has not
      been charged with sexual assault or related misconduct; and is not
      otherwise deemed unfit to provide suicide prevention support to Objective
      Zero users.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> How do I become an
      Ambassador?
      <br />
      <span className="text-gray-500 font-bold">A:</span> All Objective Zero
      Ambassadors must complete the online Objective Zero Ambassador Training
      Program and complete the Ambassador registration form. The training and
      registration can be accessed on Objective Zero&apos;s OpenLearning portal:
      www.objectivezero.org/become-an-ambassador
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Why should I become an
      Ambassador?
      <br />
      <span className="text-gray-500 font-bold">A:</span> The Objective Zero
      Foundation was founded on the belief that the simple act of listening can
      go a long way in helping veterans, their families, and caregivers.
      Sometimes all a person needs is to be heard, without the fear of judgement
      or stigma.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What resources are
      available for Ambassadors?
      <br />
      <span className="text-gray-500 font-bold">A:</span> In addition to the
      Objective Zero Ambassador Training Program, Ambassadors have access to a
      host of additional online training classes, thanks to our partnership with
      PsychArmor, a leading provider of online training classes for military
      caregivers. You can access our catalogue of PsychArmor courses at the
      following link: www.objectivezero.org/ambassador-training Courses include:
      Introduction to PTSD and Depression, Military 101, Military Substance
      Abuse, and more!
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What is expected of
      Ambassadors?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Ambassadors must first
      take our online training, available at
      www.objectivezero.org/become-an-ambassador or upon registration within the
      mobile app. After training is complete, Ambassadors are expected to field
      texts, calls, and video chats as their schedule allows.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Do Ambassadors receive
      paid compensation?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Ambassadors do not
      receive paid compensation. However, Objective Zero is a member of the
      Presidential Volunteer Award Program. Ambassadors can track their
      volunteer hours and submit them to volunteer@objectivezero.org for credit
      toward earning a Presidential Volunteer Award, a prestigious award looked
      upon favorably by many employers.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Can I become an
      Ambassador without a smartphone?
      <br />
      <span className="text-gray-500 font-bold">A:</span> At this time,
      Ambassadors must have a smartphone in order to connect to Objective Zero
      users. However, you can take the training and register to be an Ambassador
      online (www.objectivezero.org/become-an-ambassador). We plan to develop an
      online/desktop capability and will notify all Objective Zero users and
      Ambassadors when we do so.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Are there different
      Ambassador levels?
      <br />
      <span className="text-gray-500 font-bold">A:</span> At this time, there is
      only one level of Ambassador: A Certified Objective Zero Ambassador.
      However, we offer additional training that will give you additional
      skills. This training can be accessed at
      www.objectivezero.org/ambassador-training. In the future, we plan to award
      badges to Ambassadors who complete these additional trainings and create
      higher Ambassador levels. Additional training taken now will be counted
      retroactively toward progress to these levels.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> How do I know if/when
      I need to refer a user to call 911?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Ambassadors should
      refer a user to 911 or the Veterans Crisis Line (1-800-273-8255) if they
      believe the user is in imminent danger of hurting himself or herself or
      someone else. Ultimately, Ambassadors must use their best judgement,
      informed by the Objective Zero Ambassador Training they&apos;ve completed,
      to determine whether a user is in imminent danger. Warning signs could
      include but certainly are not limited to: if a user tells you they&apos;re
      thinking about (or are planning to) kill themselves; if a user makes
      comments such as, &ldquo;My family would be better off if I were
      dead.&ldquo; or &ldquo;I have no reason to live.&ldquo;; or if I user
      tells you they have a weapon or other lethal means near them. It&apos;s
      important to remember that while the aforementioned are obvious warning
      signs, many other signs may be much subtler. Pay close attention to tone
      and don&apos;t be afraid to ask them directly if they are thinking about
      killing themselves.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What happens if
      someone harms themselves while I&apos;m on the phone with them?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Contact 911
      immediately. If the user is still conscious, reassure them and gather as
      much information as possible to direct emergency personnel to them.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Can an Ambassador
      connect a user to 911 or other crisis support from the Objective Zero
      mobile app?
      <br />
      <span className="text-gray-500 font-bold">A:</span> No. However,
      Ambassadors can connect a user to 911 via their mobile phone&apos;s native
      phone features. Simply minimize the Objective Zero app, call 911, and
      place the phone on speaker. This will create a three-way call between you,
      the user, and the 911 dispatcher.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What should I do if
      the user doesn&apos;t want to be connected to 911 or the Veterans Crisis
      Line?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Do your best to
      persuade them into getting connected to emergency support. If they persist
      in their refusal to do so, try to get information about where they are
      located. Then, call 911 and direct first responders to their location.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> My malpractice
      insurance doesn&apos;t cover me outside my job; What&apos;s my liability
      volunteering?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Per the Objective Zero
      Terms of Use, Ambassadors act as good samaritans, not as caregivers, when
      interacting with users. Nevertheless, if you are a licensed caregiver,
      please consult your respective licensure rules, restrictions, and
      regulations.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What should I do if a
      user becomes violent or abusive with me?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Refer the user to the
      Veterans Administration&apos;s Crisis Line (1-800-273-8255), disconnect
      from the user, and send an email to contactus@objectivezero.org with the
      user&apos;s username and a description of the interaction.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What should I do if a
      user wants to meet up with me in person?
      <br />
      <span className="text-gray-500 font-bold">A:</span> It is plausible that
      an Ambassador and user may establish a close personal bond and want to
      meet in person. We discourage meeting with users in person and assume no
      liability if you choose to do so. If you chose to do so, we recommend you
      meet in a public place and have a friend accompany you to the meet up.
      Such caution is a good security practice, whether meeting up with an
      Objective Zero user or anyone from another social platform.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What should I do if a
      user asks me for money?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Never give a user
      money. Instead, direct them to Veteran services in their area (see
      Ambassador resource guide for a list of resources).
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Why is the content in
      the Activities tab reserved for Veterans and current military service
      members?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Objective Zero is
      first and foremost an app intended to eliminate Veteran and current
      military service member suicide, so we tailor our services to help those
      populations. Additionally, we paid for access to the content in the
      Activities tab with money collected from donors who wanted to ensure their
      money was going to support Veterans. Restricting access to the content to
      military servicemembers and Veterans ensures we meet this obligation.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Why do we require
      proof of military service to access content in the Activities tab?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Under agreement with
      the content providers, Objective Zero must restrict access to the content
      to those with military service. In order to meet this requirement and
      facilitate verification, we integrated ID.me, a cutting-edge military
      service verification technology.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Who can become an
      Objective Zero user?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Objective Zero is
      intended for Veterans and their families; current military service members
      of the Active, Reserve, and National Guard components and their family
      members; and military caregivers. However, you do not need an affiliation
      with the military to become and Ambassador.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> How do I become an
      Objective Zero user?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Download the app from
      the App Store (iOS) or Google Play (Android) and sign-up as a user.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What happens if an
      Objective Zero Ambassador isn&apos;t available on the network?
      <br />
      <span className="text-gray-500 font-bold">A:</span> The app will first
      attempt to connect you to an Ambassador. In the unlikely event that there
      aren&apos;t enough Ambassadors on the network, the app will connect you to
      Vets4Warriors, a Veteran nonprofit call center staffed with Veterans and
      other trained peers that will be able to assist you. In the unlikely event
      Vets4Warriors is unable to field your call, the app will transfer you to
      the Veteran Administration&apos;s Crisis Line.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Why did the Objective
      Zero app unexpectedly switch from video chat to voice chat?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Video chat capability
      is based on the bandwidth strength, or signal, of your mobile phone. In
      instances where your bandwidth decreases below an acceptable level to
      execute the video chat function, the application will automatically switch
      to voice chat, which uses less bandwidth, until you reach an area with a
      sufficient bandwidth. As a note, users in rural areas may experience
      difficulties accessing the video chat function due to generally low
      bandwidth availability in these areas.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> I&apos;m thinking
      about hurting myself or someone else. Should I contact an Ambassador?
      <br />
      <span className="text-gray-500 font-bold">A:</span> If you are thinking
      about hurting yourself or someone else or are about to do so, call 911
      immediately. Objective Zero Ambassadors should not be consulted in
      emergencies.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Why can&apos;t I find
      any Ambassadors in my area?
      <br />
      <span className="text-gray-500 font-bold">A:</span> The Objective Zero
      Ambassador is rapidly expanding, but there may be some areas where we do
      not have Ambassadors. If you&apos;re unable to find an Ambassador in your
      area, check your Ambassador search settings and reduce the number of
      filters to broaden your search. For instance, if you&apos;re unable to
      find an Ambassador with a very specific profile, such as a Marine Veteran
      of OEF with the MOS of 0202 who is a female between the ages of 21 and 22,
      try searching for a female Marine Veteran of OEF. This will greatly
      increase the chances of connecting with an Ambassador, since there likely
      are more Ambassadors who fit the latter profile and the former.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Is Objective Zero a
      certified nonprofit?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Yes, Objective Zero is
      a certified 501(c)(3) nonprofit. Our EIN is: 81-4324563. You can view our
      Guide Star profile at: https://www.guidestar.org/profile/81-4324563
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Who founded Objective
      Zero?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Objective Zero was
      founded by US Army Veteran and Infantry officer Chris Mercado and his
      classmates at Georgetown University. Learn more about the Objective Zero
      team and our story: http://www.objectivezero.org/about
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Is Objective Zero a
      government entity?
      <br />
      <span className="text-gray-500 font-bold">A:</span> No. Objective Zero is
      an independent nonprofit organization. However, we do partner with
      government entities in furtherance of our mission. To learn more about our
      partners, visit: http://www.objectivezero.org/our-partners
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What percentage of
      donations go to fund Objective Zero staff salaries?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Currently, the
      Objective Zero staff is made up entirely of unpaid volunteers.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Is my donation to
      Objective Zero tax deductible?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Yes. All donations are
      tax deductible because we are a certified 501(c)(3) nonprofit. Be sure to
      use our EIN (81-4324563) on your tax forms.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Isn&apos;t Objective
      Zero just a suicide prevention hotline?
      <br />
      <span className="text-gray-500 font-bold">A:</span> No. Objective Zero is
      the first-of-its-kind interactive mobile technology that connects users to
      a support network of trained peers, suicide prevention resources, and free
      wellness and motivational activities known to reduce stress, anxiety, and
      depression. Moreover, Objective Zero enables the user to filter
      Ambassadors by service component, service status (Active, Reserve,
      Veteran, etc.), sex, age, location, and more, empowering users to connect
      to someone they feel comfortable with. Users of call centers, on the other
      hand, are stuck with whomever answers the phone, many times a paid
      employee with little in common with the caller, and they don&apos;t get
      access to interactive resources and content. Even more, unlike the
      Objective Zero platform&apos;s voice, video, and text communication
      features, call centers usually only allow for voice communication, with a
      limited number allowing for text communication. Unfortunately, call
      centers can also sometimes be overwhelmed or forced to send calls to
      back-up centers. Veteran and servicemember call centers such as
      Vets4Warrriors and the VA Crisis Line are vital partners and leaders in
      our work to save lives, and direct links to these hotlines will be
      available via the Objective Zero App. The Objective Zero App fills gaps in
      call center capabilities, by empowering anyone who cares about our
      Veterans to become a part of a national community of support. Suicide
      prevention hotlines are essential, but they can not do this job alone.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> I don&apos;t own a
      smartphone, can I still use Objective Zero?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Currently, the
      Objective Zero platform is only available on iOS and Android smartphones.
      We plan to create an online/desktop platform in the near future and will
      let you know when we do.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Does Objective Zero
      share my information with third parties?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Objective Zero will
      never share any of your information with third parties without your
      permission. Objective Zero may use user data to improve the functionality
      and performance of the app.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Is my personal
      information secure?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Yes. Objective Zero
      only uses data storage services that meet or exceed the leading industry
      standards.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Can I remain anonymous
      on Objective Zero?
      <br />
      <span className="text-gray-500 font-bold">A:</span> Yes. The default
      setting for user profiles is anonymous, meaning Ambassadors and other
      users cannot see your personal information. Users can choose to
      de-anonymize their profile, in their settings, if they choose to do so.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> Will Objective Zero
      tell my chain of command or family that I&apos;m using the app?
      <br />
      <span className="text-gray-500 font-bold">A:</span> No. Objective Zero
      will never disclose the identities of its users.
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> How can I submit
      feedback to the Objective Zero team?
      <br />
      <span className="text-gray-500 font-bold">A:</span> You can send us an
      email at contactus@objectivezero.org
      <br />
      <br />
      <span className="text-blue-400 font-bold">Q:</span> What kind of feedback
      is Objective Zero interested in?
      <br />
      <span className="text-gray-500 font-bold">A:</span> We&apos;re always
      interested in hearing about ways we can improve the app, including new
      resources, activities, and features. We&apos;re also interested in hearing
      about user experiences, including things you like about the app and things
      you think could be improved. Additionally, we want to know if you feel
      like you have been mistreated by an Objective Zero user or if you have
      been exposed by offensive or misleading information or any situation,
      including emergencies, you deem significant.
      <br />
      <br />
      <br />
    </div>
  );
}

export default FAQs;
