import React, { useState } from "react";
import useAPI from "../hooks/useAPI";
import customToast from "./customToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function AmbassadorQuestionnaire({ setPart }) {
  const [subtext, setSubtext] = useState();
  const api = useAPI();
  const checkResults = e => {
    e.preventDefault();
    let pass = true;
    const form = e.target;
    const q1 = form.elements["q1"];
    const q2 = form.elements["q2"];
    const q3 = form.elements["q3"];
    const q4 = form.elements["q4"];
    const q5 = form.elements["q5"];
    const q6 = form.elements["q6"];

    if (q1[1].checked) {
      pass = false;
    }
    if (!q2[2].checked) {
      pass = false;
    }
    if (q3[0].checked) {
      pass = false;
    }
    if (!q4[5].checked) {
      pass = false;
    }
    if (q5[1].checked) {
      pass = false;
    }
    if (q6[1].checked) {
      pass = false;
    }

    if (pass) {
      api
        .post("user/ambassador", {})
        .then(() => {
          customToast.info(
            "Welcome aboard! Some additional resources have been sent to you!"
          );
          setPart(3);
        })
        .catch(err => {
          console.log(err);
          customToast.warn(
            "So sorry, something went wrong, please try again later!"
          );
        });
    } else {
      setSubtext("*One or more answers was incorrect please try again");
    }
  };
  return (
    <div className="section-holder flex flex-col pt-4">
      <div className="flex justify-between">
        <div
          className="text-blue-600 add-pointer-hover"
          onClick={() => setPart(1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="grey" /> Back to Video
        </div>
        <h1 className="font-bold text-blue-600 mt-2 text-xl">Questionnaire</h1>
        <div></div>
      </div>
      <div className="pl-20">
        <form onSubmit={checkResults}>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>True or
              False: You should be direct when asking a veteran if they&apos;re
              thinking about killing themselves.
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q1"
                  value="true"
                  id="q1True"
                  className="mr-4"
                />
                <label htmlFor="q1True" className="mr-4">
                  True
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q1"
                  value="false"
                  id="q1False"
                  className="mr-4"
                />
                <label htmlFor="q1False">False</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>What
              should you do if a veteran tells you they&apos;re ready to kill
              themselves
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q2"
                  value="a"
                  id="q2A"
                  className="mr-4"
                />
                <label htmlFor="q2A" className="mr-4">
                  Ignore them
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q2"
                  value="b"
                  id="q2B"
                  className="mr-4"
                />
                <label htmlFor="q2B">
                  Tell them their problems aren&apos;t big enough
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q2"
                  value="c"
                  id="q2C"
                  className="mr-4"
                />
                <label htmlFor="q2C">
                  Connect the Veteran to 911 or the Veterans Crisis Line
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q2"
                  value="d"
                  id="q2D"
                  className="mr-4"
                />
                <label htmlFor="q2D">
                  Tell them to sleep it off; things will get better
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q2"
                  value="e"
                  id="q2E"
                  className="mr-4"
                />
                <label htmlFor="q2E">Hang up on them</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>True or
              False: Veterans who contemplate suicide or attempt it are weak.
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q3"
                  value="true"
                  id="q3True"
                  className="mr-4"
                />
                <label htmlFor="q3True" className="mr-4">
                  True
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q3"
                  value="false"
                  id="q3False"
                  className="mr-4"
                />
                <label htmlFor="q3False">False</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>What are
              warning signs that a veteran may be thinking about killing
              themselves?
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="a"
                  id="q4A"
                  className="mr-4"
                />
                <label htmlFor="q4A" className="mr-4">
                  Exercising More
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="b"
                  id="q4B"
                  className="mr-4"
                />
                <label htmlFor="q4B">Becoming easily irritable</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="c"
                  id="q4C"
                  className="mr-4"
                />
                <label htmlFor="q4C">Giving away prized possessions</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="d"
                  id="q4D"
                  className="mr-4"
                />
                <label htmlFor="q4D">
                  Making comments like, &quot;You&apos;d be better off if I were
                  dead.&quot;
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="e"
                  id="q4E"
                  className="mr-4"
                />
                <label htmlFor="q4E">Unexplained mood swings</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q4"
                  value="f"
                  id="q4F"
                  className="mr-4"
                />
                <label htmlFor="q4F">All, except the first one</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>True or
              False: You should ALWAYS take a veteran seriously if they tell you
              they are thinking about killing themselves.
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q5"
                  value="true"
                  id="q5True"
                  className="mr-4"
                />
                <label htmlFor="q5True" className="mr-4">
                  True
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q5"
                  value="false"
                  id="q5False"
                  className="mr-4"
                />
                <label htmlFor="q5False">False</label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <span className="text-blue-600 font-bold mr-4">Q:</span>True or
              False: Firearms are the top method by which veterans commit
              suicide.
            </div>
            <div className=" pl-8 flex flex-col">
              <div>
                <input
                  type="radio"
                  name="q6"
                  value="true"
                  id="q6True"
                  className="mr-4"
                />
                <label htmlFor="q6True" className="mr-4">
                  True
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="q6"
                  value="false"
                  id="q6False"
                  className="mr-4"
                />
                <label htmlFor="q6False">False</label>
              </div>
            </div>
          </div>
          <div className="text-red-500 w-full">{subtext ? subtext : ""}</div>
          <div className="flex justify-center">
            <input
              type="submit"
              value="Submit"
              className="bg-oz-blue button-width-600 hover:bg-blue-700 flex justify-center rounded  w-2/12 text-white add-pointer-hover p-2 font-bold"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
AmbassadorQuestionnaire.propTypes = {
  setPart: PropTypes.func
};
export default AmbassadorQuestionnaire;
