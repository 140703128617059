import React, { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import DayAvailable from "./DayAvailable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import customToast from "./customToast";
import TimeRangeSlider from "react-time-range-slider";
import moment from "moment";
function ChangeAvailability() {
  const api = useAPI();
  const today = moment();

  const [userData, setUserData] = useState({
    days: [],
    time_range_end: 0,
    time_range_start: 0
  });
  const [isTextAvailable, setIsTextAvailable] = useState(false);
  const [isVoiceAvailable, setIsVoiceAvailable] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  //   const [sliderValues, setSliderValues] = useState([12, 44]);
  const [sliderValues, setSliderValues] = useState({
    start: "09:00",
    end: "17:00"
  });
  const daysArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  useEffect(() => {
    api
      .get("user")
      .then(r => {
        const data = r.data;
        const ob = {
          days: data.days_available.split(","),
          time_range_end: moment()
            .startOf("day")
            .seconds(data.time_range_end)
            .format("HH:mm"),
          time_range_start: moment()
            .startOf("day")
            .seconds(data.time_range_start)
            .format("HH:mm")
        };
        setUserData(ob);
        if (data.is_available_textChat === "1") {
          setIsTextAvailable(true);
        }
        if (data.is_available_voiceCall === "1") {
          setIsVoiceAvailable(true);
        }
        if (data.is_available_videoCall === "1") {
          setIsVideoAvailable(true);
        }
        setSliderValues({ start: ob.time_range_start, end: ob.time_range_end });
      })
      .catch(err => {
        console.log(err);
      });
  }, [api]);

  const saveUserInfo = () => {
    const dataToBeSaved = {
      days_available: userData.days.join(","),
      time_range_start: moment(sliderValues.start, "HH:mm:ss").diff(
        today.startOf("day"),
        "seconds"
      ),
      time_range_end: moment(sliderValues.end, "HH:mm:ss").diff(
        today.startOf("day"),
        "seconds"
      ),
      is_available_textChat: isTextAvailable ? 1 : 0,
      is_available_voiceCall: isVoiceAvailable ? 1 : 0,
      is_available_videoCall: isVideoAvailable ? 1 : 0
    };
    api.patch("user", dataToBeSaved).then(() => {
      customToast.info("Your Information has been saved!");
    });
  };
  const dayInState = name => {
    if (userData.days.includes(name)) {
      return true;
    } else {
      return false;
    }
  };
  const removeOrAddDayToState = name => {
    if (userData.days.includes(name)) {
      const arrayDuplicate = userData.days;
      const index = arrayDuplicate.indexOf(name);
      arrayDuplicate.splice(index, 1);
      const stateToChange = { ...userData };
      stateToChange.days = arrayDuplicate;
      setUserData(stateToChange);
    } else {
      const daysObj = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6
      };
      const arrayDuplicate = userData.days;
      arrayDuplicate.splice(daysObj[name], 0, name);
      const stateToChange = { ...userData };
      stateToChange.days = arrayDuplicate;
      setUserData(stateToChange);
    }
  };
  const timeChangeHandler = time => {
    setSliderValues(time);
  };
  const returnAMOrPM = t => {
    const num = Number(t.split(":")[0]);
    if (num < 12 && num !== 0) {
      return `${t} AM`;
    } else {
      if (num > 12) {
        return `${num - 12}:${t.split(":")[1]} PM`;
      }
      if (num === 12) {
        return `12:${t.split(":")[1]} PM`;
      }
      if (num === 0) {
        return `12:${t.split(":")[1]} AM`;
      }
    }
  };

  return (
    <div className="mb-10">
      <div className="flex justify-between">
        <h1 className="text-blue-600 font-bold text-xl flex items-end">
          Availability
        </h1>
      </div>
      <div className="border border-blue-600 mb-8"></div>
      <div>
        <div className="section-holder flex flex-col">
          <h1 className="font-bold text-blue-600 mt-2">Day Available</h1>
          <div className="pl-20 less-padding">
            {daysArray.map((day, i) => (
              <DayAvailable
                name={day}
                key={i}
                removeOrAddDayToState={removeOrAddDayToState}
                dayInState={dayInState}
              />
            ))}
          </div>
        </div>
        <div className="border border-blue-600 mb-8 mt-8"></div>
        <div className="section-holder flex flex-col">
          <h1 className="font-bold text-blue-600 mt-2">Time Available</h1>
          <div className="mb-6">
            <div className="w-full flex justify-center">
              <div className="start_time mb-4">
                {returnAMOrPM(sliderValues.start)}
              </div>
              <div className="border-b-4 border-blue-600 w-3/12 h-3 mr-2 ml-2"></div>
              <div className="end_time">{returnAMOrPM(sliderValues.end)}</div>
            </div>
            <TimeRangeSlider
              disabled={false}
              format={24}
              maxValue={"23:59"}
              minValue={"00:00"}
              name={"time_range"}
              onChange={timeChangeHandler}
              step={15}
              value={sliderValues}
            />
          </div>
        </div>
        <div className="border border-blue-600 mb-8 mt-8"></div>
        <div className="section-holder flex flex-col">
          <h1 className="font-bold text-blue-600 mt-2">Modes Available</h1>
          <div className="pl-20 less-padding">
            <div
              className="font-bold text-gray-600 border-b-2 border-gray-200 flex justify-between mb-2"
              onClick={() => setIsTextAvailable(!isTextAvailable)}
            >
              Available for Text Chat
              {isTextAvailable ? (
                <FontAwesomeIcon icon={faCheck} color="green" size="sm" />
              ) : (
                ""
              )}
            </div>
            <div
              className="font-bold text-gray-600 border-b-2 border-gray-200 flex justify-between mb-2"
              onClick={() => setIsVoiceAvailable(!isVoiceAvailable)}
            >
              Available for Voice Calls
              {isVoiceAvailable ? (
                <FontAwesomeIcon icon={faCheck} color="green" size="sm" />
              ) : (
                ""
              )}
            </div>
            <div
              className="font-bold text-gray-600 border-b-2 border-gray-200 flex justify-between mb-2"
              onClick={() => setIsVideoAvailable(!isVideoAvailable)}
            >
              Available for Video Calls
              {isVideoAvailable ? (
                <FontAwesomeIcon icon={faCheck} color="green" size="sm" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div
            className="m-4 w-30 p-2 flex justify-center rounded bg-oz-blue text-white font-bold hover:bg-blue-700 add-pointer-hover"
            onClick={saveUserInfo}
          >
            SAVE INFO
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeAvailability;
