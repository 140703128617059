import { useState, useEffect } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import useAuth from "./useAuth";

function useWebSockets() {
  const { authToken, userID } = useAuth();
  const [ws, setWS] = useState();
  useEffect(() => {
    if (!authToken || !userID) return;
    const rws = new ReconnectingWebSocket(
      "wss://" +
        process.env.REACT_APP_WS_URL +
        "/ws/" +
        `${userID}/` +
        `${authToken}`
    );
    rws.timeout = 1000;
    rws.addEventListener("open", () => {
      console.log("[CLIENT] connected to ws");
    });

    rws.addEventListener("close", () => {
      console.log("[CLIENT], server disconnected");
    });
    rws.onerror = err => {
      console.log("[CLIENT] error with websocket", err);
    };
    setWS(rws);
  }, [authToken, userID]);
  return [ws, setWS];
}

export default useWebSockets;
