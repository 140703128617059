import StaticModalBase from "./StaticModalBase";
import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import CreateHandle from "./CreateHandle";
import EditProfileModal from "./EditProfileModal";

function NewUserModal() {
  const [page, setPage] = useState(1);
  const { showNewUserModal, setShowNewUserModal } = useAuth();

  const returnCorrectPage = () => {
    if (page === 2) {
      return <EditProfileModal setPage={setPage} />;
    } else {
      return <CreateHandle setPage={setPage} />;
    }
  };

  return (
    <StaticModalBase show={showNewUserModal} setShow={setShowNewUserModal}>
      <div className="p-8">{returnCorrectPage()}</div>
    </StaticModalBase>
  );
}

export default NewUserModal;
