import { setup } from "axios-cache-adapter";

import useAuth from "./useAuth";
import { useEffect } from "react";

let apis = {

}

function useAPI(version = 1) {
  const { authToken } = useAuth();
  let baseURL = process.env.REACT_APP_API_BASE_URL;
  if (version > 0) baseURL += `/v${version}/`;
  useEffect(()=> {
    if(!authToken){
      apis={}
    }
  },[authToken])
  const http = setup({
    baseURL: baseURL,
    headers: { access_token: authToken },
    cache: {
      readHeaders: true,
      exclude: { query: false }
    }
  });
  if(version >1){
    return http
  }else {
    if(authToken){
      if(!apis[version]){
        apis[version] = http
        return http
      }
      else {
        return apis[version]
      }
    }
  }
}

export default useAPI;
