import { useCallback } from "react";
import useAuth from "./useAuth";
function useErrModal() {
  const { setErr, setShowErrorModal } = useAuth();

  return useCallback(
    err => {
      let message =
        "something went wrong. Try logging back in, if this issue persists email us at support@objectivezero.org";
      if (err.response && err.response.status && err.response.status === 401) {
        message =
          "Due to security, you're only allowed to be logged into one device. Please log out and log back into this device to use it.";
      }
      console.log(err);
      setErr(message);
      setShowErrorModal(true);
    },
    [setErr, setShowErrorModal]
  );
}

export default useErrModal;
