import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function DayAvailable({ name, removeOrAddDayToState, dayInState }) {
  return (
    <div
      className="font-bold text-gray-600 border-b-2 border-gray-200 flex justify-between mb-2"
      onClick={() => removeOrAddDayToState(name)}
    >
      {name}
      {dayInState(name) ? (
        <FontAwesomeIcon icon={faCheck} color="green" size="sm" />
      ) : (
        ""
      )}
    </div>
  );
}
DayAvailable.propTypes = {
  name: PropTypes.string,
  removeOrAddDayToState: PropTypes.func,
  dayInState: PropTypes.func
};

export default DayAvailable;
