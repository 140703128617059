import React, { useEffect, useState } from "react";
import ModalBase from "./ModalBase";

function OnMobileModal() {
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [hasExited, setHasExited] = useState(false);
  useEffect(() => {
    if (hasExited) return;
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setAppUrl(
        "https://play.google.com/store/apps/details?id=org.objectivezero.app"
      );
      setShowMobileModal(true);
    }

    if (/android/i.test(userAgent)) {
      setAppUrl(
        "https://play.google.com/store/apps/details?id=org.objectivezero.app"
      );
      setShowMobileModal(true);
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setAppUrl("https://apps.apple.com/us/app/objective-zero/id1304706914");
      setShowMobileModal(true);
    }
  }, [hasExited]);

  return (
    <ModalBase show={showMobileModal} setShow={setShowMobileModal} mt={48}>
      <div className="w-full flex justify-end h-2 pr-2 mb-4">
        <div
          className="add-pointer-hover"
          onClick={() => {
            setShowMobileModal(false);
            setHasExited(true);
          }}
        >
          x
        </div>
      </div>
      <div className="p-6 pt-0 text-center">
        To better improve your experience please download the mobile version of
        this app by clicking the button below or exit to continue to the web
        version.
        <div className="mt-4">
          <a
            className="rounded bg-oz-blue text-white p-2 text-center mt-4"
            href={appUrl}
          >
            Go To App Store
          </a>
        </div>
      </div>
    </ModalBase>
  );
}

export default OnMobileModal;
