import React from "react";
import ModalBase from "./ModalBase.js";
import useAuth from "../hooks/useAuth.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function ErrorModal({ err }) {
  const { showErrorModal, setShowErrorModal } = useAuth();
  return (
    <ModalBase show={showErrorModal} setShow={setShowErrorModal}>
      <div className="warning-container ">
        <div className="flex justify-end pr-2 w-full">
          <div
            className="add-pointer-hover"
            onClick={() => setShowErrorModal(false)}
          >
            X
          </div>
        </div>
        <div className="warning-box flex shadow p-8 rounded">
          <div className="triangle-container">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="4x"
              className="triangle-icon"
            />
          </div>
          <div className="error-message-container pt-4 pl-4">
            <p>{err.message ? err.message : err}</p>
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
ErrorModal.propTypes = {
  err: PropTypes.object
};
export default ErrorModal;
