import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import call from "../Assets/images/phone.png";
import messages from "../Assets/images/messages_inactive.png";

function RecentActivityCard({ act, userId }) {
  const [cardInfo, setCardInfo] = useState({ name: "", time: "", date: "" });
  useEffect(() => {
    if (act.sender_id === userId) {
      const timestamp = getDateFromTimeStamp(Number(act.call_start_time)).split(
        " "
      );
      const obj = {
        name: act.receiver_name || act.receiver_username,
        time: timestamp[1],
        date: timestamp[0]
      };
      setCardInfo(obj);
    }
    if (act.receiver_id === userId) {
      const timestamp = getDateFromTimeStamp(Number(act.call_start_time)).split(
        " "
      );
      const obj = {
        name: act.sender_name || act.sender_username,
        time: timestamp[1],
        date: timestamp[0]
      };
      setCardInfo(obj);
    }
  }, [act, userId]);
  const getDateFromTimeStamp = timestamp => {
    const time = moment(timestamp).format("MM/DD/YYYY hh:mm:ssa");
    return time;
  };
  return (
    <div className="flex justify-between shadow p-6 m-2 w-10/12 ml-4">
      <div>
        <img src={act.type === "voice" ? call : messages} className="w-8" />
      </div>
      <div>{cardInfo.name}</div>
      <div>{cardInfo.time}</div>
    </div>
  );
}
RecentActivityCard.propTypes = {
  act: PropTypes.object,
  userId: PropTypes.string
};
export default RecentActivityCard;
