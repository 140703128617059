import React, { useEffect } from "react";
import Loader from "../components/Loader";
import ErrorHandler from "../components/ErrorHandler";
import useResourceAPI from "../hooks/useResourceAPI";
import SimilarCard from "../components/SimilarCard";
import Image from "../components/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { errorImage } from "../constants/imageUrls";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import useWindowSize from "../hooks/useWindowSize";
import "./details.css";
import classnames from "classnames";
const widthSwitch = 780;

function ResourceDetails(props) {
  const { type, id } = useParams();
  const [width] = useWindowSize();
  const [resource, similarSources, isLoading, err] = useResourceAPI(type, id);
  useEffect(() => {}, [id, type]);

  const rightClasses = classnames("ml-2", {
    "right-details": width > widthSwitch,
    "top-details": width <= widthSwitch
  });
  const leftClasses = classnames("", {
    "left-details": width > widthSwitch,
    "bottom-details": width <= widthSwitch
  });
  const detailsClasses = classnames("", {
    "details-container": width > widthSwitch,
    "details-container-flex": width <= widthSwitch
  });
  const similarClasses = classnames("", {
    "similar-cards-container": width > widthSwitch,
    "similar-cards-container-alt": width <= widthSwitch
  });

  //switched left and right for ui/ux
  return (
    <>
      <ErrorHandler err={err}>
        <Loader isLoading={isLoading}>
          <div className="flex">
            <div className="arrow-container">
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => props.history.push("/resources")}
                color="grey"
              />
            </div>
            <div className={detailsClasses}>
              <div className={rightClasses}>
                <div className="details-header-container no-padding">
                  <div
                    className="resource-header no-margin add-pointer-hover"
                    onClick={() => props.history.push("/resources")}
                  >
                    {resource.name || resource.title}
                  </div>
                </div>
                <div className="">
                  <div className="details-photo-container shadow shadow-md">
                    <Image
                      url={resource.image}
                      defaultUrl={errorImage}
                      classNames={`resource-details-image`}
                    />
                  </div>
                  <div
                    className="details-description-container"
                    id="inner-html-description"
                  >
                    <p
                      dangerouslySetInnerHTML={{ __html: resource.description }}
                    />
                  </div>
                </div>
              </div>
              <div className={leftClasses}>
                <div className="details-info-container">
                  <div className="resource-header mr-6">Similar Activities</div>
                </div>
                <div className={similarClasses}>
                  {similarSources.map(source => (
                    <SimilarCard
                      activity={source}
                      key={source.id}
                      detailsId={id}
                      width={width}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </ErrorHandler>
    </>
  );
}
ResourceDetails.propTypes = {
  history: PropTypes.object
};
export default ResourceDetails;
