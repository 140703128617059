import React from "react";
import settingsIcon from "../Assets/images/settingsIcon.png";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const SettingsDropDown = ({ showDropDown, setShowDropDown, logout }) => {
  const history = useHistory();
  const menuClass = classNames(
    "absolute right-0 mr-4 bg-white mt-2 rounded-lg customZ text-gray-800 shadow shadow-xl w-64 py-2",
    { hidden: !showDropDown }
  );
  const expandMenu = id => {
    let closeMenu = false;
    const el = document.getElementById(id);
    if (!el.classList.contains("hidden")) {
      closeMenu = true;
    }
    const els = document.querySelectorAll(".shadowInner");
    els.forEach(el => el.classList.add("hidden"));
    if (!closeMenu) {
      el.classList.remove("hidden");
    }
  };
  return (
    <div className="relative">
      <button
        className="block h-7/12 w-7/12 flex justify-center rounded-full focus:outline-none"
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <img src={settingsIcon} className="help-icon w-12/12" alt="settings" />
      </button>
      <div className={menuClass}>
        <div
          className="block px-4 py-2 hover:bg-blue-700 hover:text-white cursor-pointer"
          onClick={() => expandMenu("1")}
        >
          Settings
        </div>
        <div className="shadowInner hidden" id="1">
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/profile_settings/ps")}
          >
            Profile Settings
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/profile_settings/ra")}
          >
            Recent Activity
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/profile_settings/av")}
          >
            Change Availability
          </div>
        </div>
        <div
          className="block px-4 py-2 hover:bg-blue-700 hover:text-white cursor-pointer"
          onClick={() => history.push("/help/ba")}
        >
          Become an Ambassador
        </div>
        <div
          className="block px-4 py-2 hover:bg-blue-700 hover:text-white cursor-pointer"
          onClick={() => expandMenu("2")}
        >
          About
        </div>
        <div className="shadowInner hidden" id="2">
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/about/oz")}
          >
            Objective Zero
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/about/pp")}
          >
            Privacy Policy
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/about/tc")}
          >
            Terms & Conditions
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/about/sl")}
          >
            Software License
          </div>
        </div>
        <div
          className="block px-4 py-2 hover:bg-blue-700 hover:text-white cursor-pointer"
          onClick={() => expandMenu("3")}
        >
          Help
        </div>
        <div className="shadowInner hidden" id="3">
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/help/cu")}
          >
            Contact Us
          </div>
          <div
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
            onClick={() => history.push("/help/fa")}
          >
            FAQs
          </div>
        </div>
        <div
          className="block px-4 py-2 hover:bg-blue-700 hover:text-white cursor-pointer"
          onClick={logout}
        >
          Logout
        </div>
      </div>
    </div>
  );
};
SettingsDropDown.propTypes = {
  showDropDown: PropTypes.bool,
  setShowDropDown: PropTypes.func,
  logout: PropTypes.func
};

export default SettingsDropDown;
