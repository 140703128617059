import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAPI from "../hooks/useAPI";
import ResourceTitleCard from "../components/ResourceTitleCard";
import "./Resources.css";
import SimilarCard from "../components/SimilarCard";
import classnames from "classnames";
import useWindowSize from "../hooks/useWindowSize";
const smallWidth = 541;
const medWidth = 950;

function Resources() {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [combinedArray, setCombinedArray] = useState([]);
  const [width] = useWindowSize();
  const [columnNumber, setColumnNumber] = useState(3);
  const [rowContents, setRowContents] = useState({
    row: undefined,
    contents: []
  });
  const history = useHistory();
  const api = useAPI(0);

  const toggleExpand = (row, activities) => {
    if (
      rowContents.row === row &&
      activities[0].id === rowContents.contents[0].id
    ) {
      setIsSelected(false);
      setRowContents({ row: undefined, contents: [] });
    } else {
      setIsSelected(true);
      setRowContents({ row: row, contents: activities });
    }
  };

  useEffect(() => {
    async function fetchData() {
      const resourceArray = await api
        .get("resources")
        .then(resp => {
          return resp.data.response;
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          return [];
        });

      const activityArray = await api
        .get("activities")
        .then(resp => {
          return resp.data.response;
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          return [];
        });
      setCombinedArray([...activityArray, ...resourceArray]);
    }
    fetchData();
  }, [rowContents, api]);

  useEffect(() => {
    if (width < smallWidth) {
      setColumnNumber(1);
    }
    if (width < medWidth && width > smallWidth) {
      setColumnNumber(2);
    }
    if (width > medWidth) {
      setColumnNumber(3);
    }
  }, [width, setColumnNumber]);

  useEffect(() => {
    if (combinedArray.length > 0) {
      const dividedArray = [];
      let j = combinedArray.length;
      for (let i = 0; i < j; i += columnNumber) {
        let temparray = combinedArray.slice(i, i + columnNumber);
        dividedArray.push(temparray);
      }
      setResources(dividedArray);
      setIsLoading(false);
    }
  }, [combinedArray, columnNumber]);

  const gridClassNames = classnames("grid gap-10", {
    "grid-cols-3": width >= medWidth,
    "grid-cols-2": width < medWidth && width >= smallWidth,
    " grid-cols-1 ": width < smallWidth
  });
  const dividerClasses = classnames("grey-bar ", {
    "col-span-3": width > smallWidth,
    "col-span-full": width <= smallWidth
  });

  const subGridClasses = classnames("", {
    "col-span-3 subtype-container grid grid-cols-3 gap-4": width >= medWidth,
    "col-span-3 subtype-container grid grid-cols-2 gap-4":
      width < medWidth && width >= smallWidth,
    "flex flex-col items-center": width < smallWidth
  });
  if (isLoading) {
    return (
      <>
        <div className="loader-holder">
          <div className="loader"></div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="resource-header">Resources</div>
        <div className="resize-grid">
          <div className={gridClassNames}>
            {resources.map((arr, i) => (
              <>
                {arr.map(source => (
                  <ResourceTitleCard
                    resource={source}
                    key={source.id}
                    history={history}
                    onClick={
                      source.name
                        ? () => toggleExpand(i, source.activities)
                        : () => history.push(`details/resource/${source.id}`)
                    }
                  />
                ))}
                <div className={dividerClasses}></div>
                <div className={subGridClasses}>
                  {rowContents.row === i && isSelected
                    ? rowContents.contents.map(card => (
                        <SimilarCard activity={card} key={card.id} />
                      ))
                    : ""}
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Resources;
