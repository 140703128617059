import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

function PrivacyPolicy() {
  const titleClasses = classnames("font-bold text-blue-600 text-xl");
  const history = useHistory();
  return (
    <div className="pt-6">
      <div className="flex">
        <div className="mr-6" onClick={() => history.push("/")}>
          <FontAwesomeIcon icon={faChevronLeft} color="grey" />
        </div>
        <h1 className="text-blue-600 font-bold text-xl">Privacy Policy</h1>
      </div>
      <div className="border border-blue-600 mb-2"></div>
      <div>
        <span className={titleClasses}>1. Introduction</span>
        <br />
        <br />
        Welcome to the Objective Zero mobile app! Your use of the Objective Zero
        mobile app and its services, to include those provided through the
        Objective Zero website (www.objectivezero.org), the Objective Zero
        mobile application, and any other software or services provided by
        Objective Zero is governed by this Privacy Policy (the “Policy”).
        Accordingly, it is important to carefully read the Policy before using
        the App. For the purposes of this Policy, “we,” “our,” “us,” “Objective
        Zero,” and “App” refer to the Objective Zero Foundation and the
        providers and operators of the Objective Zero App. <br /> <br />
        In order to use the App, you must first agree to this Policy. If you are
        using the App on behalf of an organization, you are agreeing to this
        Policy for that organization and promising that you have the authority
        to bind that organization to this Policy. In that case, “you” and “your”
        will also refer to that organization, wherever possible.
        <br />
        <br /> We are committed to protecting your privacy. This Privacy Policy
        describes how we collect, store, use and distribute information through
        our software, website, documentation, and related services (together,
        the “App” or “Services”).
        <br />
        <br />
        <strong>a.</strong> Subject to the provisions of this Policy, Objective
        Zero is required by law to: <br />
        <br />
        <strong>i. </strong> Ensure any information that identifies you is kept
        private, consistent with the provisions of this Policy. <br />
        <br />
        <strong>ii.</strong> Give you this notice of our legal duties and
        privacy practices. <br />
        <br /> <strong>iii.</strong> Follow the terms of the current notice.{" "}
        <br />
        <br /> <strong>b.</strong> Who will adhere to this privacy policy?
        <br />
        <br /> <strong>i.</strong> Network officers, employees, interns, and
        volunteers. <br /> <br />
        <strong>ii.</strong> Anyone who has a contract with Objective Zero to
        provide services to the agency or people served by the agency. In this
        notice, these people will be called our Partners.
        <br /> <br />
        <span className={titleClasses}>2. Consent</span>
        <br /> <br /> By using the App, you consent to the use of your Personal
        Information as described in this Privacy Policy. Except as set forth in
        this Privacy Policy, your Personal Information will not be used for any
        other purpose without your consent. We do not collect Personal
        Information for the purpose of sale of such information in a way that
        identifies the individual (i.e. we don’t sell customer lists). You may
        withdraw your consent to our processing of your Personal Information at
        any time. However, withdrawing consent may result in your inability to
        continue using some or all of the App.
        <br />
        <br />{" "}
        <span className={titleClasses}>3. Collection of Information</span>{" "}
        <br /> <br />
        <span className={titleClasses}>a. Personal Information</span> <br />
        <br />
        <strong>i.</strong> We will collect and store certain personal
        information from or about you in order to provide you with the App. For
        example, when expressing an interest in obtaining additional information
        about the certain services or registering to use the App, we may require
        you to provide certain personally identifiable information, such as your
        name, phone number, and email address. For the purposes of purchases, we
        will require you to provide financial and billing information, such as
        billing name and address, and credit card number. Additionally, you may
        provide certain information to us in connection with your ongoing use of
        the App, such as your health habits and responses to dynamic content
        within the app. All of this information is collectively referred to as
        your “Personal Information.” <br />
        <br />
        <span className={titleClasses}>
          b. Data, Diagnostic, & Login Information
        </span>
        <br />
        <br /> <strong>i.</strong> You may be able to create, upload, publish,
        transmit, distribute, display, store or share information, data, text,
        graphics, video, messages or other materials using the App (this is
        collectively referred to below as “Data”). Some of this Data may be
        stored and maintained on our servers. If you run into technical errors
        in the course of using the App, we may request your permission to obtain
        a crash report along with certain logging information from your system
        documenting the error (“Diagnostic Information”). Such information may
        contain information regarding your Operating System version, hardware,
        browser version (and .NET version information in case of Windows
        systems), and your email address if provided. Additionally, certain
        login information may be maintained in a cookie stored locally on your
        personal computing device (i.e. not on a server) in order to streamline
        the login process (“Login Information”) <br />
        <br />
        <span className={titleClasses}>c. Biometric Data</span>
        <br /> <br /> <strong>i.</strong> Certain features of the App may
        actively record information about you as you use the App, should you
        give the App permission to do so. This tracking is accomplished through
        integration with Apple’s HealthKit API, Apple Health App, and other
        wearable devices. Such information is referred to as “Biometric Data”
        and will not be shared with anyone, unless under an authorized
        disclosure outlined in this privacy policy.
        <br />
        <br />
        <span className={titleClasses}>d. Analytics Information</span> <br />
        <br />
        <strong>i.</strong> As you use the App, we may also collect information
        through the use of commonly-used information-gathering tools, such as
        cookies (see section), log files, and Web beacons for the purposes of
        improving the performance and functionality of the app. Such Information
        may include standard information regarding your mobile device, browser
        type, browser language, Operating System, Internet Protocol (“IP”)
        address, and the actions you take on our web site (such as the web pages
        viewed and the links clicked) or while using the App. Collectively, this
        information is referred to as “Analytics Information.” <br />
        <br /> <span className={titleClasses}>e. Geo-Location</span>
        <br />
        <br /> The app may collect geolocational data from you, if you give it
        permission to do so. We will never collect such information without your
        consent. <br /> <br />
        <span className={titleClasses}>4. Use of Information</span>
        <br />
        <br /> We use the information we collect in the following ways:
        <br />
        <br /> <strong>a.</strong> We use Personal Information to manage your
        account, to provide services, to maintain our customer/visitor lists, to
        respond to your inquiries or provide feedback, for identification and
        authentication purposes, for service improvement, and to address issues
        like Personal Information.malicious use of the App. We may use Personal
        Information to communicate with you regarding the App within the App,
        including communications via email and push notifications. We may also
        use Personal Information for limited marketing purposes, namely, to
        contact you to further discuss your interest in the App, and to send you
        information about us or our partners. We may also use credit card
        information to manage your account, to provide the App, and to check the
        financial qualifications of prospective customers and to collect payment
        for the App if such payment is required. We may use a third-party
        service provider to manage credit card processing. If we do so, such a
        service provider will not be permitted to store, retain, or use Billing
        Information except for the sole purpose of credit card processing on our
        behalf. Any use of your Personal Information for these limited marketing
        purposes will comply with applicable federal and state laws, and, if
        applicable, any HIPAA Business Associate Agreement in place between
        Objective Zero and your medical professional and/or health care provider
        (“Medical Professional”) who connects with you through the App (see
        Section 6 of this Privacy Policy for more information regarding security
        and Section 7 of this Privacy Policy for more information regarding
        HIPAA). <br />
        <br />
        <strong>b.</strong> Data, Diagnostic Information, and Login Information.
        We use this information for the purpose of administering and improving
        our services to you. We may also use this information in a
        de-identified, anonymous way in conjunction with an analytics service
        such as Google Analytics to monitor and analyze use of the App, for the
        App’s technical administration, to increase the App’s functionality and
        user-friendliness, and for other legitimate business purposes. <br />{" "}
        <br />
        <strong>c.</strong> Biometric Data. We use this information solely for
        the purpose of providing you with health-related feedback and self-care
        suggestions. This information is not used for advertising or other
        use-based data mining purposes. Your Biometric Data is not accessible by
        other users of the App, and we will not share such information with
        third parties without your consent, except as provided in the sections
        below. <br />
        <br />
        <strong>d.</strong> Analytics Information. We use this information to
        provide you with services within the app. We may also use your Analytics
        Information in a de-identified, anonymous way in conjunction with an
        analytics service such as Google Analytics to monitor and analyze use of
        the App, for the App’s technical administration, to increase the App’s
        functionality and user-friendliness, and to verify users have the
        authorization needed for the App to process their requests. If we plan
        to use your Personal Information in the future for any other purposes
        not identified above, we will only do so after informing you by updating
        this Privacy Policy.
        <br /> <br />
        <span className={titleClasses}>5. Disclosures and Transfers</span>{" "}
        <br /> <br />
        We may in the future put in place contractual and other organizational
        safeguards with our agents and contractors (see further below) to ensure
        a proper level of protection of your Personal Information. In addition
        to those measures, we will not disclose or transfer your Personal
        Information to third parties without your permission, except as
        specified in this Privacy Policy.
        <br />
        <br /> From time to time we may employ third parties as contractors to
        help us provide and/or improve the App. These third parties may have
        limited access to Personal Information solely for the purpose of helping
        us to provide and/or improve the App, and they will be subject to
        contractual restrictions prohibiting them from using the information
        about our members for any other purpose. Such agents or third parties do
        not have any rights to use Personal Information beyond what is necessary
        to assist us.
        <br />
        <br /> We may also disclose your Personal Information with your consent,
        for routine purposes, or when mandated by the state, local, and federal
        law.
        <br />
        <br /> <strong>a.</strong> Disclosures requiring your consent. Sometimes
        Objective Zero will need your permission before communicating personal
        information. Objective Zero will ask you to sign a consent form for this
        purpose. The following requires your consent before Objective Zero can
        communicate personal information:
        <br />
        <br /> <strong>i.</strong> Outside services: If you need or want to work
        with an outside agency before Objective Zero can help you work with any
        such agency, Objective Zero will need to provide the agency some
        personal information about you. Outside agencies may include, but are
        not necessarily limited to hospitals, extended care facilities, schools
        and government offices.
        <br />
        <br />
        <strong>ii.</strong> Individuals not involved in providing services
        through the App who you want to be informed.
        <br />
        <br />
        <strong>iii.</strong> Rights to Revoke: You have the right to revoke
        your consent at any time but you must put it in writing and send it{" "}
        <strong>iii.</strong> to: contactus@objectivezero.org. Please include
        your first and last name and username.
        <br />
        <br />
        <strong>b.</strong> Routine disclosures. Objective Zero may need to make
        routine disclosures, may ask permission to disclose private information
        or may be required to disclose personal information under the
        circumstances outlined below. Routine disclosures are those Objective
        Zero must make as a part of serving you. Objective Zero does not need
        any specific consent or permission from you for the uses listed below.
        <br />
        <br />
        <strong>i.</strong> For payment: We may communicate your personal
        information about you to receive payment for services.
        <br />
        <br />
        <strong>ii.</strong> Operations: Objective Zero may use personal
        information about you to improve Objective Zero services.
        <br />
        <br />
        <strong>iii.</strong> For Providers: We may disclose your Personal
        Information to your Medical Professional who you connect with through
        the App
        <br />
        <br />
        <strong>c.</strong> Mandatory disclosures. There are certain times we
        are unable to protect the privacy of your personal information. We will
        give information about you when required to do so by federal, state, or
        local law.
        <br />
        <br />
        <strong>i.</strong>{" "}
        <span className="underline">
          To avert a serious threat to your health or safety.
        </span>{" "}
        Objective Zero desires to keep you safe. Therefore, Objective Zero may
        give personal information about you to stop a serious threat to your
        health and safety.
        <br />
        <br />
        <strong>ii.</strong>{" "}
        <span className="underline">
          Where the law requires disclosure to protect others.
        </span>{" "}
        Objective Zero will protect the health and safety of other people if you
        are in any way an imminent threat to someone else.
        <br />
        <br />
        <strong>iii.</strong>
        <span className="underline">
          {" "}
          To comply with a valid subpoena or court order.
        </span>{" "}
        Objective Zero may be served with a subpoena and/or court order to
        disclose personal information about you. In such case, Objective Zero is
        bound by law to provide the information set forth in the subpoena and/or
        court order.
        <br />
        <br />
        <strong>iv.</strong>{" "}
        <span className="underline">
          When validly requested by law enforcement.
        </span>{" "}
        We release personal information if asked by law enforcement officials as
        part of an official investigation, or to identify or locate a missing
        person, crime suspect, fugitive or material witness.
        <br />
        <br />
        <strong>v.</strong>{" "}
        <span className="underline">As required due to incarceration.</span> If
        you are an inmate in a jail or prison, Objective Zero may be required to
        release personal information about you to jail or prison authorities.
        <br />
        <br />
        <strong>d.</strong> Sharing Information with Third Parties.You may be
        able to share Personal Information with third parties through the use of
        the App. The privacy policies of these third parties are not under our
        control and may differ from ours. The use of any information that you
        may provide to any third parties will be governed by the privacy policy
        of such third party or by your independent agreement with such third
        party, as the case may be. If you have any doubts about the privacy of
        the information you are providing to a third party, we recommend that
        you contact that third party directly for more information or to review
        its privacy policy.
        <br />
        <br />
        <strong>e.</strong> Important exceptions. We may disclose your Personal
        Information to third parties without your consent if we have reason to
        believe that disclosing this information is necessary to identify,
        contact or bring legal action against someone who may be causing injury
        to or interference with (either intentionally or unintentionally) our
        rights or property, other users of the App, or anyone else (including
        the rights or property of anyone else) that could be harmed by such
        activities. We may disclose Personal Information when we believe in good
        faith that such disclosure is permitted or required by and in accordance
        with the law.We may also disclose your Personal Information in
        connection with a corporate re-organization, a merger or amalgamation
        with another entity, a sale of all or a substantial portion of our
        assets or stock, including any due diligence exercise carried out in
        relation to the same, provided that the information disclosed continues
        to be used for the purposes permitted by this Privacy Policy by the
        entity acquiring the information.
        <br />
        <br />
        <span className={titleClasses}>6. Security</span>
        <br />
        <br /> The security of your Personal Information is important to us. We
        use commercially reasonable efforts to store and maintain your Personal
        Information in a secure environment. Communications between the mobile
        applications and our servers take place over secure HTTPS connections,
        and your data is encrypted when it is stored on our servers. We also use
        commercially reasonable technical, administrative, and physical
        safeguards to preserve the integrity and security of the Personal
        Information that you provide to us. We have implemented procedures
        designed to limit the dissemination of your Personal Information to only
        such designated staff as are reasonably necessary to carry out the
        stated purposes we have communicated to you.
        <br />
        You are also responsible for helping to protect the security of your
        Personal Information. For instance, never give out your password, and
        safeguard your user name, password and personal credentials when you are
        using the App so that other people will not have access to your Personal
        Information. Furthermore, you are responsible for maintaining the
        security of any personal computing device in which you utilize the App.
        <br />
        If we learn of a security breach, we may attempt to notify you
        electronically so that you can take appropriate protective steps. We may
        also post a notice on our website if a security breach occurs. Depending
        on where you live, you may have a legal right to receive notice of a
        security breach in writing.
        <br />
        <br />
        <span className={titleClasses}>7. HIPAA </span>
        <br />
        <br />
        It is not Objective Zero’s intent to store, distribute, or disclose
        personal medical information. However, such information may be
        transmitted by individuals within the Objective Zero App, either
        verbally or through written correspondence, the content of which may be
        stored on Objective Zero&apos;s data storage devices. This information
        may be about your mental health, your medical health, and/or your use of
        alcohol or drugs.
        <br /> If you connect with your Medical Professional through the App, we
        are required to comply with the Health Insurance Portability and
        Accountability of 1996, as amended, and its implementing regulations
        (collectively, “HIPAA”) as a “business associate” of your Medical
        Professional. We enter into a HIPAA Business Associate Agreement with
        each Medical Professional that is subject to HIPAA as a “covered
        entity.” We will comply with our HIPAA Business Associate Agreements and
        this Privacy Policy with respect to your Personal Information that is
        also “protected health information” as defined in HIPAA.
        <br /> A copy of the Objective HIPAA Business Associate Agreement can be
        accessed at www.objectivezero.org/business-associate-agreement.
        <br />
        <br />
        <span className={titleClasses}>8. Retention</span>
        <br />
        <br /> We will keep your Personal Information for as long as it remains
        necessary for the identified purpose or as required by law, which may
        extend beyond the termination of our relationship with you. We may
        retain certain data as necessary to prevent fraud or future abuse, or
        for legitimate business purposes, such as analysis of aggregated,
        de-identified data, or account recovery. All retained Personal
        Information will remain subject to the terms of this Privacy Policy.
        Please note that if you request that your Personal Information is
        removed from our databases, it may not be possible to completely delete
        all of your Personal Information due to technological, legal, or
        contractual constraints.
        <br />
        <br />
        <span className={titleClasses}>
          9. Amendment of this Privacy Policy
        </span>{" "}
        <br />
        <br />
        We reserve the right to change this Privacy Policy at any time. If we
        decide to change this Privacy Policy in the future, we will post or
        provide appropriate notice. Any non-material change (such as
        clarifications) to this Privacy Policy will become effective on the date
        the change is posted, and any material changes will become effective 30
        days from their posting on www.objectivezero.org or via email to your
        listed email address. Unless stated otherwise, our current Privacy
        Policy applies to all Personal Information that we have about you and
        your account. The date on which the latest update was made is indicated
        at the bottom of this document. We recommend that you print a copy of
        this Privacy Policy for your reference and revisit this policy from time
        to time to ensure you are aware of any changes. Your continued use of
        the App signifies your acceptance of any changes.
        <br /> Objective Zero reserves the right to amend this notice in the
        future. If Objective Zero makes such amendments, Objective Zero will
        update this page to replace this notice with the amended notice, which
        will then become the current notice. You are welcome to ask for a copy
        of this, or any amended notice by emailing contactus@objectivezero.org.
        <br />
        <br />
        <span className={titleClasses}>10. Access and Accuracy</span>
        <br />
        <br />
        You have the right to access the Personal Information we hold about you
        in order to verify the Personal Information we have collected in respect
        to you and to have a general account of our uses of that information.
        Upon receipt of your written request, we will provide you with a copy of
        your Personal Information, although in certain limited circumstances we
        may not be able to make all relevant information available to you, such
        as where that information also pertains to another user. In such
        circumstances, we will provide reasons for the denial to you upon
        request. We will endeavor to deal with all requests for access and
        modifications in a timely manner.
        <br /> We will make every reasonable effort to keep your Personal
        Information accurate and up-to-date, and we will provide you with
        mechanisms to update, correct, delete or add to your Personal
        Information as appropriate. This amended Personal Information will be
        transmitted to those parties to which we are permitted to disclose your
        information, as appropriate. Having accurate Personal Information about
        you enables us to give you the best possible service.
        <br />
        Users and Ambassadors are entitled to ask us for a notice identifying
        the categories of Personal Information which we share with our
        affiliates and/or third parties for marketing purposes, and providing
        contact information for such affiliates and/or third parties. If you
        would like a copy of this notice, please submit a written request to
        contactus@objectivezero.org.
        <br /> Specifically, you have the following rights regarding your
        personal information:
        <br />
        <br />
        <strong>a.</strong> Right to inspect and copy: In most cases, you have
        the right to look at and receive a copy of information that is in your
        Objective Zero User profile. You may be charged for copies you receive.
        To receive a copy of your information, send a written request
        contactus@objectivezero.org. Please include your first and last name and
        username.
        <br />
        <br />
        <strong>b.</strong> Right to Amend: If you think any information we have
        about you is incorrect or incomplete, you can ask us to change the
        information or add a statement to your record stating what you think is
        incorrect or incomplete. Objective Zero can deny your request for
        certain reasons, but Objective Zero must give you a written reason for
        our denial.
        <br />
        <br />
        <strong>c.</strong> Right to Request Restrictions: You have the right to
        put limits on what we can do with your personal information. However,
        Objective Zero is not required to agree with your request for any
        specific instructions regarding limitations.
        <br />
        <br />
        <strong>d.</strong> Right to Request Restrictions: You have the right to
        put limits on what we can do with your personal information. However,
        Objective Zero is not required to agree with your request for any
        specific instructions regarding limitations.
        <br />
        <br />
        <strong>e.</strong> Right to Request Confidential Communications: You
        have the right to request Objective Zero to communicate with you in a
        certain way or at a certain location. It is your responsibility to
        provide this information to Objective Zero and update such information
        in writing anytime such information changes. You can send such
        information and requests to contactus@objectivezero.org. Please include
        your first and last name and username.
        <br />
        <br />
        <strong>f.</strong> Please be aware, there may be a cost to receive a
        copy of information from your record or an accounting of disclosures.
        <br />
        <br />
        <span className={titleClasses}>11. Cookies Policy</span>
        <br />
        <br />A cookie is a small amount of data, which often includes a unique
        identifier that is sent to your computer or mobile phone
        (interchangeable “device”) from the computer of a website and is stored
        on the hard drive of your device. If you set your browser’s preferences
        to allow cookies, each website can send its own cookie to your browser.
        However, your browser only permits a website to access the cookies it
        has already sent to you, not the cookies sent to you by other sites.
        Cookies record information about your preferences that allow websites to
        track traffic flow.
        <br />
        <br />
        Cookies allow Objective Zero to tailor features/services within the App
        to your interests. Objective Zero also uses cookies and collects IP
        addresses (an IP address is a number that can uniquely identify a
        specific computer or other network devices on the Internet). Objective
        Zero may use analysis software to view IP addresses and cookies for the
        purpose of improving services and enhancing your User experience. This
        information is not used to develop a personal profile of you and log
        files are purged on a regular basis.
        <br />
        <br />
        Objective Zero may also use your IP address to determine from what
        region you are accessing Objective Zero services. You may be directed to
        a specific link within the App based on your geographical location. IP
        addresses are also used to block disruptive use.
        <br />
        <br />
        When you sign up to utilize the App and receive services through the
        App, you may be asked for personal information. For example, this may
        consist of information such as your name, address, date of birth, email
        address and/or telephone number. Different web pages you visit within
        the App may request different personal information.
        <br />
        <br />
        All pages/functions of our App that handle sensitive/personal
        information are SSL encrypted for maximum security. By entering your
        details in the fields as requested, you enable Objective Zero and its’
        service providers to provide you with the App you choose to utilize.
        <br />
        <br />
        Also, Objective Zero may use your personal information for a number of
        purposes including, but not necessarily limited to the following:
        <br />
        <br />
        <strong>a.</strong> to administer services. Objective Zero may contact
        you for reasons related to the service you choose to utilize through the
        App;
        <br />
        <br />
        <strong>b.</strong> to contact you regarding a submission you have made
        to the App;
        <br />
        <br />
        <strong>c.</strong> to determine the most beneficial means of
        disseminating information to you; and,
        <br />
        <br />
        <strong>d.</strong> to make features more User-friendly;
        <br />
        <br />
        <strong>e.</strong>{" "}
        <span className="underline">
          for the use of contracted third parties to process your information
        </span>
        (for example or payment providers). Objective Zero will instruct any
        such third parties with whom it contracts to strictly comply with its
        instructions and will require that such third party not use your
        personal information for the personal or business gain of the third
        party.
        <br />
        <br />
        <span className={titleClasses}>12. Contact</span>
        <br />
        <br />
        If you have any questions about this Policy or if you wish to make any
        complaint or claim with respect to the App, please contact us at:
        contactus@objectivezero.org
        <br />
        When submitting a complaint, please provide a brief description of
        nature of your complaint and the specific services to which your
        complaint relates, as well as your first and last name and username.
        <br />
        TERMS, CONDITIONS, APP FEATURES, AVAILABILITY, PRICING, SERVICE AND
        SUPPORT OPTIONS ARE SUBJECT TO CHANGE WITHOUT NOTICE.
      </div>
    </div>
  );
}

export default PrivacyPolicy;
