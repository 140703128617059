export const errorUnResponsiveAmbassador =
  "Please wait for the ambassador to respond before sending more messages";
export const successToNewAmbassador =
  "Thanks for reaching out, we will notify this ambassador and then you when they respond";
export const newChannelToAmbassador =
  "A new user would like to chat with you. Click here to view their message!";
export const successMessageSent = "Message sent!";
export const messageToFirstAvailableAmbassador =
  "We'll let available Ambassadors know you want to chat. A new chat notification will appear once you're matched";
export const userAlreadyInWaitingRoom =
  "We're looking for new Ambassadors to connect you with. A new chat notification should appear shortly.";

export const notAvailableToTextAmbassador =
  "Sorry, this ambassador isn't available at the moment";

export const alreadyInWaitingRoom =
  "Sorry, but it seems you're already in a waiting room.";

export const successToUserReachingOut =
  "Thanks for reaching out to a user in need, they should respond shortly!";
export const yourAmbassadorIsReady =
  "You have been connected to an ambassador! Click here to be directed to the page, or go to messages!";

export const waitingRoomError =
  "Hmm, something went wrong, please try again. If the problem persists contact Objective Zero Support";
export const messageLengthError = "Messages must contain at least 1 character";
