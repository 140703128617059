import React, { useState, useEffect, useContext } from "react";
import GoogleMap from "./GoogleMap";
import AmbassadorInfoWindow from "./AmbassadorInfoWindow";
import InfoWindowEx from "./InfoWindowEx";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { AmbassadorContext } from "../pages/Connect";
import AmbassadorPin from "../Assets/images/AmbassadorPin.png";
import DefaultPin from "../Assets/images/DefaultPin.png";
import UserPin from "../Assets/images/UserPin.png";
import UserDefaultPin from "../Assets/images/UserDefaultPin.png";
import useAuth from "../hooks/useAuth";
import classnames from "classnames";
import useAPI from "../hooks/useAPI";
import PropTypes from "prop-types";
import customToast from "./customToast";
import useWindowSize from "../hooks/useWindowSize";

function ConnectMap({ center, zoom }) {
  const [map, setMap] = useState();
  const v1 = useAPI(1);
  const [infoWindow, setInfoWindow] = useState();
  const [faceMarkers, setFaceMarkers] = useState([]);
  const [allMarkers, setAllMarkers] = useState([]);
  const [cluster, setCluster] = useState();
  const [hiddenCluster, setHiddenCluster] = useState();
  const [width, height] = useWindowSize();
  const {
    ambassadors,
    activeAmbassador,
    setActiveAmbassador,
    currentFilters,
    currentUser,
    setShowMessageModal,
    history,
    user_type,
    favorites,
    showInfoWindow,
    setShowInfoWindow,
    setIsLoading,
    setIsSorry,
    setFavorites
  } = useContext(AmbassadorContext);
  const {
    isChrome,
    setSenderPayload,
    setShowCallModal,
    setUnavailableAmbassadorModal
  } = useAuth();

  const callUser = (id, is_available_voiceCall, type) => {
    if (is_available_voiceCall) {
      v1.post(`call/user/${id}`, { type: type })
        .then(r => {
          setSenderPayload(r.data);
        })
        .catch(err => {
          console.log(err);
        });
      setShowCallModal(true);
    } else {
      setUnavailableAmbassadorModal(true);
    }
  };

  const checkFavorite = id => {
    const arrayOfFavorite = favorites.filter(fav => fav.id === id);
    if (arrayOfFavorite.length < 1) {
      return false;
    } else {
      return true;
    }
  };
  const favoriteOrUnfavorite = (isFavorite, id, name) => {
    if (isFavorite) {
      setFavorites(favorites => favorites.filter(fav => fav.id !== id));
      v1.delete(`favorite/${id}`)
        .then(() => {
          customToast.deny(`Removed ${name} from favorites`);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      let ambassador = ambassadors.filter(obj => obj.id === id);
      setFavorites(favorites => [...favorites, ambassador[0]]);
      v1.post(`favorite/${id}`)
        .then(() => {
          customToast.success(`Added ${name} to favorites`);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (map) {
    }
  }, [map, width]);

  const googleMapOptions = {
    center: center || null,
    zoom: zoom,
    mapTypeControl: false
  };
  const returnUrlForPin = ambassador => {
    if (ambassador.ambassador) {
      if (ambassador.profile_pic) {
        return AmbassadorPin;
      }
      return DefaultPin;
    } else {
      if (ambassador.profile_pic) {
        return UserPin;
      }
      return UserDefaultPin;
    }
  };

  useEffect(() => {
    if (map) setInfoWindow(new window.google.maps.InfoWindow());
  }, [map]);
  useEffect(() => {
    let timeout;
    let timeout2;
    if (map) {
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 10000);
      timeout2 = setTimeout(() => {
        setIsSorry(true);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
      setIsLoading(true);
      setIsSorry(false);
    };
  }, [map, setIsLoading, setIsSorry]);

  // Remove filtered Ambassador Markers
  useEffect(() => {
    if (ambassadors[0] && allMarkers.length > 0) {
      faceMarkers.forEach(mark => {
        mark.setMap(null);
        mark.map = null;
      });
      setFaceMarkers([]);
      if (hiddenCluster) {
        hiddenCluster.clearMarkers();
        setHiddenCluster();
      }
      allMarkers.forEach(mark => {
        mark.setMap(null);
        mark = null;
      });
      if (cluster) {
        cluster.clearMarkers();
        setCluster();
      }
      setAllMarkers([]);
    }
  }, [currentFilters, user_type, ambassadors, allMarkers, faceMarkers, center]);

  // Add markers for each ambassador
  useEffect(() => {
    if (map && ambassadors && allMarkers.length === 0) {
      ambassadors.forEach((ambassador, i) => {
        let faceMarker = "";
        if (ambassador.profile_pic) {
          const image = {
            url: ambassador.profile_pic,
            size: new window.google.maps.Size(37, 37),
            scaledSize: new window.google.maps.Size(37, 35),
            anchor: new window.google.maps.Point(18.5, 48)
          };

          faceMarker = new window.google.maps.Marker({
            map: map,
            icon: image,
            zIndex: i,
            class: "facemarker"
          });
          const tempArr = faceMarkers;
          tempArr.push(faceMarker);
          setFaceMarkers(tempArr);
        }

        let marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            ambassador.lat,
            ambassador.lng
          ),
          map: map,
          icon: {
            url: returnUrlForPin(ambassador),
            size: new window.google.maps.Size(55, 55),
            scaledSize: new window.google.maps.Size(55, 55)
          },
          zIndex: i + 1
        });
        if (faceMarker) {
          faceMarker.bindTo("position", marker);
        }
        marker.addListener(
          "click",
          () => {
            setActiveAmbassador(ambassador);
            setShowInfoWindow(true);
          },
          {
            passive: true
          }
        );
        ambassador.mapMarker = marker;
        const newTempArr = allMarkers;
        newTempArr.push(marker);
        setAllMarkers(newTempArr);
      });
      let clusterer = new MarkerClusterer(map, allMarkers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 8,
        minimumClusterSize: 1
      });
      setCluster(clusterer);

      let hiddenClusterer = new MarkerClusterer(map, faceMarkers, {
        clusterClass: "hidden",
        maxZoom: 8,
        minimumClusterSize: 1
      });
      setHiddenCluster(hiddenClusterer);
    }
  }, [
    map,
    ambassadors,
    allMarkers,
    faceMarkers,
    setActiveAmbassador,
    setShowInfoWindow,
    center
  ]);

  // Pan to the active ambassador and open the InfoWindow
  useEffect(() => {
    if (map && activeAmbassador) {
      map.panTo(
        new window.google.maps.LatLng(
          activeAmbassador.lat,
          activeAmbassador.lng
        )
      );
      map.setZoom(10);
    }
  }, [map, activeAmbassador]);

  const renderInfoWindow = () => {
    return (
      <InfoWindowEx
        activeAmbassador={activeAmbassador}
        marker={activeAmbassador ? activeAmbassador.mapMarker : null}
        visible={showInfoWindow}
        mapWindow={infoWindow}
        map={map}
        mapCenter={center}
      >
        <div className="bg-transparent add-blur">
          {activeAmbassador && (
            <AmbassadorInfoWindow
              ambassador={activeAmbassador}
              setShowMessageModal={setShowMessageModal}
              setShowInfoWindow={setShowInfoWindow}
              history={history}
              callUser={callUser}
              checkFavorite={checkFavorite}
              favoriteOrUnfavorite={favoriteOrUnfavorite}
              setActiveAmbassador={setActiveAmbassador}
            />
          )}
        </div>
      </InfoWindowEx>
    );
  };

  const googleMapClassnames = classnames("", {
    "map-holder-chrome": isChrome && height < 900,
    "map-holder": !isChrome && height < 900,
    "map-holder-ambassador-tablet": height > 900
  });
  return (
    <div style={{ height: "70vh", borderRadius: "0.5em" }} className="relative">
      <GoogleMap
        options={googleMapOptions}
        onMount={setMap}
        ambassadors={ambassadors}
        currentUser={currentUser}
        className={googleMapClassnames}
      />
      <div className="info-holder">{renderInfoWindow()}</div>
    </div>
  );
}

ConnectMap.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number
};

export default ConnectMap;
