import React, { useContext } from "react";
import ChannelCard from "./ChannelCard";
import moment from "moment";
import { MessagingContext } from "../pages/Messages";
import useWindowSize from "../hooks/useWindowSize";

function ChannelList() {
  const { channels } = useContext(MessagingContext);
  const [width] = useWindowSize();

  channels.sort(function (a, b) {
    return b.lastMessageEpochMilliseconds - a.lastMessageEpochMilliseconds;
  });
  const cards =
    channels &&
    channels.map((channel, i) => {
      return <ChannelCard key={i} channel={channel} />;
    });
  const today = moment();

  const compareCards = (card1, card2) => {
    const ts1 = card1.props.channel.lastMessageEpochMilliseconds;
    const ts2 = card2.props.channel.lastMessageEpochMilliseconds;
    return moment(ts1).isSame(moment(ts2), "day");
  };

  const renderCorrectChannelList = () => {
    if (cards.length > 0) {
      if (width > 860) {
        return cards.map((card, i) => {
          const cardMoment = moment(
            card.props.channel.lastMessageEpochMilliseconds
          );
          if (i === 0) {
            return (
              <div key={i}>
                <div className="mt-2 text-xs text-gray-400">
                  {cardMoment.isSame(today, "day")
                    ? "Today"
                    : cardMoment.format("MMM Do YYYY")}
                </div>
                {card}
              </div>
            );
          } else if (!compareCards(cards[i - 1], card)) {
            return (
              <div key={i}>
                <div className="mt-2 text-xs text-gray-400">
                  {cardMoment.format("MMM Do YYYY")}
                </div>
                {card}
              </div>
            );
          } else {
            return <div key={i}>{card}</div>;
          }
        });
      } else {
        return cards.map((card, i) => {
          return <div key={i}>{card}</div>;
        });
      }
    } else {
      return <div>No messages to display</div>;
    }
  };

  return (
    <div>
      <h2 className="text-oz-blue font-bold">Recents</h2>
      <div className="overflow-y-auto channelListHeight channel-list-alt">
        {renderCorrectChannelList()}
      </div>
    </div>
  );
}

export default ChannelList;
