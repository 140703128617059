import React from "react";
import Image from "./Image";
import { useHistory } from "react-router-dom";
import { errorImage } from "../constants/imageUrls";
import PropTypes from "prop-types";
import classnames from "classnames";

const SimilarCard = ({ activity, detailsId, width }) => {
  const history = useHistory();
  const cardClassNames = classnames(
    "resource-similar-card shadow md:shadow-lg",
    {
      "bg-oz-blue": activity.id === detailsId
    }
  );
  const titleClassnames = classnames("activity-title font-bold", {
    "blue-text": activity.id !== detailsId,
    "text-white": activity.id === detailsId
  });
  const previewClassNames = classnames("resource-description-preview ", {
    "text-gray-500": activity.id !== detailsId,
    "text-white bg-oz-blue": activity.id === detailsId
  });

  const renderDescription = () => {
    if (activity.id !== detailsId && width > 1039) {
      return (
        <div className={previewClassNames}>
          <p dangerouslySetInnerHTML={{ __html: activity.description }} />
        </div>
      );
    } else {
      return "";
    }
  };
  return (
    <>
      <div
        className={cardClassNames}
        onClick={() => {
          activity.name
            ? history.push(`/details/activity/${activity.id}`)
            : history.push(`/details/resource/${activity.id}`);
        }}
      >
        <div className="activity-image-container object-left">
          <Image
            url={activity.image}
            defaultUrl={errorImage}
            classNames={`activity-image`}
          />
        </div>
        <div className="resource-thumbnail-container">
          <div className="resource-card-title-container">
            <h1 className={titleClassnames}>
              {activity.name || activity.title}
            </h1>
            {renderDescription()}
          </div>
        </div>
      </div>
    </>
  );
};
SimilarCard.propTypes = {
  activity: PropTypes.object,
  detailsId: PropTypes.number,
  width: PropTypes.number
};

export default SimilarCard;
