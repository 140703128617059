import React from "react";
import classnames from "classnames";

function SoftwareLicense() {
  const titleClasses = classnames("font-bold text-blue-600 text-xl");
  return (
    <div>
      <h1 className="text-blue-600 font-bold text-xl">Software License</h1>
      <div className="border border-blue-600 mb-2"></div>
      <div>
        <span className={titleClasses}>License Agreement</span>
        <br />
        <br />
        <span className={titleClasses}>Objective Zero Application</span>
        <br />
        <br />
        <span className={titleClasses}>Version 1.0</span>
        <br />
        <br />
        <span className={titleClasses}>Last Updated 9 August 2017</span>
        <br />
        <br />
        <span className={titleClasses}>License</span>
        <br />
        <br />
        Objective Zero gives you a personal, worldwide, royalty-free,
        non-assignable and non-exclusive license to use the software and
        Objective Zero-Supplied Content provided to you as part of the Objective
        Zero Mobile Application. This license is for the sole purpose of
        allowing you to utilize the Mobile Application as a personalized
        self-help system to help you improve your well-being in the manner
        permitted by these Terms. Additionally, if you are a Medical
        Professional utilizing these Services in conjunction with a patient who
        is also a user of the Objective Zero Mobile Application, you may utilize
        the Application for the limited purpose of monitoring or assisting such
        patient with the same, so long as the patient has given you permission
        to do so.
        <br />
        You shall use the Objective Zero Mobile Application strictly in
        accordance with the terms of the Related Agreements and shall not: (a)
        decompile, copy, modify, reverse engineer, disassemble, attempt to
        derive the source code of, or decrypt the Application; (b) make any
        modification, adaptation, improvement, enhancement, translation or
        derivative work from the Application; (c) violate applicable laws, rules
        or regulations in connection with your access or use of the Application;
        (d) remove, alter or obscure any proprietary notice (including any
        notice of copyright or trademark) of the Objective Zero Foundation or
        its affiliates, partners, suppliers or licensors of the Application; (e)
        use the Application for any revenue-generating endeavor, commercial
        enterprise, or other purpose for which it is not designed or intended;
        (f) use the Application for creating a product, service or software that
        is a direct substitute or is competitive with any services, product or
        software offered by the Objective Zero Foundation; (g) use the
        Application to send automated queries to any website or to send any
        unsolicited commercial e-mail or correspondence; (h) use any proprietary
        information or interfaces of the Objective Zero Foundation or other
        intellectual property of the Objective Zero Foundation in the design,
        development, manufacture, licensing, or distribution of any
        applications, accessories or devices for use with the Application; (i)
        attempt to disable or circumvent any security mechanisms used by the
        Objective Zero Mobile Application. If you breach these restrictions, you
        may be subject to prosecution and damages. The terms of the license will
        govern any upgrades provided by use that replace and/or supplement our
        service, unless such upgrade is accompanied by a separate license in
        which case the terms of that license will govern.
        <br />
        <br />
        <span className={titleClasses}>Restrictions on Use:</span>
        <br />
        You agree that you will not upload, record, publish, post, link to,
        transmit or distribute User Content, or otherwise utilize the
        Application in a manner that: (a) advocates, promotes, incites,
        instructs, informs, assists or otherwise encourages violence or any
        illegal activities; (b) infringes or violates the copyright, patent,
        trademark, service mark, trade name, trade secret, or other intellectual
        property rights of any third party or the Objective Zero Foundation, or
        any rights of publicity or privacy of any party; (c) attempts to mislead
        others about your identity or the origin of a message or other
        communication, or impersonates or otherwise misrepresents your
        affiliation with any other person or entity, or is otherwise materially
        false, misleading, or inaccurate; (d) promotes, solicits or comprises
        inappropriate, harassing, abusive, profane, hateful, defamatory,
        libelous, threatening, obscene, indecent, vulgar, pornographic or
        otherwise objectionable or unlawful content or activity; (e) is harmful
        to minors; (f) utilizes or contains any viruses, Trojan horses, worms,
        time bombs, or any other similar software, data, or programs that may
        damage, detrimentally interfere with, surreptitiously intercept, or
        expropriate any system, data, personal information, or property of
        another; or (g) violates any law, statute, ordinance, or regulation
        (including without limitation the laws and regulations governing export
        control, unfair competition, anti-discrimination, or false advertising).
        <br />
        You may not engage in any activity that interferes with or disrupts the
        Objective Zero Mobile Application (or the servers and networks which are
        connected to the Application).
        <br />
        You may not access the Objective Zero Mobile Application for the purpose
        of bringing an intellectual property infringement claim against the
        Objective Zero Foundation or for the purpose of creating a product or
        service competitive with the Application.
        <br />
        You may not use the Objective Zero Mobile Application if you are a
        person barred from receiving the Application under the laws of the
        United States or other countries, including the country in which you are
        a resident or from which you use the Application. You affirm that you
        are over the age of 18, as the Applicationis not intended for
        individuals under 18.
        <br />
        <br />
        <span className={titleClasses}>Term and Termination:</span>
        <br />
        These terms will commence upon your download of the Objective Zero
        Mobile Application and/or your commencement of the services offered by
        the Objective Zero Foundation and, unless earlier terminated as provided
        in previous sections of this license agreement, will continue in
        perpetuity. These terms will immediately terminate upon your breach of
        these terms, unless such breach is curable and is actually and
        immediately cured by you after the Objective Zero Foundation provides
        notice of the breach to you. Upon termination of these terms, you will
        discontinue all use of the Objective Zero Mobile Application and/or
        services, will promptly destroy or have destroyed all software and any
        copies thereof, and upon request by the Objective Zero Foundation,
        certify in writing to the Objective Zero Foundation that such
        destruction has taken place. These remedies are cumulative and in
        addition to any other remedies available to the Objective Zero
        Foundation.
        <br />
        The Objective Zero Foundation reserves the right at any time and from
        time to time to modify or discontinue, temporarily or permanently, the
        services (or any part thereof) with or without notice. You agree that
        the Objective Zero Foundation shall not be liable to you or to any third
        part for any modification, suspension or discontinuance of services.
        Objective Zero Foundation reserves the right to change, limit usage of,
        and/or discontinue any service at any point in time.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default SoftwareLicense;
