import React from "react";
import MessageTimestamp from "./MessageTimestamp";
import MessagePicture from "./MessagePicture";
import PropTypes from "prop-types";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import {
  AMBASSADOR_INACTIVE,
  USER_DECLINED_TEXT_FORWARDING
} from "../constants/ChatConstants";

function TheirMessage({ user, message, disableTimeout, getNewAmbassador }) {
  if (message) {
    if (
      !message.state.body.includes(
        "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ"
      )
    ) {
      return (
        <div className="w-full flex mb-8">
          <div>
            <span className="w-full text-oz-gray text-tiny text-left mb-2 ml-12">
              <MessageTimestamp date={message.dateCreated} />
            </span>

            <div className="flex items-center justify-start">
              <MessagePicture
                url={user?.profile_pic || ProfilePictureMissing}
              />

              <div className="bg-oz-lightgray rounded p-4 ml-4 text-black text-sm">
                {message.state.body}
              </div>
            </div>
          </div>

          <div className="flex-1"></div>
        </div>
      );
    } else {
      if (
        message.state.body === AMBASSADOR_INACTIVE ||
        message.state.body === USER_DECLINED_TEXT_FORWARDING
      ) {
        return (
          <div className="w-full flex mb-8 text-sm">
            <div>
              <div className="flex items-center justify-start flex-col">
                <div>
                  The other user is showing signs of inactivity, would you like
                  to connect to another ambassador?
                </div>
                <div className="flex w-full justify-evenly">
                  <div
                    className="add-pointer-hover p-4 rounded hover:bg-gray-200 px-8"
                    onClick={getNewAmbassador}
                  >
                    Yes
                  </div>
                  <div
                    className="add-pointer-hover p-4 rounded hover:bg-gray-200 px-8"
                    onClick={disableTimeout}
                  >
                    No
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1"></div>
          </div>
        );
      } else {
        return "";
      }
    }
  } else {
    return "";
  }
}
TheirMessage.propTypes = {
  user: PropTypes.object,
  message: PropTypes.object
};

export default TheirMessage;
