import React, { useEffect } from "react";
import customToast from "./customToast";
import { toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import useAuth from "../hooks/useAuth";
import PropTypes from "prop-types";

function InactivityTest({ logout }) {
  const { getRemainingTime } = useIdleTimer({
    timeout: 1800000 * 4,
    debounce: 500
  });
  const { setShowErrorModal, setErr, authToken } = useAuth();

  useEffect(() => {
    const checkIdleTime = setInterval(() => {
      const timeLeft = getRemainingTime();
      if (timeLeft < 120000 && timeLeft > 5000) {
        if (!toast.isActive("inactive-toast")) {
          customToast.warn(
            "You have been inactive for almost 2 hours, click here to continue or in 2 minutes your session will end",
            {
              autoClose: 115000,
              toastId: "inactive-toast"
            }
          );
        }
      }
      if (timeLeft < 5000) {
        setErr({
          message:
            "Due to inactivity, you have been logged out. Please log back in to resume using the app. Thanks!"
        });
        setShowErrorModal(true);
        logout(true);
      }
    }, 30000);
    if (!authToken) {
      clearInterval(checkIdleTime);
    }
    return () => clearInterval(checkIdleTime);
  }, [authToken, getRemainingTime, logout, setErr, setShowErrorModal]);
  return <div></div>;
}
InactivityTest.propTypes = {
  logout: PropTypes.func
};

export default InactivityTest;
