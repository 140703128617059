import React from "react";
import "./ResourceTitleCard.css";
import Image from "./Image";
import { errorImage } from "../constants/imageUrls";
import classnames from "classnames";
import PropTypes from "prop-types";

const ResourceTitleCard = ({ resource, onClick }) => {
  const imageClasses = classnames("w-full object-cover", {
    "add-tint": resource.title
  });
  return (
    <>
      <div
        className="col-span-1 source-card rounded add-pointer-hover"
        onClick={onClick}
      >
        <Image
          url={resource.image}
          defaultUrl={errorImage}
          classNames={imageClasses}
        />
        {resource.activities ? (
          <div className="corner-box shadow">
            <p className="resource-flag">{resource.activities.length}</p>
          </div>
        ) : (
          ""
        )}

        <h1 className="text-center text-white font-bold title-on-card">
          {resource.name || resource.title}
        </h1>
      </div>
    </>
  );
};
ResourceTitleCard.propTypes = {
  resource: PropTypes.object,
  onClick: PropTypes.func
};

export default ResourceTitleCard;
