import React, { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import MainEditSection from "./MainEditSection";
import GenericEditSubSection from "./GenericSubSection";
import DateOfBirthEdit from "./DateOfBirthEdit";
import customToast from "./customToast";
import { Prompt } from "react-router-dom";

function EditProfile() {
  const api = useAPI();
  const apiZero = useAPI(0);
  const [subSection, setSubSection] = useState();
  const [imgUpload, setImgUpload] = useState();
  const [fileData, setFileData] = useState();
  const [filters, setFilters] = useState();
  const [showSave, setShowSave] = useState(false);
  const [userData, setUserData] = useState({
    gender: "",
    name: "",
    username: "",
    date_of_birth: "",
    zip_code: "",
    member_type: "",
    specialties: "",
    service_member: "",
    service: "",
    mos: "",
    veteran: "",
    component: "",
    academy: "",
    graduating_class: "",
    rotc_program: ""
  });
  useEffect(() => {
    api
      .get("user")
      .then(r => {
        const data = r.data;
        const obj = {
          gender: data.gender || "",
          name: data.name || "",
          username: data.username || "",
          date_of_birth: data.date_of_birth || "",
          zip_code: data.zip_code || "",
          member_type: data.member_type || "",
          specialties: data.specialties || "",
          service_member: data.service_member || "",
          service: data.service || "",
          mos: data.mos || "",
          veteran: data.veteran || "",
          component: data.component || "",
          military_academy: data.military_academy || "",
          military_academy_year: data.military_academy_year || "",
          rotc_program: data.rotc_program || ""
        };
        setUserData(obj);
        setImgUpload(data.profile_pic);
      })
      .catch(err => {
        console.log(err);
      });
    apiZero
      .get("/filters")
      .then(resp => {
        const data = { ...resp.data.response };
        const remodeledData = {
          member_type: data["Member Type"],
          service: data["Service"],
          veteran: data["Veteran"],
          component: data["Component"],
          mos: data["MOS"],
          specialties: data["Specialties"],
          military_academy: data["Military Academy"],
          military_academy_year: data["Military Academy Year"],
          rotc_program: data["ROTC Program"],
          gender: data["Gender"],
          service_member: [
            "Active Duty Family",
            "Civilian Mental Health Professional",
            "Clergy",
            "Combat Wounded",
            "Military Mental Health Professional",
            "Sexual Trauma Survivor",
            "Social Worker",
            "Spiritual Counselor",
            "Veteran Family",
            "White Star Family Member"
          ]
        };
        setFilters(remodeledData);
      })
      .catch(err => {
        console.log(err);
      });
  }, [api, apiZero]);
  const updateName = e => {
    setShowSave(true);
    const stateToChange = { ...userData };
    stateToChange[e.target.id] = e.target.value;
    setUserData(stateToChange);
  };

  const changeImage = e => {
    if (e.target.files && e.target.files[0]) {
      setShowSave(true);
      let img = e.target.files[0];
      setImgUpload(URL.createObjectURL(img));
      const formData = new FormData();
      formData.append("filename", img);
      setFileData(formData);
    }
  };
  const renderCorrectSection = () => {
    if (subSection) {
      if (subSection === "date_of_birth") {
        return (
          <DateOfBirthEdit
            subSection={subSection}
            setSubSection={setSubSection}
            userData={userData}
            setUserData={setUserData}
            setShowSave={setShowSave}
          />
        );
      } else {
        return (
          <GenericEditSubSection
            data={filters[subSection]}
            subSection={subSection}
            setSubSection={setSubSection}
            userData={userData}
            setUserData={setUserData}
            setShowSave={setShowSave}
          />
        );
      }
    } else {
      return (
        <MainEditSection
          data={userData}
          setSubSection={setSubSection}
          updateState={updateName}
        />
      );
    }
  };

  const checkZipCode = zip => {
    if (zip.length === 0) {
      return true;
    }
    if (zip.length > 5) {
      return false;
    }
    if (!Number(zip)) {
      return false;
    }
    if (zip.length < 5) {
      return false;
    }
    if (zip.length === 5 && parseInt(zip)) {
      return true;
    }
  };

  const saveUserInfo = () => {
    if (userData.username === "") {
      customToast.warn(
        "A username is required to use this app, be sure that it isn't blank"
      );
    } else {
      if (checkZipCode(userData.zip_code)) {
        setShowSave(false);
        if (fileData) {
          api.patch("user", fileData, userData).then(() => {
            customToast.info("Your Information has been saved!");
          });
        } else {
          api.patch("user", userData).then(() => {
            customToast.info("Your Information has been saved!");
          });
        }
      } else {
        customToast.warn("Zip code must be 5 characters long and just numbers");
      }
    }
  };
  const renderSaveButton = () => {
    if (showSave) {
      return (
        <div
          className="m-4 w-30 p-2 flex justify-center rounded bg-oz-blue text-white font-bold hover:bg-blue-700 add-pointer-hover"
          onClick={saveUserInfo}
        >
          SAVE INFO
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Prompt
        when={showSave}
        message="You are about to leave this page without saving, are you sure you want to do that?"
      />
      <div>
        <div className="flex justify-between">
          <h1 className="text-blue-600 font-bold text-xl flex items-end">
            Your Profile
          </h1>
          {renderSaveButton()}
        </div>
        <div className="border border-blue-600 mb-2"></div>
        <div className="p-4">
          <div className="flex justify-between">
            <div className="flex items-center flex-col">
              <div className=" w-6/12 mr-10 max-w-xs">
                <img
                  src={imgUpload ? imgUpload : ProfilePictureMissing}
                  alt="user"
                  className="inline-block rounded-full border-4 border-yellow-400 w-36 h-36"
                />
              </div>
              <button className="mt-8 pr-8">
                <label className="bg-oz-blue text-white  bg-cover rounded p-2 add-pointer-hover hover:bg-blue-800">
                  Upload<span className="display-none-alt-875"> an Image</span>
                  <input type="file" name="myImage" onChange={changeImage} />
                </label>
              </button>
            </div>

            <div className="pr-2 flex flex-col w-8/12">
              <div className="flex justify-between border-b-2 border-gray-200 mb-10 mt-16">
                <h3>Name</h3>
                <input
                  type="text"
                  placeholder="Name Here"
                  value={userData.name}
                  className="border-none text-right w-36 input-width-alt"
                  id="name"
                  onChange={e => updateName(e)}
                />
              </div>
              <div className="flex justify-between border-b-2 border-gray-200">
                <h3>
                  <span className="display-none-alt-800">Anonymous</span>{" "}
                  Handle*
                </h3>
                <input
                  type="text"
                  placeholder="Username"
                  value={userData.username ? userData.username : ""}
                  className="border-none text-right w-36 input-width-alt"
                  id="username"
                  onChange={e => updateName(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 mb-2 mt-2"></div>
        <div className="w-full flex justify-between bg-gray-200 p-2">
          <div className="text-gray-600 mr-6 text-sm">
            We use the following information to match you with other users or
            Ambassadors who share similar attributes. We will never share your
            personally identifiable data outside of the Objective Zero App.
            Please add as much of the following information as you feel
            comfortable with.
          </div>
        </div>
        <div className="flex justify-center w-full">
          {renderCorrectSection()}
        </div>
        <div className="flex justify-end"></div>
      </div>
    </>
  );
}

export default EditProfile;
