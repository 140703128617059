import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import connectActive from "../Assets/images/contacts_active.png";
import connectInactive from "../Assets/images/contacts.png";
import messagesActive from "../Assets/images/messages_active.png";
import messagesInactive from "../Assets/images/messages_inactive.png";
import messagesAlert from "../Assets/images/messages_alert.png";
import resourcesActive from "../Assets/images/resources_active.png";
import resourcesInactive from "../Assets/images/resources.png";
import favoritesInactive from "../Assets/images/favorites.png";
import favoritesActive from "../Assets/images/favorites_active.png";
import useAPI from "../hooks/useAPI";
import useWindowSize from "../hooks/useWindowSize";
import classnames from "classnames";
import useErrModal from "../hooks/useErrModal";

function Navigation() {
  const activeClass = "border-oz-blue border-b-2 flex self-end";
  const location = useLocation();
  const [connect, setConnect] = useState(false);
  const [messages, setMessages] = useState(false);
  const [resources, setResources] = useState(false);
  const [newMessages, setNewMessages] = useState(false);
  const [favorites, setFavorites] = useState(false);
  const [width] = useWindowSize();
  const errorHandler = useErrModal();
  const v0 = useAPI(0);

  useEffect(() => {
    let value = false;
    v0.get("message_history")
      .then(r => {
        r.data.forEach(obj => {
          if (obj.unreadMessages > 0) {
            value = true;
          }
        });
        setNewMessages(value);
      })
      .catch(err => errorHandler(err));
  }, [v0, errorHandler]);
  useEffect(() => {
    if (location.pathname === "/") {
      setConnect(true);
      setMessages(false);
      setResources(false);
      setFavorites(false);
    }
    if (
      location.pathname === "/messages" ||
      location.pathname.includes("messages")
    ) {
      setMessages(true);
      setConnect(false);
      setResources(false);
      setFavorites(false);
    }
    if (location.pathname === "/resources") {
      setResources(true);
      setConnect(false);
      setMessages(false);
      setFavorites(false);
    }
    if (location.pathname === "/favorites") {
      setFavorites(true);
      setResources(false);
      setConnect(false);
      setMessages(false);
    }
  }, [location, setMessages, setResources, setConnect]);

  const returnMessagesIcon = () => {
    if (newMessages) {
      return messagesAlert;
    } else {
      if (messages) {
        return messagesActive;
      } else {
        return messagesInactive;
      }
    }
  };
  const imageClasses = classnames("mr-2 mb-1 safari-half-image", {
    "w-6": width > 780,
    "w-4": width <= 780
  });
  const listClasses = classnames(" mb-8 flex shadow w-full ", {
    "pl-4": width > 780,
    "text-xs": width <= 780
  });
  const linkClasses = classnames("", {
    "p-4": width > 780,
    "p-2": width <= 780
  });

  return (
    <ul className={listClasses}>
      <li className={linkClasses}>
        <NavLink exact to="/" activeClassName={activeClass} className="flex">
          <img
            src={connect ? connectActive : connectInactive}
            className={imageClasses}
          />
          Connect
        </NavLink>
      </li>
      <li className={linkClasses}>
        <NavLink to="/messages" activeClassName={activeClass} className="flex">
          <img src={returnMessagesIcon()} className={imageClasses} />
          Messages
        </NavLink>
      </li>
      <li className={linkClasses}>
        <NavLink to="/resources" activeClassName={activeClass} className="flex">
          <img
            src={resources ? resourcesActive : resourcesInactive}
            className={imageClasses}
          />
          Resources
        </NavLink>
      </li>
      <li className={linkClasses}>
        <NavLink to="/favorites" activeClassName={activeClass} className="flex">
          <img
            src={favorites ? favoritesActive : favoritesInactive}
            className={imageClasses}
          />
          Favorites
        </NavLink>
      </li>
    </ul>
  );
}

export default Navigation;
