import { useState, useEffect } from "react";
import useAPI from "./useAPI";

const useResourceAPI = (type, id) => {
  const [resource, setResource] = useState({});
  const [similarSources, setSimilarSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [err, setErr] = useState(false);
  const api = useAPI(0);

  useEffect(() => {
    if (type === "activity") {
      api
        .get(`/activities`)
        .then(resp => {
          const activities = resp.data.response.map(el => el.activities).flat();
          const item = activities.filter(obj => obj.id === id);
          if (item.length > 0) {
            const filteredNotItem = activities.filter(
              obj => obj.category_id === item[0].category_id
            );
            if (item.length > 0) {
              setResource(item[0]);
              setSimilarSources(filteredNotItem);
              setIsLoading(false);
            }
          } else {
            setErr(true);
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    }
    if (type === "resource") {
      api
        .get(`/resources`)
        .then(resp => {
          const filteredResp = resp.data.response.filter(obj => obj.id === id);
          const NotFilteredResp = resp.data.response;
          if (filteredResp.length > 0) {
            setResource(filteredResp[0]);
            setSimilarSources(NotFilteredResp);
            setIsLoading(false);
          } else {
            setErr(true);
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [id, type, api]);
  return [resource, similarSources, isLoading, err];
};
export default useResourceAPI;
