import { useState, useEffect } from "react";
import useAPI from "./useAPI";
import { useHistory } from "react-router-dom";
import useCurrentUser from "./useCurrentUser";
import useAuth from "./useAuth";

const Chat = require("twilio-chat");

function useTwilio() {
  const v1 = useAPI(1);
  const api = useAPI(0);
  const [client, setClient] = useState();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { authToken, incomingCall } = useAuth();
  useEffect(() => {
    console.log("setting null");
    setClient(null);
  }, [authToken]);

  useEffect(() => {
    if (!currentUser) return;
    api
      .post("twilio_chat_token", {
        twilio_identity: currentUser.twilio_identity,
      })
      .then((r) => {
        return Chat.Client.create(r.data.response.twilio_chat_token).catch(
          (err) => {
            console.log("ERROR FOR CHAT CLIENT: ", err);
          }
        );
      })
      .then((client) => {
        setClient(client);
        client.on("channelInvited", function (channel) {
          channel.join().catch((err) => {
            console.log(err);
          });
        });
        client.on("tokenAboutToExpire", function () {
          console.log("EXPIRED TWILIO TOKEN RESET :D");
          api
            .post("twilio_chat_token", {
              twilio_identity: currentUser.twilio_identity,
            })
            .then((r) => {
              client.updateToken(r.data.response.twilio_chat_token);
            })
            .catch((err) => {
              console.log("ERROR UPDATNG CHAT CLIENT: ", err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser, incomingCall, v1, history, api]);

  return client;
}

export default useTwilio;
