import React, { useContext } from "react";
import { AmbassadorContext } from "../pages/Connect";
import AmbassadorPicture from "./AmbassadorPicture";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import PropTypes from "prop-types";

function AmbassadorCard({ ambassador }) {
  const { name, username } = ambassador;
  const profilePicUrl = ambassador.profile_pic || ProfilePictureMissing;

  const {
    activeAmbassador,
    setActiveAmbassador,
    setShowInfoWindow
  } = useContext(AmbassadorContext);
  const active = ambassador === activeAmbassador;

  return (
    <div
      className="mt-4 cursor-pointer"
      onClick={() => {
        setActiveAmbassador(ambassador);
        setShowInfoWindow(true);
      }}
    >
      <div
        className={`flex p-2 items-center shadow rounded-sm ${
          active ? "bg-oz-blue text-white" : "text-oz-blue"
        }`}
      >
        <AmbassadorPicture url={profilePicUrl} size={12} />
        <div className="w-4"></div>
        <span className="flex-1">{name || username}</span>
      </div>
    </div>
  );
}

export default AmbassadorCard;
AmbassadorCard.propTypes = {
  ambassador: PropTypes.object
};
