import React from "react";
import MessageTimestamp from "./MessageTimestamp";
import MessagePicture from "./MessagePicture";
import useCurrentUser from "../hooks/useCurrentUser";
import PropTypes from "prop-types"

function OwnMessage({ message }) {
  const user = useCurrentUser();
  if (message) {
    if (
      message.state.body.includes(
        "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,"
      )
    ) {
      console.log(
        "didn't render message from api that said: ",
        message.state.body
      );
      return "";
    }
  }
  return (
    <div className="w-full flex mb-8">
      <div className="flex-1"></div>

      <div>
        <span className="w-full text-oz-gray text-tiny text-right mb-2 mr-12">
          <MessageTimestamp date={message.dateCreated} />
        </span>

        <div className="flex items-center justify-end">
          <div className="bg-oz-blue rounded p-4 mr-4 text-white text-sm">
            {message.body}
          </div>

          <MessagePicture url={user?.profile_pic} />
        </div>
      </div>
    </div>
  );
}

OwnMessage.propTypes = {
  message: PropTypes.object
}

export default OwnMessage;
