import React, { useState, useEffect, useRef } from "react";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import AmbassadorPicture from "./AmbassadorPicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faVideoSlash,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import classnames from "classnames";
import useAuth from "../hooks/useAuth";

const Participant = ({
  participant,
  user,
  type,
  top,
  isFullScreen,
  setIsFullScreen,
  mute,
  handle,
  videoMute,
  showOrHideVideo,
  rws = null
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const {
    setAppAudioTracks,
    setRemoteAudioTracks,
    setRemoteVideoTracks
  } = useAuth();

  const videoRef = useRef();
  const audioRef = useRef();

  const videoHolderClasses = classnames("", {
    "video-holder relative": top && !isFullScreen,
    " absolute flex self-start mb-24": !top && !isFullScreen,
    "fixed bottom-0 left-0 shadow shadow-lg": !top && isFullScreen
  });

  const videoChildClassnames = classnames("", {
    "not-full-screen": top && !isFullScreen,
    "fullscreen-video": isFullScreen,
    relative: top,
    " w-auto h-40 justify-start shadow shadow-lg max-custom-z": !top,
    "bg-black": videoMute
  });
  const videoIconClasses = classnames(
    "rounded-full shadow add-pointer-hover h-10 w-10 flex justify-center p-2 pt-3 mr-4",
    {
      "bg-gray-500": videoMute,
      "bg-white": !videoMute
    }
  );

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === "video" && type === "video") {
        setVideoTracks(videoTracks => [...videoTracks, track]);
        if (top) {
          setRemoteVideoTracks(videoTracks => [...videoTracks, track]);
        }
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
        if (top) {
          setRemoteAudioTracks(audioTracks => [...audioTracks, track]);
        } else {
          setAppAudioTracks(audioTracks => [...audioTracks, track]);
        }
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === "video" && type === "video") {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
        if (top) {
          setRemoteVideoTracks(videoTracks =>
            videoTracks.filter(v => v !== track)
          );
        }
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
        if (top) {
          setRemoteAudioTracks(audioTracks =>
            audioTracks.filter(a => a !== track)
          );
        } else {
          setAppAudioTracks(audioTracks =>
            audioTracks.filter(a => a !== track)
          );
        }
      }
    };
    if (type === "video") {
      setVideoTracks(trackpubsToTracks(participant.videoTracks));
    }
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if (top === true) {
      setRemoteAudioTracks(trackpubsToTracks(participant.audioTracks));
    } else {
      setAppAudioTracks(trackpubsToTracks(participant.audioTracks));
    }

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [
    participant,
    type,
    setAppAudioTracks,
    setRemoteAudioTracks,
    top,
    user,
    setRemoteVideoTracks
  ]);

  useEffect(() => {
    if (type === "video") {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    }
  }, [videoTracks, type]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, type]);

  useEffect(() => {
    if (top && rws) {
      const videoTrack = videoTracks[0];
      if (participant && videoTrack && videoMute) {
        rws.send(
          JSON.stringify({
            method: "video_mute_complete",
            user_id: participant.identity
          })
        );
        videoTrack.detach();
      } else if (participant && videoTrack && !videoMute) {
        videoTrack.attach(videoRef.current);
        rws.send(
          JSON.stringify({
            method: "video_mute_complete",
            user_id: participant.identity
          })
        );
      }
    }
  }, [participant, videoTracks, videoMute, top, rws]);

  const handleExit = () => {
    setIsFullScreen(false);
    handle.exit();
  };
  const renderFullScreenIcons = () => {
    if (top && isFullScreen) {
      return (
        <div className="text-white name-holder absolute bottom-0 w-full flex justify-end p-1 bot-holder">
          <div className={videoIconClasses} onClick={showOrHideVideo}>
            {videoMute ? (
              <FontAwesomeIcon icon={faVideoSlash} color={"white"} size="" />
            ) : (
              <FontAwesomeIcon icon={faVideo} color={"gray"} size="" />
            )}
          </div>
          <div
            className="rounded-full shadow add-pointer-hover h-10 w-10 flex justify-center p-2 pt-3 bg-white"
            onClick={handleExit}
          >
            <FontAwesomeIcon icon={faExpand} color="gray" size="" />
          </div>
        </div>
      );
    } else {
      return "";
    }
  };
  const returnRightAudio = () => {
    if (!top) {
      return <audio ref={audioRef} autoPlay={true} muted={true} />;
    } else {
      return <audio ref={audioRef} autoPlay={true} muted={mute} />;
    }
  };

  return (
    <>
      {type === "video" ? (
        <>
          <div
            className={videoHolderClasses}
            id={top ? "remote-media-div" : "local-media-div"}
          >
            {videoMute && top ? (
              <div className="bg-black w-full h-full absolute custom-z">
                <div className="flex w-full h-full justify-center items-center">
                  <FontAwesomeIcon
                    icon={faVideoSlash}
                    color={"white"}
                    size="lg"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <video
              ref={videoRef}
              autoPlay={true}
              className={videoChildClassnames}
              playsInline
              id={`video-link-${user.id}`}
            />
          </div>
          {renderFullScreenIcons()}
          {returnRightAudio()}
        </>
      ) : top ? (
        <>
          <audio ref={audioRef} autoPlay={true} muted={mute} />
          {/* <div className="rounded-full shadow call-animation w-4/12 mb-10 overflow-hidden"> */}
          <div className="flex">
            <AmbassadorPicture
              url={user.profile_pic || ProfilePictureMissing}
              size={32}
            />
            <div className="w-4"></div>

            <table>
              <tbody>
                <tr>
                  <td className="text-oz-gray text-right pr-1">Ambassador: </td>
                  <td className="text-oz-blue">{user.name}</td>
                </tr>
                <tr>
                  <td className="text-oz-gray text-right pr-1">Age: </td>
                  <td className="text-oz-blue">{user.age}</td>
                </tr>
                <tr>
                  <td className="text-oz-gray text-right pr-1">Service: </td>
                  <td className="text-oz-blue">{user.service}</td>
                </tr>
                <tr>
                  <td className="text-oz-gray text-right pr-1">Component: </td>
                  <td className="text-oz-blue">{user.component}</td>
                </tr>
                <tr>
                  <td className="text-oz-gray text-right pr-1">Veteran: </td>
                  <td className="text-oz-blue">{user.veteran}</td>
                </tr>
                <tr>
                  <td className="text-oz-gray text-right pr-1">MOS: </td>
                  <td className="text-oz-blue">{user.mos}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <audio ref={audioRef} autoPlay={true} muted={true} />
        </>
      )}
    </>
  );
};
Participant.propTypes = {
  participant: PropTypes.object,
  user: PropTypes.object,
  type: PropTypes.string,
  picture: PropTypes.string,
  isFullScreen: PropTypes.bool,
  setIsFullScreen: PropTypes.func,
  mute: PropTypes.bool,
  top: PropTypes.bool,
  handle: PropTypes.object,
  videoMute: PropTypes.bool,
  showOrHideVideo: PropTypes.func,
  rws: PropTypes.any
};

export default Participant;
