import React from "react";
import ModalBase from "./ModalBase";
import useAuth from "../hooks/useAuth";

const UnavailableAmbassadorModal = () => {
  const {
    unavailableAmbassadorModal,
    setUnavailableAmbassadorModal
  } = useAuth();

  return (
    <ModalBase
      show={unavailableAmbassadorModal}
      setShow={setUnavailableAmbassadorModal}
      isUnavailable={true}
    >
      <div className="bg-black bg-opacity-50 add-blur text-white font-bold p-4">
        Sorry, but this ambassador is unavailable at the moment. You can message
        them or try again later.
        <div className="w-full flex justify-end mt-2">
          <button
            className="bg-transparent border-2 border-white text-white hover:bg-white hover:text-black py-1 px-2 rounded-full w-2/12"
            onClick={() => setUnavailableAmbassadorModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </ModalBase>
  );
};
export default UnavailableAmbassadorModal;
