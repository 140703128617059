import React, { useState } from "react";
import StaticModalBase from "./StaticModalBase.js";
import PropTypes from "prop-types";
import useAPI from "../hooks/useAPI.js";
import useAuth from "../hooks/useAuth";
import { useHistory } from "react-router-dom";
import useTwilio from "../hooks/useTwilio.js";
import {
  NEW_MESSAGE,
  INCOMING_CALL,
  WAITING_USER,
  EXISTING_NEW_MESSAGE
} from "../constants/CadetConsentOptions";

function CadetModal({ cadetInfo }) {
  const {
    showCadetModal,
    setShowCadetModal,
    setSenderPayload,
    setShowCallModal
  } = useAuth();
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [disagreedCheck, setDisagreeCheck] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const api = useAPI();
  const history = useHistory();
  const twilio = useTwilio();
  const submitAction = () => {
    if (agreeCheck) {
      api
        .post(`cadets_program/${cadetInfo.id || cadetInfo.senderDetail.id}`)
        .then(() => {
          switch (cadetInfo.method) {
            case NEW_MESSAGE:
              history.push("/messages");
              break;
            case WAITING_USER:
              history.push(`/messages/${cadetInfo.id}`);
              break;
            case INCOMING_CALL:
              setSenderPayload(cadetInfo);
              setShowCallModal(true);
              break;
          }
          setShowCadetModal(false);
        });
    } else {
      if (cadetInfo && cadetInfo.channel_name) {
        twilio.getChannelByUniqueName(cadetInfo.channel_name).then(ch => {
          ch.leave();
          setShowCadetModal(false);
          if ((cadetInfo.method = EXISTING_NEW_MESSAGE)) {
            history.push("/");
          }
        });
      } else {
        setShowCadetModal(false);
      }
    }
  };
  const agreeCheckHandler = () => {
    if (agreeCheck) {
      setAgreeCheck(false);
      setDisagreeCheck(false);
      setIsDisabled(true);
    } else {
      setAgreeCheck(true);
      setDisagreeCheck(false);
      setIsDisabled(false);
    }
  };
  const disagreeCheckHandler = () => {
    if (disagreedCheck) {
      setAgreeCheck(false);
      setDisagreeCheck(false);
      setIsDisabled(true);
    } else {
      setAgreeCheck(false);
      setDisagreeCheck(true);
      setIsDisabled(false);
    }
  };
  return (
    <StaticModalBase show={showCadetModal} setShow={setShowCadetModal}>
      <div className="warning-container p-4">
        <div className="flex justify-center w-full text-lg font-bold text-oz-blue">
          Special Cadet Program Consent
        </div>
        <div className="cadet-holder p-2">
          The following message is from someone participating in a study that
          involves the chat log you are about to enter. Your text exchange with
          the participant may be observed by researchers for quality assurance
          purposes. Your personally identifying information will not be looked
          at by any party involved, nor will your side of the text chat
          conversation be used in the study. If you give your consent, please
          hit “yes” to continue with the conversation over this app. Otherwise,
          please hit “no” and the chat will be moved on to the next available
          ambassador.
        </div>
        <div className="flex justify-evenly p-2">
          <div>
            <input
              type="checkbox"
              id="agree"
              name="agree"
              value="agree"
              onClick={agreeCheckHandler}
              checked={agreeCheck}
              className="add-pointer-hover"
              readOnly
            />
            <label htmlFor="agree"> I Agree</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="disagree"
              name="disagree"
              value="disagree"
              onClick={disagreeCheckHandler}
              checked={disagreedCheck}
              className="add-pointer-hover"
              readOnly
            />
            <label htmlFor="disagree"> I Disagree</label>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end pr-4 pb-4">
        <button
          className="rounded w-2/12 bg-oz-blue p-2 text-white font-bold text-center add-pointer-hover"
          onClick={submitAction}
          disabled={isDisabled}
        >
          Submit
        </button>
      </div>
    </StaticModalBase>
  );
}
CadetModal.propTypes = {
  cadetInfo: PropTypes.object
};
export default CadetModal;
