import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PropTypes from "prop-types";
import "./DateOfBirth.css";

function DateOfBirthEdit({
  setSubSection,
  setUserData,
  userData,
  setShowSave
}) {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(1970);
  const today = moment();
  const saveAndReturnToMainScreen = () => {
    setShowSave(true);
    setSubSection();
    let stateToChange = { ...userData };
    stateToChange.date_of_birth = `${month}/${day}/${year}`;
    setUserData(stateToChange);
  };
  const returnDays = () => {
    const daysInMonth = moment(`${year}-${month}`).daysInMonth();
    let count = daysInMonth;
    let days = [];
    for (let i = 1; i <= count; i++) {
      days.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return days;
  };
  useEffect(() => {
    if (userData.date_of_birth) {
      const dates = userData.date_of_birth.split("/");
      setMonth(dates[0]);
      setDay(dates[1]);
      setYear(dates[2]);
    }
  }, [userData]);
  const returnYears = () => {
    const years = [];
    for (let i = 1930; i <= today.format("YYYY"); i++) {
      years.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return years;
  };
  const changeMDY = e => {
    if (e.target.id === "month") {
      setMonth(e.target.value);
    }
    if (e.target.id === "day") {
      setDay(e.target.value);
    }
    if (e.target.id === "year") {
      setYear(e.target.value);
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full font-bold text-blue-400 mt-2">
        <FontAwesomeIcon
          icon={faChevronLeft}
          color="#60A5FA"
          size="lg"
          onClick={() => saveAndReturnToMainScreen()}
          className="mr-4"
        />{" "}
        Date of Birth
      </div>
      <div className="pl-8 mt-4 flex w-full justify-center">
        <div className="dropdown mr-2">
          <select value={month} id="month" onChange={e => changeMDY(e)}>
            <option value="1">Janaury</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div className="dropdown mr-2">
          <select id="day" value={day} onChange={e => changeMDY(e)}>
            {returnDays().map(opt => opt)}
          </select>
        </div>
        <div className="dropdown mr-2">
          <select id="year" value={year} onChange={e => changeMDY(e)}>
            {returnYears().map(opt => opt)}
          </select>
        </div>
      </div>
    </div>
  );
}
DateOfBirthEdit.propTypes = {
  setSubSection: PropTypes.func,
  setUserData: PropTypes.func,
  userData: PropTypes.object,
  setShowSave: PropTypes.func
};

export default DateOfBirthEdit;
