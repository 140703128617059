import React, { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import useCurrentUser from "../hooks/useCurrentUser";
import moment from "moment";
import RecentActivityCard from "./RecentActivityCard";
import { MDY } from "../constants/dates";
function RecentActivity() {
  const api = useAPI();
  const currentUser = useCurrentUser();
  const today = moment();
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    api
      .get("recent-activity")
      .then(r => {
        setActivities(r.data.reverse());
      })
      .catch(err => {
        console.log(err);
      });
  }, [api]);

  const compareCards = (d1, d2) => {
    const m1 = d1.format("MM/DD/YYYY");
    const m2 = d2.format("MM/DD/YYYY");
    if (m1 === m2) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <h1 className="text-blue-600 font-bold text-xl">
        {activities.length > 0 ? "Activites" : "No Recent Activities"}
      </h1>
      <div className="border border-blue-600 mb-2"></div>
      <div className="card-holder overflow-y-auto ambassadorListHeight">
        {activities.map((act, i) => {
          if (act.call_start_time) {
            const momentObject = moment(Number(act.call_start_time));
            if (i === 0) {
              return (
                <>
                  <div className="text-gray-600 font-bold">
                    {momentObject.isSame(today)
                      ? "Today"
                      : momentObject.format(MDY)}
                  </div>
                  <RecentActivityCard
                    act={act}
                    key={i}
                    userId={currentUser.id}
                  />
                </>
              );
            }
            if (
              !compareCards(
                momentObject,
                moment(Number(activities[i - 1].call_start_time))
              )
            ) {
              return (
                <>
                  <div className="text-gray-600 font-bold">
                    {momentObject.format(MDY)}
                  </div>
                  <RecentActivityCard
                    act={act}
                    key={i}
                    activities={activities}
                    userId={currentUser.id}
                  />
                </>
              );
            } else {
              return (
                <RecentActivityCard act={act} key={i} userId={currentUser.id} />
              );
            }
          }
        })}
      </div>
    </div>
  );
}

export default RecentActivity;
