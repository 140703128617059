import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

function TermsAndConditions() {
  const titleClasses = classnames("font-bold text-blue-600 text-xl");
  const history = useHistory();
  return (
    <>
      <div className="pt-4">
        <div className="flex">
          <div className="mr-6" onClick={() => history.push("/")}>
            <FontAwesomeIcon icon={faChevronLeft} color="grey" />
          </div>
          <h1 className="text-blue-600 font-bold text-xl">
            Terms and Conditions
          </h1>
        </div>
        <div className="border border-blue-600 mb-2"></div>
        <div>
          <span className={titleClasses}>1. Introduction</span>
          <br />
          <br />
          Welcome to the Objective Zero mobile app! Your use of the Objective
          Zero mobile app and its services, to include those provided through
          the Objective Zero website (www.objectivezero.org), the Objective Zero
          mobile application, and any other software or services provided by
          Objective Zero is governed by these Terms of Use (the “Terms”).
          Accordingly, is it important to carefully read the Terms before using
          the App. For the purposes of these Terms, “we,” “our,” “us,”
          “Objective Zero,” and “App” refer to the Objective Zero Foundation and
          the providers and operators of the Objective Zero App.
          <br />
          In order to use the App, you must first agree to these Terms. If you
          are using the App on behalf of an organization, you are agreeing to
          these Terms for that organization and promising that you have the
          authority to bind that organization to these Terms. In that case,
          “you” and “your” will also refer to that organization, wherever
          possible.
          <br />
          You must be over 18 years of age to use the App, and children under
          the age of 18 cannot use or register for the App.
          <br />
          You agree your purchases and/or use of the App are not contingent on
          the delivery of any future functionality or features or dependent on
          any oral or written public comments made by Objective Zero or any of
          its affiliates regarding future functionality or features.
          <br />
          BY USING, DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING THE APP OR
          ANY MATERIALS INCLUDED IN OR WITH THE APP, YOU HEREBY AGREE TO BE
          BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, THEN YOU MAY
          NOT USE, DOWNLOAD, INSTALL, OR OTHERWISE ACCESS THE OBJECTIVE ZERO
          APP.
          <br />
          <br />
          <span className={titleClasses}>2. Not Medical Advice</span>
          <br /> <br />
          You acknowledge and agree that the App constitutes, primarily, a
          personalized self-help system designed to help users improve their
          well-being and that if you choose to utilize the App you are solely
          responsible for deciding which of the activities and resources in the
          App you put into practice and how to use those activities and
          resources. Accordingly, you acknowledge that Objective Zero is not a
          health care provider, and does not provide you with any medical
          advice.
          <br />
          Any advice or other materials provided through the App are intended
          for general information purposes only. They are not intended to be
          relied upon and are not a substitute for professional medical advice
          based on your personal circumstances. Such advice and other materials
          are intended to support the relationship between you and your health
          care providers (as the case may be), and not replace it. We are not
          liable or responsible for any actions taken due to your having read or
          been told about such advice or other materials. In particular, to the
          fullest extent permitted by law, we give no representation or
          warranties about the accuracy, completeness, or suitability for any
          purpose of the advice or other materials and information published on
          or through the App.
          <br />
          If you receive advice from a doctor or other qualified medical
          professional which conflicts with anything contained in the App then
          the former should take precedence. Never disregard professional
          medical advice or delay in seeking it because of something you have
          read through the App. IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY,
          CALL YOUR DOCTOR OR 911 IMMEDIATELY.
          <br />
          This provision is consistent with and in addition to the provisions of
          section 13 (Limitation of Liability) of these Terms.
          <br />
          <br />
          <span className={titleClasses}>
            3. Use of Services by Medical Professionals
          </span>
          <br />
          In addition to functioning as a standalone personalized self-help
          system, the App may also be used by certain medical professionals
          and/or health care providers (“Medical Professionals”) to monitor or
          otherwise assist in their patient’s use of the App (“Patients”). Such
          use shall require the Patient’s affirmative consent, and shall also be
          subject to the terms of a separate agreement (the “Medical
          Professionals Agreement”), available on request to
          contactus@objectivezero.org. In the event of any conflict between
          these Terms and the terms of the Medical Professionals Agreement, the
          terms of the Medical Professionals Agreement shall control with
          respect to use of the App by Medical Professionals. In all other
          matters, these Terms shall control.
          <br />
          <br />
          <span className={titleClasses}>4. Your Account</span>
          <br />
          In the course of registering for or using the App, you may be required
          to provide Objective Zero with certain information, including your
          name, email address, username and a password (“Credentials”).
          Objective Zero handles such information with the utmost attention,
          care, and security. Nonetheless, you, not Objective Zero, shall be
          responsible for maintaining and protecting your Credentials in
          connection with the App. If your contact information, or other
          information relating to your username or password changes, you must
          notify Objective Zero promptly and keep such information current. You
          are solely responsible for any activity using your Credentials,
          whether or not you authorized that activity. You should immediately
          notify Objective Zero of any unauthorized use of your Credentials or
          if your email or password has been hacked or stolen. If you discover
          that someone is using your Credentials without your consent, or you
          discover any other breach of security, you agree to notify Objective
          Zero immediately.
          <br />
          <br />
          <span className={titleClasses}>5. Content and License from You</span>
          <br />
          A variety of information, reviews, recommendations, messages,
          comments, posts, text, graphics, software, photographs, videos, data,
          and other materials (“Content”) may be made available through the App
          by Objective Zero or its suppliers (“Objective Zero-Supplied
          Content”). While Objective Zero strives to keep the Content that it
          provides through the App accurate, complete, and up-to-date, Objective
          Zero cannot guarantee, and is not responsible for the accuracy,
          completeness, or timeliness of any Objective Zero-Supplied Content.
          <br />
          You acknowledge that you may also be able to create, transmit, publish
          or display information (such as data files, written text, computer
          software, music, audio files or other sounds, photographs, videos or
          other images) through use of the App. All such information is referred
          to below as “User Content.”
          <br />
          You agree that you are solely responsible for (and that Objective Zero
          has no responsibility to you or to any third party for) any User
          Content, and for the consequences of your actions (including any loss
          or damage which Objective Zero may suffer) in connection with such
          User Content. If you are registering for these Services on behalf of
          an organization, you also agree that you are also responsible for the
          actions of associated Users and for any User Content that such
          associated Users might upload, record, publish, post, link to, or
          otherwise transmit or distribute through use of the App.
          <br />
          Objective Zero may refuse to store, provide, or otherwise maintain
          your User Content for any or no reason. Objective Zero may remove your
          User Content from the App at any time if you violate these Terms or if
          the App or your account is canceled or suspended. If User Content with
          an expiration date is stored using the App, Objective Zero may also
          delete the User Content as of that date. User Content that is deleted
          may be irretrievable. You agree that Objective Zero has no
          responsibility or liability for the deletion or failure to store any
          User Content or other communications maintained or transmitted through
          use of the App.
          <br />
          Objective Zero reserves the right (but shall have no obligation) to
          remove User Content from the App, in its discretion.
          <br />
          By submitting, posting or otherwise uploading User Content on or
          through the App you give Objective Zero a worldwide, royalty-free,
          nonexclusive, fully sub-licensable, and perpetual license as set
          below:
          <br />
          <strong>a.</strong> With respect to User Content that you submit, post
          or otherwise make publicly or generally available through the App
          (such as postings made on the App’ public forums), the license to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute , publicly perform, and publicly display such User
          Content (in whole or part) worldwide via the App or otherwise, and/or
          to incorporate it in other works in any form, media, or technology now
          known or later developed for any legal purpose; and
          <br />
          <strong>b.</strong> With respect to User Content that you submit, post
          or otherwise make available privately via the App (such as
          communications made to a specific user or User Content which you
          upload for your own personal use), the license to use, reproduce,
          modify, adapt, publish, translate, create derivative works from,
          distribute, publicly perform and publicly display such User Content
          for the sole purpose of enabling Objective Zero to provide you with
          the App.
          <br />
          <br />
          <span className={titleClasses}>6. Proprietary Rights</span>
          <br />
          You acknowledge and agree that Objective Zero (or Objective Zero’s
          licensors) own all legal right, title and interest in and to the App
          and that the App are protected by copyrights, trademarks, patents, or
          other proprietary rights and laws (whether those rights happen to be
          registered or not, and wherever in the world those rights may exist).
          <br />
          Except as provided in Section 5, Objective Zero acknowledges and
          agrees that it obtains no right, title, or interest from you (or your
          licensors) under these Terms in or to any Content that you create,
          submit, post, transmit, share or display on, or through, the App,
          including any intellectual property rights which subsist in that
          Content (whether those rights happen to be registered or not, and
          wherever in the world those rights may exist). Unless you have agreed
          otherwise in writing with Objective Zero, you agree that you are
          responsible for protecting and enforcing those rights and that
          Objective Zero has no obligation to do so on your behalf.
          <br />
          <br />
          <span className={titleClasses}>
            7. License from Objective Zero and Restrictions on Use
          </span>
          <br />
          Objective Zero gives you a personal, worldwide, royalty-free,
          non-assignable and non-exclusive license to use the software and
          Objective Zero-Supplied Content provided to you as part of the App.
          This license is for the sole purpose of allowing you to utilize the
          App as a personalized self-help system to help you improve your
          well-being in the manner permitted by these Terms. Additionally, if
          you are a Medical Professional utilizing these Services in conjunction
          with a patient who is also a user of the App, you may utilize the App
          for the limited purpose of monitoring or assisting such patient with
          the same, so long as the patient has given you permission to do so.
          <br />
          You may not (and you may not permit anyone else to): (a) copy, modify,
          create a derivative work of, reverse engineer, decompile or otherwise
          attempt to extract the source code of the App or any part thereof,
          unless this is expressly permitted or required by law, or unless you
          have been specifically told that you may do so by Objective Zero, in
          writing (e.g., through an open source software license); or (b)
          attempt to disable or circumvent any security mechanisms used by the
          App or any applications running on the App.
          <br />
          You may not engage in any activity that interferes with or disrupts
          the App (or the servers and networks which are connected to the App).
          <br />
          If it is necessary for Objective Zero to collect fees, you may not
          access the App in a manner intended to avoid incurring fees.
          <br />
          You may not access the App for the purpose of bringing an intellectual
          property infringement claim against Objective Zero or for the purpose
          of creating a product or service competitive with the App.
          <br />
          You agree that you will not upload, record, publish, post, link to,
          transmit or distribute User Content, or otherwise utilize the App in a
          manner that: (i) advocates, promotes, incites, instructs, informs,
          assists or otherwise encourages violence or any illegal activities;
          (ii) infringes or violates the copyright, patent, trademark, service
          mark, trade name, trade secret, or other intellectual property rights
          of any third party or Objective Zero, or any rights of publicity or
          privacy of any party; (iii) attempts to mislead others about your
          identity or the origin of a message or other communication, or
          impersonates or otherwise misrepresents your affiliation with any
          other person or entity, or is otherwise materially false, misleading,
          or inaccurate; (iv) promotes, solicits or comprises inappropriate,
          harassing, abusive, profane, hateful, defamatory, libelous,
          threatening, obscene, indecent, vulgar, pornographic or otherwise
          objectionable or unlawful content or activity; (v) is harmful to
          minors; (vi) utilizes or contains any viruses, Trojan horses, worms,
          time bombs, or any other similar software, data, or programs that may
          damage, detrimentally interfere with, surreptitiously intercept, or
          expropriate any system, data, personal information, or property of
          another; or (vii) violates any law, statute, ordinance, or regulation
          (including without limitation the laws and regulations governing
          export control, unfair competition, antidiscrimination, or false
          advertising).
          <br />
          You may not use the App if you are a person barred from receiving the
          App under the laws of the United States or other countries, including
          the country in which you are resident or from which you use the App.
          You affirm that you are over the age of 18, as the App are not
          intended for individuals under 18.
          <br />
          <br />
          <span className={titleClasses}>8. Pricing Terms</span>
          <br />
          Subject to the Terms, the App is provided to you without charge up to
          certain usage limits, and usage over these limits may require purchase
          of additional resources and the payment of fees. While free at this
          time, Objective Zero reserves the right to charge for content or
          access to the App in the future. Objective Zero will notify its users
          if it chooses to charge for content in the future, at which time users
          can decide whether or not to continue using the App. Users will not be
          charged without their consent.
          <br />
          <br />
          <span className={titleClasses}>9. Privacy Policies</span>
          <br />
          These Services are provided in accordance with our Privacy Policy,
          which can be found at www.objectivezero.org/privacy. You agree to the
          use of your User Content and personal information in accordance with
          these Terms and Objective Zero’s Privacy Policy.
          <br />
          Notwithstanding anything to the contrary, if you are using the App on
          behalf of an organization, you agree to permit Objective Zero to
          identify you as a customer and to use your name and/or logo in
          Objective Zero’s website and marketing materials.
          <br />
          <br />
          <span className={titleClasses}>
            10. Modification and Termination of Services
          </span>
          <br />
          Objective Zero is constantly evolving and innovating in order to
          provide the best possible experience for its users. You acknowledge
          and agree that the form and nature of the App which Objective Zero
          provides may change from time to time without prior notice to you,
          subject to the terms in its Privacy Policy. Changes to the form and
          nature of the App will be effective with respect to all versions of
          the App; examples of changes to the form and nature of the App include
          without limitation changes to fee and payment policies, security
          patches, added functionality, automatic updates, and other
          enhancements. Any new features that may be added to the website or the
          App from time to time will be subject to these Terms, unless stated
          otherwise.
          <br />
          You may terminate these Terms at any time by canceling your account on
          the App. You will not receive any refunds if you cancel your account.
          <br />
          You agree that Objective Zero, in its sole discretion and for any or
          no reason, may terminate your account or any part thereof. You agree
          that any termination of your access to the App may be without prior
          notice, and you agree that Objective Zero will not be liable to you or
          any third party for such termination.
          <br />
          If you connect with your Medical Professional through the App and
          later remove the connection with that Medical Professional, you will
          be required to sign a HIPAA authorization if you want to continue to
          use the App. If you do not sign the HIPAA authorization, Objective
          Zero will be required to terminate the App and your account. You will
          also be required to sign a HIPAA authorization to continue to use the
          App if the relationship between Objective Zero and your Medical
          Professional terminates. It is your responsibility to notify Objective
          Zero if you sever such connections and to request a HIPAA
          authorization form.
          <br />
          Upon any termination of the App or your account these Terms will also
          terminate, but all provisions of these Terms which, by their nature,
          should survive termination, shall survive termination, including,
          without limitation, ownership provisions, warranty disclaimers, and
          limitations of liability.
          <br />
          <br />
          <span className={titleClasses}>11. Changes to the Terms</span>
          <br />
          These Terms may be amended or updated from time to time without notice
          and may have changed since your last visit to the website or use of
          the App. It is your responsibility to review these Terms for any
          changes. By continuing to access or use the App after revisions become
          effective, you agree to be bound by the revised Terms. If you do not
          agree to the new Terms, please stop using the App. Please visit this
          page regularly to review these Terms for any changes.
          <br />
          <br />
          <span className={titleClasses}>12. DISCLAIMER OF WARRANTY</span>
          <br />
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE APP IS AT YOUR
          SOLE RISK AND THAT THE APP IS PROVIDED “AS IS” AND “AS AVAILABLE.”
          <br />
          OBJECTIVE ZERO, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS
          MAKE NO EXPRESS WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES
          REGARDING THE APP, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WITHOUT
          LIMITING THE GENERALITY OF THE FOREGOING, OBJECTIVE ZERO, ITS
          SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT OR
          WARRANT TO YOU THAT: (A) YOUR USE OF THE APP WILL MEET YOUR
          REQUIREMENTS, (B) YOUR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY,
          SECURE OR FREE FROM ERROR, AND (C) USAGE DATA PROVIDED THROUGH THE APP
          WILL BE ACCURATE.
          <br />
          NOTHING IN THESE TERMS, INCLUDING SECTIONS 12 AND 13, SHALL EXCLUDE OR
          LIMIT OBJECTIVE ZERO’S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT
          BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.
          <br />
          DO NOT RELY ON THE OBJECTIVE ZERO MOBILE APPLICATION FOR HELP IF YOU
          ARE FEELING SUICIDAL. INSTEAD, OBJECTIVE ZERO STRONGLY RECOMMENDS THAT
          YOU CALL 911OR CONTACT YOUR LOCAL EMERGENCY ROOM FOR IMMEDIATE HELP.
          USERS OF THE OBJECTIVE ZERO APP USE IT AT THEIR OWN RISK AND ARE
          THEMSELVES LIABLE FOR ANY OUTCOMES OR PERCEIVED OUTCOMES AS A RESULT
          OF USING THE OBJECTIVE ZERO APP. OBJECTIVE ZERO CANNOT BE HELD
          ACCOUNTABLE FOR THOSE WITH SUICIDAL TENDENCIES AND THOUGHTS OR FOR
          THEIR ACTIONS.
          <br />
          If you find any information on the App to be inaccurate, please inform
          Objective Zero by emailing: contactus@objectivezero.org
          <br />
          <br />
          <span className={titleClasses}>13. Limaitation and Liability</span>
          <br />
          SUBJECT TO SECTION 12 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT
          OBJECTIVE ZERO, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS
          SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL
          CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU,
          HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE,
          BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY
          OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS
          OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
          OR OTHER INTANGIBLE LOSS. THESE LIMITATIONS SHALL APPLY
          NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
          REMEDY.
          <br />
          IN NO EVENT SHALL OBJECTIVE ZERO’S TOTAL LIABILITY TO YOU FOR ALL
          DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
          (INCLUDING NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT THAT YOU HAVE
          ACTUALLY PAID FOR THE APP IN THE PAST TWELVE MONTHS, OR ONE HUNDRED
          DOLLARS ($100.00), WHICHEVER IS GREATER.
          <br />
          THE OBJECTIVE ZERO MOBILE APPLICATION, INCLUDING THE INFORMATION
          CONTAINED WITHIN OR THROUGH THE APP, EITHER BY THE OBJECTIVE ZERO
          FOUNDATION OR ANY OF ITS VOLUNTEERS, TO INCLUDE AMBASSADORS OR OTHER
          USERS, IS NOT INTENDED TO REPLACE, SUPPLANT, OR SUPPORT ANY MEDICAL
          ADVICE, TREATMENT, OR RELATIONSHIP THAT EXISTS NOW, IN THE FUTURE, OR
          PREVIOUSLY BETWEEN A PATIENT/APP USER (“USER”) AND HIS/HER TREATING
          PHYSICIAN(S), PSYCHOLOGIST(S), THERAPIST(S), PHYSICIAN ASSISTANT(S),
          AND/OR NURSE PRACTITIONERS(S) (JOINTLY “TREATING PROVIDERS”).
          INFORMATION AND OPINIONS, WHETHER WRITTEN, SPOKEN, OR INFERRED THROUGH
          THE APP, ARE THOSE OF THE AUTHORS OR ORIGINATORS AND ARE NOT INTENDED
          TO, NOR SHOULD THEY BE CONSTRUED TO, DIAGNOSE, TREAT, OR OFFER ANY
          GUIDANCE ON ANY CONDITION, NOR SHOULD ANY SUCH INFORMATION BE
          CONSTRUED AS MEDICAL ADVICE.
          <br />
          THE USER’S TREATING PROVIDER(S) SHOULD EXAMINE THE INFORMATION AND
          SERVICES UTILIZED THROUGH THE APP IN LIGHT OF THE USER’S INDIVIDUAL
          SITUATION AS EVALUATED BY SUCH TREATING PROVIDER(S). OBJECTIVE ZERO IS
          NOT RESPONSIBLE FOR ADVERSE CONSEQUENCES USERS MAY EXPERIENCE BY
          UTILIZING THE APP OR ANY INFORMATION AND SERVICES PROVIDED THROUGH THE
          APP. THE INFORMATION AND SERVICES PROVIDED THROUGH THE APP ARE
          INTENDED TO PREVENT VETERAN SUICIDE AND OBJECTIVE ZERO WILL AT ALL
          TIMES MAKE GOOD FAITH EFFORTS TO ACHIEVE BENEFICIAL RESULTS FOR USERS
          IN THE PROVISION OF INFORMATION AND/OR SERVICES. HOWEVER, OBJECTIVE
          ZERO DOES NOT GUARANTEE SUCCESS AND IS NOT RESPONSIBLE FOR POOR
          OUTCOMES/PERCEIVED POOR OUTCOMES. NOR DOES OBJECTIVE ZERO GUARANTEE
          ANY CURE FOR AN INDIVIDUAL USER’S CONDITION/SET OF CIRCUMSTANCES.
          <br />
          OBJECTIVE ZERO DOES NOT SUGGEST THAT THE SERVICES PROVIDED THROUGH THE
          APP ARE A REPLACEMENT OR SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE,
          DIAGNOSIS, OR TREATMENT/THERAPY. NEVER IGNORE PROFESSIONAL MEDICAL
          ADVICE IN SEEKING TREATMENT BECAUSE OF SOMETHING YOU HAVE READ ON THE
          APP, OR A SERVICE YOU HAVE UTILIZED THROUGH THE APP. OBJECTIVE ZERO
          CONSIDERS THE USER TO HAVE READ AND AGREED TO THIS DISCLAIMER WHEN
          UTILIZING THE APP AND THE INFORMATION AND SERVICES PROVIDED THROUGH
          THE APP.
          <br />
          AMBASSADORS, WHETHER THERAPISTS AND OTHER HEALTH/MENTAL HEALTH CARE
          PROFESSIONAL (JOINTLY “HEALTHCARE PROFESSIONALS”), ARE NOT EMPLOYEES
          OR REPRESENTATIVES OF OBJECTIVE ZERO, AND OBJECTIVE ZERO SHALL NOT BE
          RESPONSIBLE FOR THE QUALITY OF INFORMATION OR THE AUTHENTICATION OF
          THE APP OR DETAILS GIVEN BY SUCH HEALTH CARE PROFESSIONALS AND/OR
          AMBASSADORS.
          <br />
          IT IS THE SOLE RESPONSIBILITY OF EACH HEALTH CARE PROFESSIONAL AND
          AMBASSADOR PROVIDING SERVICES ON THE APP TO ENSURE THAT HIS/HER
          INFORMATION IS CURRENT AND ACCURATE. THIS INFORMATION MAY INCLUDE, BUT
          IS NOT LIMITED TO, NAME, ADDRESS, PHONE NUMBER(S), AND EMAIL
          ADDRESS(ES).
          <br />
          OBJECTIVE ZERO ASSUMES NO RESPONSIBILITY FOR AUTHENTICATING OR
          VERIFYING THE PROFESSIONAL LICENSES OF ITS USERS, IN THE CASE OF
          HEALTHCARE PROVIDERS AND OTHER SUCH PROFESSIONALS, AND THUS IT IS THE
          RESPONSIBILITY OF SUCH HEALTHCARE PROVIDERS AND PROFESSIONALS TO
          UNDERSTAND AND COMPLY WITH THEIR RESPECTIVE STATE(S)’S LICENSURE
          REQUIREMENTS AND RESTRICTIONS.
          <br />
          THE APP IS NOT A TOOL FOR VERIFYING THE CREDENTIALS OR QUALIFICATIONS
          OF ANY HEALTH CARE PROFESSIONAL OR AMBASSADOR PROVIDING SERVICES ON
          THE APP. INCLUSION ON THE APP IS NOT AN ENDORSEMENT BY OBJECTIVE ZERO
          OF ANY HEALTH CARE PROFESSIONAL OR AMBASSADOR, NOR DOES THE OMISSION
          OF SUCH ENDORSEMENT CONSTITUTE DISAPPROVAL OF SUCH HEALTH CARE
          PROFESSIONAL OR AMBASSADOR.
          <br />
          IF YOU HAVE ANY QUESTIONS OR CONCERNS REGARDING A THERAPIST&apos;S
          CONTACT DETAILS AND/OR LICENSURE INFORMATION, PLEASE CONTACT THE
          PERSON IN QUESTION FOR VERIFICATION.
          <br />
          IT IS THE RESPONSIBILITY OF EACH HEALTH CARE PROFESSIONAL PROVIDING
          SERVICES THROUGH THE APP TO IMMEDIATELY INFORM OBJECTIVE ZERO IN
          WRITING OF ANY DISCIPLINARY ACTION OR RESTRICTIONS ON THAT
          PROFESSIONAL’S LICENSURE BY THE STATE AGENCY(IES) OVERSEEING THE
          LICENSURE OF SAID HEALTH CARE PROFESSIONAL AND TO OTHERWISE UPDATE
          OBJECTIVE ZERO IN WRITING ON THE STATUS OF THE INFORMATION SET FORTH
          IN THE PREVIOUS PARAGRAPHS.YOU ASSUME FULL RESPONSIBILITY FOR YOUR
          COMMUNICATIONS WITH ANY AMBASSADOR OR USER OR ANYONE ELSE ON THE APP
          AND IN NO EVENT SHALL OBJECTIVE ZERO BE LIABLE TO YOU OR TO ANY OTHER
          PERSON FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON
          INFORMATION PROVIDED THROUGH THE APP.
          <br />
          OBJECTIVE ZERO HAS A ZERO TOLERANCE POLICY FOR THE HARASSMENT,
          THREATS, VIOLENCE, OR OTHER MISTREATMENT TOWARD FELLOW USERS AND
          AMBASSADORS. USERS MUST CONDUCT THEMSELVES IN A RESPONSIBLE AND
          RESPECTFUL MANNER. ANY VIOLATION OF THIS POLICY WILL LEAD TO THE
          SUSPENSION AND/OR CANCELLATION OF A USER OR AMBASSADOR ACCOUNT.
          <br />
          BY USING THIS APP YOU ACCEPT THE TERMS AND CONDITIONS OF THIS
          DISCLAIMER. YOU AGREE THAT ANY USE YOU MAKE OF A HEALTHCARE
          PROFESSIONAL’S OR AMBASSADOR’S SERVICES IS AT YOUR OWN RISK AND THAT
          Objective Zero IS NOT RESPONSIBLE FOR ANY DAMAGES OR LOSSES RESULTING
          FROM YOUR RELIANCE ON SUCH SERVICES.
          <br />
          The protections and limits of liability provided for in this section
          also apply to Objective Zero Ambassadors (see Section 18(b)(i)).
          <br />
          <br />
          <span className={titleClasses}>14. Indemnification</span>
          <br />
          You agree to hold harmless and indemnify Objective Zero, and its
          subsidiaries, affiliates, officers, agents, employees, advertisers,
          licensors, suppliers or partners (collectively “Objective Zero and
          Partners”) from and against any third party claim arising from or in
          any way related to (a) your breach of the Terms, (b) your use of the
          App, (c) your violation of applicable laws, rules or regulations in
          connection with the App, or (d) your User Content, including any
          liability or expense arising from all claims, losses, damages (actual
          and consequential), suits, judgments, litigation costs and attorneys’
          fees, of every kind and nature.
          <br />
          <br />
          <span className={titleClasses}>15. Copyright Policy</span>
          <br />
          We respect the intellectual property rights of others and expect our
          users to do the same. In accordance with the Digital Millennium
          Copyright Act, Title 17, United States Code, Section 512(c)(2) (the
          “DMCA”), we will respond expeditiously to claims of copyright
          infringement committed using the App if such claims are reported to
          our Designated Copyright Agent identified in the sample notice below:
          <br />
          DMCA Notice of Alleged Infringement (“Notice”)
          <br />
          <strong>a.</strong> Identify the copyrighted work that you claim has
          been infringed, or if multiple copyrighted works are covered by this
          Notice, you may provide a representative list of the copyrighted works
          that you claim have been infringed.
          <br />
          <strong>b.</strong> Identify the material or link you claim is
          infringing (or the subject of infringing activity) and to which access
          is to be disabled, including at a minimum, if applicable, the URL of
          the link or the exact location where such material may be found.
          <br />
          <strong>c.</strong> Provide your company affiliation (if applicable),
          mailing address, telephone number, and, if available, email address.
          <br />
          <strong>d.</strong> Include both of the following statements in the
          body of the Notice:
          <br />
          <strong>i.</strong> – “I hereby state that I have a good faith belief
          that the disputed use of the copyrighted material is not authorized by
          the copyright owner, its agent, or the law (e.g., as a fair use).”
          <br />
          <strong>ii.</strong> – “I hereby state that the information in this
          Notice is accurate and, under penalty of perjury, that I am the owner,
          or authorized to act on behalf of, the owner, of the copyright or of
          an exclusive right under the copyright that is allegedly infringed.”
          <br />
          <strong>e.</strong> Provide your full legal name and your electronic
          or physical signature. Deliver this Notice, with all items completed,
          to our Designated Copyright Agent:
          <br />
          Copyright Agent,
          <br />
          Objective Zero
          <br />
          2417 Sarbonne Drive
          <br />
          Oceanside, CA 92054
          <br />
          contactus@objectivezero.org
          <br />
          <br />
          <span className={titleClasses}>16. Third-Party Content</span>
          <br />
          The App may include hyperlinks to other web sites, as well as content
          or resources or email content provided by companies or persons other
          than Objective Zero (“Third Party Content”). Objective Zero has no
          control over such Third Party Content.
          <br />
          You acknowledge and agree that Objective Zero is not responsible for
          the availability of any Third Party Content, and does not endorse any
          advertising, products, or other materials on or made available through
          such Third Party Content.
          <br />
          You acknowledge and agree that Objective Zero is not liable for any
          loss or damage which may be incurred by you or other users as a result
          of your use of or reliance upon Third Party Content, or as a result of
          any reliance placed by you on the completeness, accuracy or existence
          of any advertising, products or other materials on, or made available
          through, such Third Party Content.
          <br />
          <br />
          <span className={titleClasses}>17. Third Party Software</span>
          <br />
          The App incorporates certain third party software (“Third Party
          Software”), which is licensed subject to the terms and conditions of
          the third party licensing such Third Party Software. Nothing in these
          Terms limits your rights under, or grants you rights that supersede,
          the terms and conditions of any applicable license for such Third
          Party Software.
          <br />
          <br />
          <span className={titleClasses}>
            18. App User and Ambassador Requirements and Responsibilities
          </span>
          <br />
          For the purposes of this section, “User” refers to Veterans, current
          Active, Reserve, and National Guard military service members, their
          dependents, and military caregivers who use the App’s resources and
          Ambassador network. “Ambassador” refers to individuals who use the App
          to provide peer support to Users through voice, video, and text
          communications.
          <br />
          It is your sole responsibility as a User or Ambassador to ensure that
          the contact and licensure information (if applicable) in your User
          profile is current and accurate. This information includes, but is not
          limited to, your name, address, phone number(s), email addresses,
          states and types of licensure (as applicable). Changes to your contact
          information can be made in the profile section of our App.
          <br />
          <strong>a.</strong> Users
          <br />
          Users must be at least 18 years old to utilize the app and any
          services provided through the App. Users are not allowed to use any of
          our services when under the influence of alcohol or any other drugs.
          Users must be Veterans, service-members, military dependents, or
          military caregivers. As such, Users may be asked to provide
          documentation to verify their military, Veteran, dependent, or
          caregiver status.
          <br />
          Users must not have not been charged with or convicted of a felony;
          have not been charged with or convicted of a crime involving rape,
          sexual assault, statutory rape, or any other sexually-related crime;
          have not been charged with or convicted of a crime involving a
          firearm; have not been charged with or convicted of a crime involving
          domestic abuse; and have not been charged with or convicted of any
          predatory behavior against minors, whether in person, online, or any
          digital medium.
          <br />
          <strong>b.</strong> Ambassadors
          <br />
          Ambassadors must be at least 18 years old to utilize the app and any
          services provided through the app. Ambassadors are not allowed to use
          any of our services when under the influence of alcohol or any other
          drugs.
          <br />
          Ambassadors may be civilians, clinicians, doctors, veterans, current
          service members, or any other individual who is 18 years of age and
          meets the criteria in this section and throughout the Terms.
          Ambassadors claiming military service or affiliation, whether as a
          service member, veteran, or dependent, may be asked to verify such
          service by providing documentation to Objective Zero or a third-party
          affiliate who will verify service or affiliation.
          <br />
          Ambassadors must not have not been charged with or convicted of a
          felony; have not been charged with or convicted of a crime involving
          rape, sexual assault, statutory rape, or any other sexually-related
          crime; have not been charged with or convicted of a crime involving a
          firearm; have not been charged with or convicted of a crime involving
          domestic abuse; and have not been charged with or convicted of any
          predatory behavior against minors, whether in person, online, or any
          digital medium.
          <br />
          Ambassadors must complete the Objective Zero Ambassador training
          program (www.objectivezero.org/become-an-Ambassador) and agree to
          follow the guidance outlined in the training. Ambassadors agree not to
          provide malicious guidance that could harm, injure, or otherwise hurt
          a User, whether intentionally or unintentionally.
          <br />
          <strong>i.</strong> Ambassador liability
          <br />
          Objective Zero Ambassadors act as good Samaritans to provide peer
          support to Veterans and military service members in need of moral
          support. This relationship is not, nor should it be construed as, a
          client-patient or patient-provider relationship or any other protected
          relationship and thus is not protected by the same rules, laws, and
          provisions governing such relationships.
          <br />
          As such, Ambassadors are not responsible for the actions of Users in
          response to interactions with Ambassadors on the Objective Zero mobile
          App. Ambassadors are also not responsible for any actions taken by the
          User before, during, or after communication with the User. The User,
          their trust(s), and/or descendants agree to hold harmless the
          Objective Zero Ambassador and the Objective Zero Foundation for any
          harm occurring during or after communication between the User and
          Ambassador.
          <br />
          Objective Zero Ambassadors’ liability is also protected in accordance
          with the provisions outlined in Section 13 above.
          <br />
          <br />
          <span className={titleClasses}>
            19. Therapist Terms and Conditions
          </span>
          <br />
          As a therapist, it is your sole responsibility to ensure that you give
          therapy only to people you are allowed to give therapy to, depending
          on local, state or national licensing and/or legal restrictions. It is
          your responsibility to know all such restrictions applicable to you.
          <br />
          As a therapist, you may be a Covered Entity for purposes of compliance
          with the Health Insurance Portability and Accountability Act of 1996,
          Public Law 104191 (&quot;HIPAA&quot;), and you may also be subject to
          similar state laws. Accordingly, information you collect could be
          protected under HIPAA and state privacy laws. Objective Zero is a
          technology provider, not a Covered Entity.
          <br />
          You understand compliance with HIPAA and state privacy laws is your
          responsibility. While use of the App is designed to provide security
          of information as required by HIPAA, Objective Zero makes no warranty
          or representation that use or its services will be adequate to fully
          comply with HIPAA or state privacy laws.
          <br />
          It is your sole responsibility as an Ambassador to ensure that you
          have insurance coverage in case there are legal claims/litigation. For
          more information about your rights, roles, and responsibilities under
          HIPAA while using the app, see Section 7 of the Objective Zero Privacy
          Policy.
          <br />
          <br />
          <span className={titleClasses}>20. Feedback</span>
          <br />
          You may choose to or we may invite you to submit comments or ideas
          about the App, including without limitation about how to improve the
          App or our products. By submitting any feedback, you agree that your
          disclosure is gratuitous, unsolicited, and without restriction and
          will not place Objective Zero under any fiduciary or other obligation,
          and that we are free to use such feedback without any additional
          compensation to you, and/or to disclose such feedback on a
          non-confidential basis or otherwise to anyone.
          <br />
          <br />
          <span className={titleClasses}>21. Miscellaniuos</span>
          <br />
          These Terms, together with our Privacy Policy (and Medical
          Professionals Agreement, if applicable), constitute the entire
          agreement between the parties relating to the App and all related
          activities. These Terms shall not be modified except in writing,
          signed by both parties or by a new posting of these Terms issued by
          us. If any part of these Terms is held to be unlawful, void, or
          unenforceable, that part shall be deemed severed and shall not affect
          the validity and enforceability of the remaining provisions. The
          failure of Objective Zero to exercise or enforce any right or
          provision under these Terms shall not constitute a waiver of such
          right or provision. Any waiver of any right or provision by Objective
          Zero must be in writing and shall only apply to the specific instance
          identified in such writing. You may not assign these Terms, or any
          rights or licenses granted hereunder, whether voluntarily, by
          operation of law, or otherwise without our prior written consent.
          These Terms and any action related thereto will be governed by the
          laws of the State of Delaware without regard to its conflict of laws
          provisions. The exclusive jurisdiction and venue of any action with
          respect to the subject matter of these Terms will be the state and
          federal courts located in Wilmington, Delaware, and each of the
          parties hereto waives any objection to jurisdiction and venue in such
          courts.
          <br />
          <br />
          <span className={titleClasses}>22. Contact Us</span>
          <br />
          If you have any questions about these Terms or if you wish to make any
          complaint or claim with respect to the App, please contact us at:
          contactus@objectivezero.org
          <br />
          When submitting a complaint, please provide a brief description of the
          nature of your complaint and the specific services to which your
          complaint relates, as well as your first and last name and username.
          <br /> <br />
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
