import React, { useContext } from "react";
import moment from "moment";
import { MessagingContext } from "../pages/Messages";
import PropTypes from "prop-types";
import messagesInactive from "../Assets/images/messages_inactive.png";
import messagesAlert from "../Assets/images/messages_alert.png";
import classnames from "classnames";

function ChannelCard({ channel }) {
  const name = channel.chatPartnerName || channel.chatPartnerUsername;
  const timestamp = new Date(channel.lastMessageEpochMilliseconds);

  const { activeChannel, setActiveChannel } = useContext(MessagingContext);
  const active = channel === activeChannel;

  const cardClassnames = classnames("text-xs display-none-alt", {
    "text-white": active,
    "text-oz-gray": !active
  });

  return (
    <div
      className={`mt-2 ml-2 cursor-pointer card-width`}
      onClick={() => setActiveChannel(channel)}
    >
      <div
        className={`small-text flex p-6 items-center shadow rounded-sm ${
          active ? "bg-oz-blue text-white" : "text-black"
        }`}
      >
        <span className="flex-1 flex">
          <div className="display-none-alt icon-container mr-2 w-1/12">
            <img
              src={
                channel.unreadMessages > 0 ? messagesAlert : messagesInactive
              }
              className="w-6 mr-2 mb-1"
            />
          </div>
          {name}
        </span>

        <span className={cardClassnames}>
          {moment(timestamp).format("h:mma")}
        </span>
      </div>
    </div>
  );
}
ChannelCard.propTypes = {
  channel: PropTypes.object
};

export default ChannelCard;
