import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const AppliedFilter = ({ category, value, removeFilter }) => {
  return (
    <>
      <div className="rounded bg-blue-600 flex w-auto h-5 pl-1 pr-1 justify-evenly mr-1 mt-1">
        <div className="title text-white text-xs mr-1 h-full align-middle font-light">
          {value}
        </div>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => removeFilter([category, value])}
          size="sm"
          color="white"
          className="pt-1"
        />
      </div>
    </>
  );
};
AppliedFilter.propTypes = {
  category: PropTypes.string,
  value: PropTypes.string,
  removeFilter: PropTypes.func
};
export default AppliedFilter;
