import React, { useContext, useState, useEffect } from "react";
import AmbassadorPicture from "./AmbassadorPicture";
import Loading from "./Loading";
import useAPI from "../hooks/useAPI";
import { MessagingContext } from "../pages/Messages";
import customToast from "./customToast";
import PropTypes from "prop-types";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import active from "../Assets/images/favorites_icon_active.png";
import inactive from "../Assets/images/favorite_add_icon.png";
function Tidbit({ text, value, border = true }) {
  return (
    <div className={`w-1/2 mx-auto ${border ? "border-oz-gray border-b" : ""}`}>
      <div className="my-2 text-oz-gray font-bold mb-2">{text}</div>
      <div className="my-2 flex-1 text-oz-blue">{value || "N/A"}</div>
    </div>
  );
}

function MessagePartner({ user }) {
  const api = useAPI(1);
  const { setFavorites, favorites } = useContext(MessagingContext);
  const [isFavorite, setIsFavorite] = useState(false);
  const addFavorite = user => {
    if (checkFavorite(user.id)) {
      api
        .delete(`favorite/${user.id}`)
        .then(() => {
          setIsFavorite(!isFavorite);
          customToast.info(
            `Removed ${user.name || user.username} from favorites`,
            { autoClose: 3000 }
          );
          setFavorites(prevFavorites =>
            prevFavorites.filter(fav => fav.id !== user.id)
          );
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      api
        .post(`favorite/${user.id}`)
        .then(() => {
          setIsFavorite(!isFavorite);
          customToast.info(`Added ${user.name || user.username} to favorites`, {
            autoClose: 3000
          });
          setFavorites(prevFavorites => [...prevFavorites, user]);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (user) {
      const arrayOfFavorite = favorites.filter(fav => fav.id === user.id);
      if (arrayOfFavorite.length < 1) {
        setIsFavorite(false);
      } else {
        setIsFavorite(true);
      }
    }
  }, [user, api, setIsFavorite, favorites]);

  const checkFavorite = id => {
    const arrayOfFavorite = favorites.filter(fav => fav.id === id);
    if (arrayOfFavorite.length < 1) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      <h2 className="text-oz-blue font-bold mb-8">Ambassador Details</h2>
      {user === undefined ? (
        <Loading />
      ) : (
        <div className="text-center">
          <div className="flex justify-center">
            <div className="relative pb-4 w-6/12">
              <AmbassadorPicture
                url={user?.profile_pic || ProfilePictureMissing}
                size={20}
              />

              <img
                src={isFavorite ? active : inactive}
                onClick={() => {
                  addFavorite(user);
                }}
                className="cursor-pointer absolute  bottom-0 right-0 h-16 w-16 mr-4 p-2 mt-4 no-margin-alt"
                alt="alt"
              />
            </div>
          </div>

          <Tidbit text="Ambassador" value={user.name || user.username} />
          <Tidbit text="Age" value={user.age} />
          <Tidbit text="Service" value={user.service} />
          <Tidbit text="Component" value={user.component} />
          <Tidbit text="Veteran" value={user.veteran} />
          <Tidbit text="MOS" value={user.mos} border={false} />
        </div>
      )}
    </div>
  );
}
Tidbit.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  border: PropTypes.bool
};

MessagePartner.propTypes = {
  user: PropTypes.object
};

export default MessagePartner;
