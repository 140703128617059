import React, { useState } from "react";
import AmbassadorVideo from "../components/AmbassadorVideo";
import AmbassadorQuestionnaire from "../components/AmbassadorQuestionnaire";
import WelcomeNewAmbassador from "../components/WelcomeNewAmbassador";
function AmbassadorTest() {
  const [part, setPart] = useState(1);
  const renderCorrectComponent = () => {
    if (part === 1) {
      return <AmbassadorVideo setPart={setPart} />;
    }
    if (part === 2) {
      return <AmbassadorQuestionnaire setPart={setPart} />;
    }
    if (part === 3) {
      return <WelcomeNewAmbassador />;
    }
  };

  const renderCorrectBody = () => {
    if (part !== 3) {
      return (
        <>
          <h1 className="text-blue-600 font-bold text-xl">
            Welcome New Ambassador
          </h1>
          <div className="border border-blue-600 mb-4"></div>
          <div className="w-full flex justify-between bg-gray-200 p-2">
            <div className="text-gray-700 p-4">
              Thank you for applying to become an Ambassador. The process is
              very short and requires you to just watch this video and complete
              a short quiz. After that you will be a full fledged ambassador.
              Some supplemental information will be sent to your email, but you
              will be able to help anyone after this. Thanks again for all your
              help.
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div>
      {renderCorrectBody()}
      <div className="flex flex-col mb-4">{renderCorrectComponent()}</div>
    </div>
  );
}

export default AmbassadorTest;
