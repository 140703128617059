import React from "react";

function AmbassadorPicture({ url, size = 12 }) {
  const imgClass = `inline-block rounded-full border-oz-blue border-2 h-${size} w-${size}`;

  if (url) {
    return <img alt="Ambassador" src={url} className={imgClass} />;
  } else {
    return <span className={imgClass}></span>;
  }
}

export default AmbassadorPicture;
