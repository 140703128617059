import React from "react";

function AboutObjectiveZero() {
  return (
    <div>
      <h1 className="text-blue-600 font-bold text-xl">About Objective Zero</h1>
      <div className="border border-blue-600 mb-2"></div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Mission</div>
        <div className="entry pl-2">
          The Objective Zero Foundation enhances social connectedness and access
          to suicide prevention resources to prevent veteran suicide
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Vision</div>
        <div className="entry pl-2">
          Succeeding where others have failed, the Objective Zero Foundation is
          a mission-driven tech startup that leads the pack in the fight against
          veteran suicide, connecting every veteran in America to suicide
          prevention support and resources
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Selfless Service</div>
        <div className="entry pl-2">
          Giving all for others; asking for nothing in return.
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Loyalty</div>
        <div className="entry pl-2">
          Staying true to our mission, veterans, and each other.
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Integrity</div>
        <div className="entry pl-2">Doing what we say; saying what we mean</div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Community</div>
        <div className="entry pl-2">
          Building strong, meaningful bonds and social connectedness
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Transparency</div>
        <div className="entry pl-2">
          Being forthright with our intentions, actions and dealings
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Innovation</div>
        <div className="entry pl-2">
          Thinking differently about problems and potential solutions; boldly
          taking action to solve them.
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">Confidentiality</div>
        <div className="entry pl-2">
          Holding in confidence the identity of community members
        </div>
      </div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">
          The Objective Zero Story
        </div>
        <div className="entry pl-2 mb-6">
          In the fall of 2014, Justin Miller was falling apart. Justin was
          struggling with his transition out of the military. He was unemployed
          and was abusing alcohol to cope with survivor’s guilt. Within months
          after leaving the military, at rock bottom, Justin was contemplating
          suicide. He reached out to a friend, US Army Major Chris Mercado, to
          talk. Though not a clinical therapist or a trained suicide
          intervention specialist, Chris did what any comrade would do: he
          listened. The simple act of having someone there to listen in his
          moment of crisis was cathartic for Justin. Chris was convinced that if
          Justin reflected on his experience, and told his story, he could
          inspire other veterans who were similarly struggling. Chris and Justin
          worked together to capture Justin’s experience in words. The effort
          restored Justin’s sense of purpose and mission and together they
          published Justin’s story with an online publication. In the spring of
          2016, Chris Mercado assembled a group of classmates at Georgetown
          University to brainstorm ideas for addressing the Veteran suicide
          epidemic head-on, and hence Objective Zero was born.
        </div>
      </div>
    </div>
  );
}

export default AboutObjectiveZero;
