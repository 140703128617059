import React from "react";

function WelcomeNewAmbassador() {
  return (
    <div className="w-full  ">
      <div className="customTextHeight text-oz-blue font-bold mt-16 flex flex-col items-center">
        Welcome to the team!
        <div className="welcome-divider mb-1 w-6/12"></div>
      </div>
    </div>
  );
}

export default WelcomeNewAmbassador;
