import React, { useState, useEffect, useCallback, useContext } from "react";
import classnames from "classnames";
import StaticModalBase from "./StaticModalBase";
import moment from "moment";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneAltSlash,
  faMicrophone,
  faPhoneAlt,
  faVideo,
  faVideoSlash,
  faExpand
} from "@fortawesome/free-solid-svg-icons";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import minimizeIcon from "../Assets/images/minimizeIcon.png";
import outgoing from "../Assets/sounds/outgoing.wav";
import Participant from "./Participant";
import { connect } from "twilio-video";
import "./CallModal.css";
import useCurrentUser from "../hooks/useCurrentUser";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CallContext } from "./CallComponents";

let audio = new Audio(outgoing);

const CallModal = () => {
  const api1 = useAPI(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const {
    showCallModal,
    setShowCallModal,
    rws,
    setSenderPayload,
    setIsMinimizedCallModal,
    isMinimizedCallModal,
    setUnavailableAmbassadorModal,
    isFirstAvailableCall,
    setIsFirstAvailableCall
  } = useAuth();
  const {
    payload,
    isReceiver,
    userBeingCalled,
    setUserBeingCalled,
    stopwatch,
    setTime,
    remoteVideoMute,
    setRemoteVideoMute
  } = useContext(CallContext);
  const [localMute, setLocalMute] = useState(false);
  const [remoteMute, setRemoteMute] = useState(false);
  const [localVideoMute, setLocalVideoMute] = useState(false);
  const [callType, setCallType] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [receiverCallType, setReceiverCallType] = useState();
  const [remoteParticipant, setRemoteParticipant] = useState();
  const [isTop, setIsTop] = useState(true);
  const user = useCurrentUser();
  const [callRoom, setCallRoom] = useState();

  const handle1 = useFullScreenHandle();

  const muteClasses = classnames(
    "rounded-full shadow add-pointer-hover  h-10 w-10 flex justify-center p-2 pt-3",
    {
      "bg-gray-500": localMute,
      "bg-white": !localMute
    }
  );
  const videoClasses = classnames(
    "rounded-full shadow add-pointer-hover h-10 w-10 flex justify-center p-2 pt-3",
    {
      "bg-gray-500": localVideoMute,
      "bg-white": !localVideoMute
    }
  );
  const expandClasses = classnames(
    "rounded-full shadow add-pointer-hover h-10 w-10 flex justify-center p-2 pt-3 bg-white"
  );
  const localHolderClasses = classnames("", {
    relative: isFullScreen,
    " p-2 flex flex-col items-center justify-evenly ":
      callType === "video" && !isFullScreen,
    "lCH p-2 flex items-center justify-between pr-4":
      callType === "voice" && !isFullScreen
  });

  const disconnectClasses = classnames(
    "bg-red-500 rounded-full shadow add-pointer-hover flex justify-center h-10 w-10 p-2 pt-3"
  );
  const disconnectPhoneCall = useCallback(() => {
    if (callRoom) {
      callRoom.disconnect();
    }
    api1
      .post("call/cancel", { state: "cancel" })
      .then(r => console.log(r))
      .catch(err => {
        console.log(err);
      });
    setTime("00:00:00");
    setShowCallModal(false);
    setCallType();
    setIsTop(true);
    setCallRoom();
    setIsFirstAvailableCall(false);
    setSenderPayload();
    setRemoteParticipant();
    setUserBeingCalled({
      id: "",
      name: "",
      username: "",
      profile_pic: "",
      service: "",
      component: ""
    });
  }, [
    callRoom,
    api1,
    setShowCallModal,
    setSenderPayload,
    setRemoteParticipant,
    setUserBeingCalled,
    setCallRoom,
    setIsFirstAvailableCall,
    setIsTop,
    setTime
  ]);

  useEffect(() => {
    if (payload && !callRoom) {
      setCallType(payload.type);
      if (payload.type === "voice" && !isReceiver) {
        setIsTop(false);
      }
      setReceiverCallType(payload.type);
      connect(
        payload.senderAccessToken || payload.twilio_receivertoken,
        {}
      ).then(
        room => {
          setCallRoom(room);
          let timeout;
          if (!isReceiver && !isFirstAvailableCall) {
            timeout = setTimeout(() => {
              disconnectPhoneCall();
              room.disconnect();
              setUnavailableAmbassadorModal(true);
            }, 60000);
          }
          room.on("participantConnected", participant => {
            clearTimeout(timeout);
            setIsTop(false);
            setRemoteParticipant(participant);
            audio.pause();
            api1
              .get(`user/${participant.identity}`)
              .then(r => {
                const data = r.data;
                const dataObj = {
                  id: participant.identity,
                  name: data.name || data.username || "user",
                  username: data.username,
                  profile_pic: data.profile_pic,
                  service: data.service,
                  component: data.component,
                  mos: data.mos,
                  veteran: data.veteran,
                  age: moment().diff(data.date_of_birth, "years")
                };
                setUserBeingCalled(dataObj);
              })
              .catch(err => {
                console.log(err);
              });
          });

          room.on("participantDisconnected", () => {
            disconnectPhoneCall();
          });
          if (isReceiver) {
            setIsTop(false);
            for (const entry of room.participants.entries()) {
              setRemoteParticipant(entry[1]);
              api1
                .get(`user/${entry[1].identity}`)
                .then(r => {
                  const data = r.data;
                  const dataObj = {
                    id: entry[1].identity,
                    name: data.name || "user",
                    username: data.username,
                    profile_pic: data.profile_pic,
                    service: data.service,
                    component: data.component
                  };
                  setUserBeingCalled(dataObj);
                })
                .catch(err => {
                  console.log(err);
                });
            }
          }
          room.on("disconnected", () => {
            if (timeout) {
              clearTimeout(timeout);
            }
          });
        },
        err => {
          console.log("error connecting to room, ", err);
        }
      );
    }
  }, [
    payload,
    isReceiver,
    setSenderPayload,
    setShowCallModal,
    api1,
    setUnavailableAmbassadorModal,
    disconnectPhoneCall,
    callRoom,
    isFirstAvailableCall,
    setUserBeingCalled
  ]);

  useEffect(() => {
    if (callType === "voice" && callRoom) {
      callRoom.localParticipant.videoTracks.forEach(function (tracks) {
        tracks.track.stop();
      });
    }
  }, [callType, callRoom]);

  useEffect(() => {
    if (showCallModal && !userBeingCalled.id) {
      audio.volume = 0.2;
      audio.play();
      let count = 0;
      let promise = audio.play();
      if (promise !== undefined) {
        promise.catch(err => {
          console.log(err);
        });
      }
      audio.addEventListener("ended", () => {
        if (count >= 1) {
          audio.pause();
        } else {
          audio.play();
          count++;
        }
      });
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [payload, showCallModal, userBeingCalled]);

  useEffect(() => {
    if (rws) {
      rws.addEventListener("message", message => {
        if (message.data !== "ping") {
          const obj = JSON.parse(message.data);
          if (obj.method === "change_remote_video") {
            setRemoteVideoMute(!remoteVideoMute);
          }
          if (obj.method === "change_remote_audio") {
            setRemoteMute(!remoteMute);
          }
          if (obj.method === "video_mute_complete") {
            setIsDisabled(false);
          }
        }
      });
    }
  }, [
    payload,
    rws,
    setRemoteMute,
    setRemoteVideoMute,
    remoteMute,
    remoteVideoMute
  ]);

  const showOrHideVideo = () => {
    if (!isDisabled && remoteParticipant) {
      rws.send(
        JSON.stringify({
          method: "change_remote_video",
          user_id: remoteParticipant.identity
        })
      );
      callRoom.localParticipant.videoTracks.forEach(function (tracks) {
        if (localVideoMute) {
          tracks.track.enable();
        } else {
          tracks.track.disable();
        }
        setLocalVideoMute(!localVideoMute);
      });
    }
  };

  const muteAudio = () => {
    if (!isDisabled && remoteParticipant) {
      rws.send(
        JSON.stringify({
          method: "change_remote_audio",
          user_id: remoteParticipant.identity
        })
      );
      setIsDisabled(true);
      callRoom.localParticipant.audioTracks.forEach(function (tracks) {
        if (localMute) {
          tracks.track.enable();
        } else {
          tracks.track.disable();
        }
        setLocalMute(!localMute);
        setIsDisabled(false);
      });
    }
  };

  const renderCorrectTop = () => {
    if (remoteParticipant) {
      return (
        <Participant
          participant={remoteParticipant}
          type={receiverCallType}
          user={userBeingCalled}
          picture={userBeingCalled.profile_pic}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          videoMute={remoteVideoMute}
          mute={remoteMute}
          top={true}
          handle={handle1}
          showOrHideVideo={showOrHideVideo}
          rws={rws}
        />
      );
    } else {
      if (callType !== "video") {
        return (
          <div className="rounded-full shadow call-animation w-4/12 mb-10">
            <img
              src={
                userBeingCalled.profile_pic
                  ? userBeingCalled.profile_pic
                  : ProfilePictureMissing
              }
              className="rounded-full"
              alt="remote participant"
            />
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const renderCorrectBottom = () => {
    if (callRoom) {
      return (
        <Participant
          participant={callRoom.localParticipant}
          type={callType}
          user={user}
          picture={userBeingCalled.profile_pic}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          mute={localMute}
          videoMute={localVideoMute}
          top={isTop}
          handle={handle1}
          showOrHideVideo={showOrHideVideo}
        />
      );
    } else {
      if (callType === "video") {
        return (
          <div className="p-10">
            <div className="loader"></div>
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const handleEnter = () => {
    setIsFullScreen(true);
    handle1.enter();
  };
  const renderCorrectButton = () => {
    if (!payload) return "";
    if (payload.type) {
      if (payload.type === "voice") {
        return (
          <div className={muteClasses} onClick={muteAudio}>
            <FontAwesomeIcon
              icon={localMute ? faMicrophoneAltSlash : faMicrophone}
              color={localMute ? "white" : "gray"}
              size=""
            />
          </div>
        );
      } else {
        return (
          <>
            <div className={muteClasses} onClick={muteAudio}>
              <FontAwesomeIcon
                icon={localMute ? faMicrophoneAltSlash : faMicrophone}
                color={localMute ? "white" : "gray"}
                size=""
              />
            </div>
            <div className={videoClasses} onClick={showOrHideVideo}>
              <FontAwesomeIcon
                icon={!localVideoMute ? faVideo : faVideoSlash}
                color={localVideoMute ? "white" : "gray"}
                size=""
              />
            </div>
            <div className={expandClasses} onClick={handleEnter}>
              <FontAwesomeIcon icon={faExpand} color="gray" size="" />
            </div>
          </>
        );
      }
    } else {
      return "";
    }
  };
  const buttonHolderClasses = classnames(
    "flex justify-evenly self-end w-1/4 mt-2"
  );

  const renderButtonSection = () => {
    if (!isFullScreen) {
      return (
        <div className={buttonHolderClasses}>
          {renderCorrectButton()}
          <div className={disconnectClasses} onClick={disconnectPhoneCall}>
            <FontAwesomeIcon icon={faPhoneAlt} color="white" size="" />
          </div>
        </div>
      );
    } else {
      return "";
    }
  };
  const handleExit = () => {
    setIsFullScreen(false);
    handle1.exit();
  };

  const handleEscapeClick = e => {
    if (!e && isFullScreen) {
      handleExit();
    }
  };

  const renderNameSection = () => {
    if (!isFullScreen) {
      return (
        <div className="flex">
          <div className="text-white ">
            {userBeingCalled.name || "connecting..."}
          </div>
          <div className="text-white mr-2 ml-2">|</div>
          <div className="text-white">{stopwatch || "00:00:00"}</div>
        </div>
      );
    }
  };
  if (callType === "video") {
    return (
      <StaticModalBase
        show={showCallModal}
        setShow={setShowCallModal}
        isMinimizedCallModal={isMinimizedCallModal}
        isVideo={true}
      >
        <div>
          <div className="bg-oz-blue text-white p-4 flex justify-between font-bold">
            <div></div>
            {userBeingCalled.name || userBeingCalled.username
              ? `Call with ${userBeingCalled.name || userBeingCalled.username}`
              : "Call Center"}
            <div
              onClick={() => {
                setIsMinimizedCallModal(true);
                setShowCallModal(false);
              }}
              className="add-pointer-hover"
            >
              _
            </div>
          </div>
        </div>
        <FullScreen handle={handle1} onChange={e => handleEscapeClick(e)}>
          {renderCorrectTop()}
          <div className={localHolderClasses}>
            {renderCorrectBottom()}
            {renderButtonSection()}
          </div>
        </FullScreen>
      </StaticModalBase>
    );
  }
  if (callType === "voice") {
    return (
      <StaticModalBase
        show={showCallModal}
        setShow={setShowCallModal}
        isMinimizedCallModal={isMinimizedCallModal}
        isVoice={true}
      >
        <div className="bg-white flex justify-end">
          <div
            className="pt-2 pr-2 add-pointer-hover"
            onClick={() => {
              setIsMinimizedCallModal(true);
              setShowCallModal(false);
            }}
          >
            <img alt="minimize" src={minimizeIcon} className="w-4" />
          </div>
        </div>
        <div className=" pb-5 pr-10 pl-10 flex flex-col justify-between items-center bg-white">
          {renderCorrectTop()}
        </div>
        <div className={localHolderClasses}>
          {renderNameSection()}
          {renderButtonSection()}
        </div>
        {renderCorrectBottom()}
      </StaticModalBase>
    );
  } else {
    return "";
  }
};

export default CallModal;
