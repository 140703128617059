import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";
import useAuth from "./useAuth";

function useLocation() {
  const {
    setShowGeoLocationModal,
    showGeoLocationModal,
    authToken
  } = useAuth();

  // Default location to the center of the US
  const [location, setLocation] = useLocalStorage("oz-location", null);

  useEffect(() => {
    if (location || showGeoLocationModal || !authToken) return;
    setShowGeoLocationModal(true);
  }, [location, authToken, setShowGeoLocationModal]);

  return { location: location, setLocation: setLocation };
}

export default useLocation;
