import React, { useState } from "react";
import StaticModalBase from "./StaticModalBase";
import useAuth from "../hooks/useAuth";
import locationOval from "../Assets/images/locationOval.png";
import useErrModal from "../hooks/useErrModal";
import useAPI from "../hooks/useAPI";
import useLocation from "../hooks/useLocation";

const GeolocationModal = () => {
  const {
    showGeoLocationModal,
    setShowGeoLocationModal,
    setAllowedLocation
  } = useAuth();
  const [deniedPermissions, setDeniedPermissions] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const errorHandler = useErrModal();
  const api = useAPI();
  const { setLocation } = useLocation();
  const grantAccess = () => {
    if (!disabled) {
      setDisabled(true);
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          setAllowedLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          api
            .patch("user", {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
            .then(() => {
              setDisabled(false);
              setShowGeoLocationModal(false);
            })
            .catch(err => errorHandler(err));
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            setDeniedPermissions(true);
            setDisabled(false);
          }
        }
      );
    }
  };
  const skipAccess = () => {
    if (!disabled) {
      setDisabled(true);
      setLocation({ lat: 39.8283, lng: -98.5795 });
      setAllowedLocation({ lat: 39.8283, lng: -98.5795 });
      setDisabled(false);
      setShowGeoLocationModal(false);
    }
  };
  return (
    <StaticModalBase
      show={showGeoLocationModal}
      setShow={setShowGeoLocationModal}
    >
      <div className="w-full flex flex-col items-center p-6">
        <div className="w-full flex flex-col items-center">
          <img src={locationOval} alt="alt name" className="w-32" />
          <h1 className="font-bold  text-lg text-oz-blue">Location</h1>
        </div>
        <div className="text-center mb-8 mt-4">
          Objective Zero needs your location permission to display your profile
          pin to others within your community and to provide in-the-moment
          support.
        </div>
        <div className="text-center">
          OZ never shows your exact location to others and uses anonymized data
          to determine support actions. This permission can be updated in the
          Settings or logging out and logging back in.
        </div>
        <div className="flex flex-col items-center w-full mt-4">
          {!deniedPermissions && (
            <div
              className="w-8/12 bg-oz-blue text-white font-bold rounded text-center p-2 add-pointer-hover"
              onClick={grantAccess}
            >
              Grant Location Access
            </div>
          )}
          <div
            className="text-gray-500 font-bold mt-2 add-pointer-hover"
            onClick={skipAccess}
          >
            Skip location access for now
          </div>
        </div>
        {deniedPermissions && (
          <div className="text-red-500 text-sm mt-4">
            *It looks like the permissions were denied or you haven&apos;t given
            your browser access from your system preferences, click &quot;Skip
            location access for now&quot; to set your location at the center of
            the USA and try again later.
          </div>
        )}
      </div>
    </StaticModalBase>
  );
};
export default GeolocationModal;
