import React, { useState, useEffect } from "react";
import dogtags from "../Assets/images/dog_tags.png";
import component from "../Assets/images/component.png";
import useAPI from "../hooks/useAPI";
import PropTypes from "prop-types";

function CreateHandle({ setPage }) {
  const [subtext, setSubtext] = useState("");
  const [username, setUsername] = useState("");
  const api = useAPI(1);
  const changeNameValue = e => {
    setUsername(e.target.value);
  };
  const changePage = () => {
    if (!username) {
      setSubtext("*Please Enter a Handle before continuing");
    } else {
      api.patch("user", { username: username }).then(() => {
        setPage(2);
      });
    }
  };
  useEffect(() => {
    api
      .get("user")
      .then(r => {
        setUsername(r.data.username);
      })
      .catch(err => {
        console.log(err);
      });
  }, [api]);
  return (
    <>
      <div className="w-full flex justify-center">
        <img src={dogtags} />
      </div>
      <div className="header text-blue-500 font-bold text-xl flex justify-center">
        Create your Handle
      </div>
      <div>
        <p>
          Your privacy is very important to Objective Zero, please create an
          anonymous handle for your account profile
        </p>
      </div>
      <div className="flex mt-4">
        <img src={component} className="w-8 mr-4 safari-full-image" alt="alt" />
        <input
          className="border-b border-gray-300 focus:outline-none w-full"
          placeholder="Username"
          onChange={changeNameValue}
          value={username}
        />
      </div>
      <div className="text-sm text-red-500 pl-6 mt-2">
        {subtext ? subtext : ""}
      </div>
      <div className="flex justify-center p-6">
        <button
          className="bg-oz-blue w-10/12 text-white font-bold p-2"
          onClick={changePage}
        >
          Next
        </button>
      </div>
    </>
  );
}
CreateHandle.propTypes = {
  setPage: PropTypes.func
};
export default CreateHandle;
