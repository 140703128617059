import React, { useState, useEffect } from "react";
import BecomeAnAmbassador from "../components/BecomeAnAmbassador";
import ContactSupport from "../components/ContactSupport";
import FAQs from "../components/FAQs";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

function Help() {
  const [setting, setSetting] = useState("ba");
  const [isLoading, setIsLoading] = useState(true);
  const { type } = useParams();

  useEffect(() => {
    setSetting(type);
    setIsLoading(false);
  }, [type, setIsLoading, setSetting]);
  const renderCorrectInfo = () => {
    if (setting === "cu") {
      return <ContactSupport />;
    }
    if (setting === "fa") {
      return <FAQs />;
    }
    if (setting === "ba") {
      return <BecomeAnAmbassador />;
    } else {
      return <BecomeAnAmbassador />;
    }
  };
  return (
    <Loader isLoading={isLoading}>
      <div className="flex w-full justify-center">
        <div className="w-9/12 pl-6 main-div-padding-alt ">
          {renderCorrectInfo()}
        </div>
      </div>
    </Loader>
  );
}

export default Help;
