import React, { useState } from "react";
import useAPI from "../hooks/useAPI";
import customToast from "./customToast";

function ContactSupport() {
  const [email, setEmail] = useState({ subject: "", body: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const api = useAPI();

  const changeEmail = e => {
    const stateToChange = { ...email };
    stateToChange[e.target.id] = e.target.value;
    setEmail(stateToChange);
  };
  const sendEmail = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      api
        .post("contact-support", email)
        .then(r => {
          if (r.status === 200) {
            customToast.info(
              "You're message has been sent! Our team will get back to you shortly."
            );
            setIsDisabled(false);
            setShowThanks(true);
            setEmail({ subject: "", body: "" });
          }
        })
        .catch(error => {
          console.log(error);
          setIsDisabled(false);
          customToast.warn("Sorry, something went wrong, try again later");
        });
    }
  };
  if (showThanks) {
    return (
      <div className="flex flex-col items-center border-2 border-blue-600 p-16 text-center">
        <h1 className="text-blue-600 font-bold text-xl mb-4">
          Thanks for contacting us!
        </h1>
        <div>
          We appreciate you&apos;re feedback and cooperation. Be sure to check
          the email you signed up with for our response. Thanks again and have a
          nice day!
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center">
        <h1 className="text-blue-600 font-bold text-xl self-start">
          Contact Support
        </h1>
        <div className="border border-blue-600 mb-4 w-full"></div>
        <div className="w-full flex justify-between bg-gray-200 p-2 mb-4">
          <div className="text-gray-600 mr-6">
            Sorry to hear you are experiencing some issues with the Objective
            Zero app, please feel free to fill out this form and send an email
            to our support staff so we can fix it as soon as possible.
          </div>
        </div>
        <div className="flex flex-col mb-4 w-8/12 shadow shadow-lg p-4 pt-8 width-full-alt">
          <form className="flex flex-col mb-4 items-center">
            <div className="w-10/12">
              <label htmlFor="subject" className="mb-2">
                Subject:
              </label>
              <input
                type="text"
                required
                id="subject"
                name="subject"
                className="border-2 border-gray-300 w-11/12 ml-4 zero-margin-left"
                value={email.subject}
                onChange={changeEmail}
              />
            </div>
            <div className=" items-start mt-6 w-10/12">
              <label htmlFor="body" className="mb-2">
                Body:
              </label>
              <textarea
                id="body"
                name="body"
                rows="8"
                cols="50"
                value={email.body}
                className="border-2 border-gray-300 w-11/12 ml-4 zero-margin-left"
                onChange={changeEmail}
              />
            </div>
          </form>
          <div className="flex justify-end mt-4">
            <div
              className="bg-oz-blue flex justify-center hover:bg-blue-700 w-3/12 text-white add-pointer-hover p-2 font-bold"
              onClick={sendEmail}
            >
              Send
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactSupport;
