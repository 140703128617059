import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { AmbassadorContext } from "../pages/Connect";
import PropTypes from "prop-types";

const FilterCategorySection = ({ label, values }) => {
  const { setSubFilters } = useContext(AmbassadorContext);
  return (
    <>
      <div className="filter-section flex flex-col bg-white w-full">
        <h5 className="text-gray-500 text-sm font-bold">{label}</h5>
        <div className="filter-section-cards pl-2">
          {Object.keys(values).map((name, i) => (
            <div
              className="filter-option flex justify-between p-2 mb-1 add-pointer-hover hover:shadow"
              onClick={() =>
                setSubFilters({ label: name, value: values[name] })
              }
              key={i}
            >
              <h5 className=" text-gray-500">{name}</h5>
              <FontAwesomeIcon
                icon={faAngleRight}
                onClick={() =>
                  setSubFilters({ label: name, value: values[name] })
                }
                color="#a0afbf"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
FilterCategorySection.propTypes = {
  label: PropTypes.string,
  values: PropTypes.object
};
export default FilterCategorySection;
