import React, { useState, useRef, useMemo, useEffect } from "react";
import ParachuteIcon from "../Assets/images/ParachuteIcon.png";
import useLocalStorage from "../hooks/useLocalStorage";
import SettingsDropDown from "./SettingsDropDown";
import HelpModal from "./HelpModal";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import NewUserModal from "./NewUserModal";
import GeolocationModal from "./GeolocationModal";
import { useHistory } from "react-router-dom";
import useWebSockets from "../hooks/useWebSockets";
import InactivityTest from "./InactivityTest";
import oz_logo from "../Assets/images/oz_logo_.png";
import useErrModal from "../hooks/useErrModal";

const TopBar = () => {
  const {
    authToken,
    setAuthToken,
    setUserID,
    rws,
    setWS,
    setShowNewUserModal
  } = useAuth();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const api = useAPI(0);
  const v1 = useAPI(1);
  const containerRef = useRef(null);
  const [user, setUser] = useLocalStorage("oz-user", null);
  const history = useHistory();
  const ws = useWebSockets();
  const errorHandler = useErrModal();

  useEffect(() => {
    setWS(ws[0]);
  }, [ws, setWS]);
  useEffect(() => {
    if (authToken) {
      v1.get("user")
        .then(r => {
          if (!r.data.username) {
            setShowNewUserModal(true);
          }
        })
        .catch(err => errorHandler(err));
    }
  }, [authToken, v1, setShowNewUserModal, errorHandler]);

  const logout = (timeout = false) => {
    if (rws) {
      rws.close();
      rws._ws.close();
      setWS();
    }
    signOutUser();
    localStorage.setItem("oz-chatBotSID", null);
    setUser(null);
    setUserID(null);
    setAuthToken(null);
    // setLocation(null);
    setShowDropDown(false);
    if (!timeout) {
      history.push("/login");
    }
  };
  const signOutUser = () => {
    api
      .put("/signout")
      .then(r => {
        console.log(r);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickOutside = useMemo(
    () => event => {
      if (
        showDropDown &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    },
    [showDropDown, setShowDropDown, containerRef]
  );

  const renderIcons = () => {
    if (!authToken) return;
    return (
      <div className="link-container flex pt-2">
        <div
          className="parachute-container add-pointer-hover"
          onClick={() => setShowHelpModal(true)}
        >
          <img
            src={ParachuteIcon}
            className="help-icon w-6/12 mr-0"
            alt="help modal"
          />
        </div>
        <div className="settings-container" ref={containerRef}>
          <SettingsDropDown
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            logout={logout}
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showDropDown, setShowDropDown, containerRef, handleClickOutside]);

  return (
    <div className="bg-oz-blue text-white py-2 px-8 flex">
      <div className="flex-1">
        <div onClick={() => history.push("/")} className="add-pointer-hover">
          <img src={oz_logo} alt="alt" className="w-24" />
        </div>
      </div>
      {renderIcons()}
      <HelpModal
        showHelpModal={showHelpModal}
        setShowHelpModal={setShowHelpModal}
      />
      <NewUserModal />
      <GeolocationModal />
      {authToken ? <InactivityTest logout={logout} /> : ""}
    </div>
  );
};

export default TopBar;
