import React, { useEffect, useState, useMemo, createContext } from "react";
import useAuth from "../hooks/useAuth";
import customToast from "./customToast";
import ToastCallNotification from "./ToastCallNotification";
import CallModal from "./CallModal";
import MinimizedCallModalIcon from "./MinimizedCallModalIcon";
import ReactStopwatch from "react-stopwatch";
import { toast } from "react-toastify";
import {
  newChannelToAmbassador,
  yourAmbassadorIsReady
} from "../constants/notificationMessages";
import amazing_ringtone from "../Assets/sounds/amazing_ringtone.mp3";
import useCurrentUser from "../hooks/useCurrentUser";
import { useHistory } from "react-router-dom";
import useCadetModal from "../hooks/useCadetModal";
import useAPI from "../hooks/useAPI";
import { NEW_MESSAGE } from "../constants/CadetConsentOptions";
export const CallContext = createContext();
let audio = new Audio(amazing_ringtone);

function CallComponents() {
  const {
    senderPayload,
    isMinimizedCallModal,
    setShowCallModal,
    setIsMinimizedCallModal,
    showCallModal,
    isReceiver,
    setIsReceiver,
    setSenderPayload,
    rws
  } = useAuth();
  const currentUser = useCurrentUser();
  const api = useAPI(1);
  const history = useHistory();
  const cadetModalHandler = useCadetModal();
  const [time, setTime] = useState("00:00:00");
  const [remoteVideoMute, setRemoteVideoMute] = useState(false);
  const [userBeingCalled, setUserBeingCalled] = useState({
    id: "",
    name: "",
    username: "",
    profile_pic: "",
    service: "",
    component: "",
    mos: "",
    age: "",
    veteran: ""
  });
  //websocket functions
  const CloseButton = () => <></>;
  useEffect(() => {
    if (!rws || !currentUser) return;
    rws.addEventListener("message", message => {
      if (message.data === "ping" && rws.OPEN) {
        rws.send("pong");
      } else {
        const data = JSON.parse(message.data);
        if (data.method) {
          if (data.method === "show_call_modal") {
            audio.volume = 0.2;
            audio.play();
            let count = 0;
            let promise = audio.play();
            if (promise !== undefined) {
              promise.catch(err => {
                console.log(err);
              });
            }
            audio.addEventListener("ended", () => {
              if (count >= 1) {
                audio.pause();
              } else {
                audio.play();
                count++;
              }
            });
            setIsReceiver(true);
            if (!toast.isActive(`call-from${data.senderDetail.id}`)) {
              customToast.call(
                <ToastCallNotification
                  name={data.senderDetail.name || data.senderDetail.username}
                  profilePicture={data.senderDetail.profile_pic}
                  isFirstAvailable={data.isFirstAvailable}
                  setShowCallModal={setShowCallModal}
                  audio={audio}
                  setSenderPayload={setSenderPayload}
                  data={data}
                />,
                {
                  autoClose: 59000,
                  closeButton: CloseButton,
                  toastId: `call-from-${data.senderDetail.id}`
                }
              );
            }
          }
          if (data.method === NEW_MESSAGE) {
            if (
              !toast.isActive(
                `new-channel-${data.message.sender.senderDetail.id}`
              )
            ) {
              let timeout;
              if (currentUser.isAmbassador) {
                timeout = setTimeout(() => {
                  console.log("send message of inactivity");
                }, 30000);
              }
              customToast.call(
                currentUser.isAmbassador
                  ? newChannelToAmbassador
                  : yourAmbassadorIsReady,
                {
                  onClick: () => {
                    if (data.message.sender.senderDetail.isCadet === "0") {
                      history.push("/messages");
                    } else {
                      if (
                        data.message.sender.senderDetail.user_role === "user"
                      ) {
                        api
                          .get(
                            `cadets_program/${data.message.sender.senderDetail.id}`
                          )
                          .then(r => {
                            if (!r.data.hasConsented) {
                              const cadetInfo = {
                                ...data.message.sender.senderDetail,
                                method: NEW_MESSAGE,
                                channel_name: data.message.sender.channel_name
                              };
                              cadetModalHandler(cadetInfo);
                            } else {
                              history.push("/messages");
                            }
                          });
                      } else {
                        history.push("/messages");
                      }
                    }
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                  },
                  toastId: `new-channel-${data.message.sender.senderDetail.id}`
                }
              );
            }
          }
        } else {
          console.log("error parsing data though");
        }
      }
    });
  }, [
    rws,
    setIsReceiver,
    setSenderPayload,
    setShowCallModal,
    api,
    cadetModalHandler,
    currentUser,
    history
  ]);

  const stopwatch = useMemo(() => {
    if (!senderPayload) return;
    return (
      <ReactStopwatch
        seconds={0}
        minutes={0}
        hours={0}
        limit="23:59:59"
        onCallback={() => console.log("StopWatch")}
        render={({ formatted }) => {
          setTime(formatted);
          return "";
        }}
      />
    );
  }, [senderPayload]);

  return (
    <CallContext.Provider
      value={{
        showCallModal,
        setShowCallModal,
        payload: senderPayload,
        isReceiver,
        userBeingCalled,
        setUserBeingCalled,
        stopwatch: time,
        setTime,
        remoteVideoMute,
        setRemoteVideoMute,
        senderPayload,
        setIsMinimizedCallModal
      }}
    >
      {senderPayload && isMinimizedCallModal ? (
        <MinimizedCallModalIcon type={senderPayload.type} />
      ) : (
        ""
      )}
      <CallModal />
      {stopwatch}
    </CallContext.Provider>
  );
}

export default CallComponents;
