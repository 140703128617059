import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Image = ({ url, defaultUrl, classNames }) => {
  const [image, setImage] = useState();
  useEffect(() => {
    setImage(url);
  }, [url]);
  return (
    <>
      <img
        src={image}
        onError={() => setImage(defaultUrl)}
        className={classNames}
        alt="alt"
      />
    </>
  );
};
Image.propTypes = {
  url: PropTypes.string,
  defaultUrl: PropTypes.string,
  classNames: PropTypes.string
};

export default Image;
