import React from "react";
import AmbassadorPicture from "./AmbassadorPicture";
import customToast from "./customToast";
import { notAvailableToTextAmbassador } from "../constants/notificationMessages";
import FavoritesButton2 from "./FavoritesButton2";
import profilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function AmbassadorInfoWindow({
  ambassador: {
    name,
    username,
    age,
    profile_pic,
    service,
    component,
    veteran,
    mos,
    id,
    is_available_textChat,
    is_available_voiceCall,
    is_available_videoCall
  },
  ambassador,
  setShowInfoWindow,
  history,
  callUser,
  checkFavorite,
  favoriteOrUnfavorite,
  setActiveAmbassador
}) {
  const renderCorrectTitle = () => {
    if (ambassador.ambassador) {
      return <div className="mt-4 font-bold">Ambassador</div>;
    } else {
      return <div className="mt-4 font-bold">User</div>;
    }
  };
  const renderAge = () => {
    if (age) {
      return (
        <tr>
          <td className="text-oz-gray text-right pr-1">Age: </td>
          <td className="text-oz-blue">{age}</td>
        </tr>
      );
    } else {
      return "";
    }
  };
  const renderService = () => {
    if (service) {
      return (
        <tr>
          <td className="text-oz-gray text-right pr-1">Service: </td>
          <td className="text-oz-blue">{service}</td>
        </tr>
      );
    } else {
      return "";
    }
  };
  const renderComponent = () => {
    if (component) {
      return (
        <tr>
          <td className="text-oz-gray text-right pr-1">Component: </td>
          <td className="text-oz-blue">{component}</td>
        </tr>
      );
    } else {
      return "";
    }
  };
  const renderVeteran = () => {
    if (veteran) {
      return (
        <tr>
          <td className="text-oz-gray text-right pr-1">Veteran: </td>
          <td className="text-oz-blue">{veteran}</td>
        </tr>
      );
    } else {
      return "";
    }
  };
  const renderMOS = () => {
    if (mos) {
      return (
        <tr>
          <td className="text-oz-gray text-right pr-1">MOS: </td>
          <td className="text-oz-blue">{mos}</td>
        </tr>
      );
    } else {
      return "";
    }
  };
  const renderEmpty = () => {
    if (!mos && !veteran && !age && !service && !component) {
      return (
        <div className="text-gray-500 font-bold text-center pt-8 pl-4">
          User has no public information
        </div>
      );
    }
  };
  return (
    <div className="bg-transparent bg-opacity-0 " id="infoWindow">
      <div className="x-holder bg-black opacity-50 w-full flex justify-end pr-1 text-white">
        <button
          className="w-4 mr-2 mt-1 focus:outline-none hover:bg-white hover:text-black rounded-full"
          onClick={() => {
            setShowInfoWindow(false);
            setActiveAmbassador();
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </button>
      </div>
      <div className="flex justify-evenly bg-black bg-opacity-50 text-white pb-4 relative ">
        <button
          className="text-center flex-1 focus:outline-none"
          id="message-btn"
          onClick={() => {
            if (is_available_textChat === "1") {
              history.push(`/messages/${id}`);
            } else {
              customToast.warn(notAvailableToTextAmbassador);
            }
          }}
        >
          MESSAGE
        </button>

        <span className="bg-white w-px"></span>
        <button
          className="text-center flex-1 focus:outline-none"
          id="message-btn"
          onClick={() => callUser(id, is_available_voiceCall, "voice")}
        >
          CALL
        </button>
        <span className="bg-white w-px"></span>
        <button
          className="text-center flex-1 focus:outline-none"
          id="message-btn"
          onClick={() => callUser(id, is_available_videoCall, "video")}
        >
          VIDEO
        </button>
      </div>

      <div className="flex justify-between p-4 bg-white h-full pr-0 top-shadow">
        <div className="flex flex-col items-center text-oz-blue overflow-ellipsis">
          <AmbassadorPicture
            url={profile_pic || profilePictureMissing}
            size={20}
          />
          {renderCorrectTitle()}
          <div className="w-full truncate text-center">
            {name || username || "anonymous user"}
          </div>
        </div>
        <div className="w-4"></div>
        <div className="flex flex-col w-full">
          <div className="w-full self-end pr-4">
            <FavoritesButton2
              width={4}
              id={id}
              checkFavorite={checkFavorite}
              favoriteOrUnfavorite={favoriteOrUnfavorite}
              name={name || username}
            />
          </div>
          <div className="w-full flex justify-center border-t-2 border-gray-200 mt-2 pt-2 h-full">
            <table className="pr-4 pb-2 h-full">
              <tbody className="flex flex-col justify-center text-center h-full">
                {renderAge()}
                {renderService()}
                {renderComponent()}
                {renderMOS()}
                {renderVeteran()}
                {renderEmpty()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
AmbassadorInfoWindow.propTypes = {
  ambassador: PropTypes.object,
  setShowInfoWindow: PropTypes.func,
  history: PropTypes.any,
  callUser: PropTypes.func,
  checkFavorite: PropTypes.func,
  favoriteOrUnfavorite: PropTypes.func,
  setActiveAmbassador: PropTypes.func
};

export default AmbassadorInfoWindow;
