import React from "react";
import bubbles_of_ambassadors from "../Assets/images/bubbles_of_ambassadors.png";
import { useHistory } from "react-router-dom";

function BecomeAnAmbassador() {
  const history = useHistory();
  const becomeAmbassador = () => {
    history.push("/ambassador_test");
  };
  return (
    <div className="pb-8">
      <h1 className="text-blue-600 font-bold text-xl">Ambassador Program</h1>
      <div className="border border-blue-600 mb-8"></div>
      <div className="flex flex-col mb-4">
        <div className="title font-bold text-blue-600">
          What is an Ambassador?
        </div>
        An ambassador believes the simple act of listening can make a big
        difference in the lives of those in need of support
        <br />
        Ambassadors may be veterans, service members, behavioral health
        specialists, religious counselors, or just concerned citizens who are
        willing to lend an ear.
        <div className="m-2 p-4 pl-2">
          <img src={bubbles_of_ambassadors} alt="bubbles" />
        </div>
        <div className="title font-bold text-blue-600">
          What does it take to be an Ambassador?
        </div>
        Ambassadors undergo suicide prevention training and agree to make
        themselves available to those in-need for at least 30 minutes a week.
      </div>
      <div className="flex justify-center">
        <div
          className="bg-oz-blue flex justify-center text-align-center-alt width-increase-700 hover:bg-blue-700 w-3/12 text-white add-pointer-hover p-2 font-bold"
          onClick={becomeAmbassador}
        >
          Become an Ambassador
        </div>
      </div>
    </div>
  );
}

export default BecomeAnAmbassador;
