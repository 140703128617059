import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import useAPI from "../hooks/useAPI";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import PropTypes from "prop-types";
import useCadetModal from "../hooks/useCadetModal";
import { WAITING_USER } from "../constants/CadetConsentOptions";

const WaitingUserCard = ({ user }) => {
  const api = useAPI(1);
  const history = useHistory();
  const cadetModalHandler = useCadetModal();
  const localizeDate = date => {
    const localDate = new Date(`${date}Z`);
    return localDate.toString();
  };
  const reformattedDate = localizeDate(user.created_at);
  const joinChatWaitingRoomWithUser = id => {
    if (user.isCadet === "0") {
      history.push(`/messages/${id}`);
    } else {
      api.get(`cadets_program/${id}`).then(r => {
        const cadetInfo = {
          id: id,
          method: WAITING_USER
        };
        if (!r.data.hasConsented) {
          cadetModalHandler(cadetInfo);
        } else {
          history.push(`/messages/${id}`);
        }
      });
    }
  };
  return (
    <>
      <div
        className="bg-white w-6/12 p-4 m-1 shadow grid grid-cols-3 add-pointer-hover"
        onClick={() => joinChatWaitingRoomWithUser(user.user_id)}
      >
        <div className="icon-container mr-2 w-1/12">
          <FontAwesomeIcon icon={faCommentAlt} color={"lightgray"} />
        </div>
        <div className="w-full flex text-black">
          <h5>{user.username || user.name || "Not Provided"}</h5>
        </div>
        <div className="flex justify-end text-black">
          <TimeAgo date={reformattedDate} />
        </div>
      </div>
    </>
  );
};
WaitingUserCard.propTypes = {
  user: PropTypes.object
};
export default WaitingUserCard;
