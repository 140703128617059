import React from "react";
import PropTypes from "prop-types";

function CurrentlyShowing({ user_type }) {
  return (
    <div className="absolute w-full right-2 z-50 bg-black bg-opacity-50 text-white flex justify-center just-blur">
      Currently Showing {user_type ? user_type : "Both Ambassadors and Users"}
    </div>
  );
}

CurrentlyShowing.propTypes = {
  user_type: PropTypes.string
};

export default CurrentlyShowing;
