import React from 'react'

function MessagePicture({ url = 'https://api.adorable.io/avatars/285/random.png', size = 8 }) {
  const imgClass = `rounded-full border-oz-blue border-2 h-${size} w-${size}`

  if (url) {
    return <img alt="Ambassador" src={url} className={imgClass} />
  } else {
    return <div className={imgClass}></div>
  }
}

export default MessagePicture
