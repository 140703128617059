import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faExclamation
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import PropTypes from "prop-types";

function ToastMessage({ type = "info", msg }) {
  const iconHolders = classnames(
    "rounded-full flex justify-center items-center w-10 h-10 mr-2 mt-0.5",
    {
      "bg-red-600": type === "deny",
      "bg-green-600": type === "success",
      "bg-oz-blue": type === "info",
      "bg-yellow-700": type === "warn"
    }
  );
  const returnCorrectIcon = () => {
    if (type === "info") {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          color="white"
          size="sm"
          className="object-center"
        />
      );
    }
    if (type === "warn") {
      return (
        <FontAwesomeIcon
          icon={faExclamation}
          color="white"
          size="sm"
          className="object-center"
        />
      );
    }
    if (type === "deny") {
      return (
        <FontAwesomeIcon
          icon={faTimes}
          color="white"
          size="sm"
          className="object-center"
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          color="white"
          size="sm"
          className="object-center"
        />
      );
    }
  };
  const returnNotCallIcon = () => {
    if (type !== "call") {
      return <div className={iconHolders}>{returnCorrectIcon()}</div>;
    } else {
      return "";
    }
  };
  return (
    <div className="flex justify-evenly items-center">
      <div className="w-1/4">{returnNotCallIcon()}</div>
      <div className="mt-2">{msg}</div>
    </div>
  );
}
ToastMessage.propTypes = {
  type: PropTypes.string,
  msg: PropTypes.any
};
export default ToastMessage;
