import React from "react";
import AmbassadorPicture from "./AmbassadorPicture.js";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faVideo,
  faCommentAlt
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

function FavoritesCard({
  fav,
  val,
  selectedKey,
  setSelectedKey,
  history,
  callUser,
  unFavoriteUser
}) {
  const cardClasses = classnames(
    "favCards flex flex-col items-center add-pointer-hover p-4 w-48 rounded mb-8",
    {
      "bg-blue-600": val === selectedKey
    }
  );
  const titleClasses = classnames("font-bold text-xl flex items-end mt-4", {
    "text-white": val === selectedKey,
    "text-blue-600": val !== selectedKey
  });
  const closeClasses = classnames({
    "hover:text-blue-800": val === selectedKey,
    hidden: val !== selectedKey
  });
  const conClasses = classnames("FavCons flex justify-evenly w-full mt-4", {
    hidden: val !== selectedKey
  });
  return (
    <div className={cardClasses} onClick={() => setSelectedKey(val)}>
      <div className="text-white flex w-full justify-end h-2 ">
        <div className={closeClasses} onClick={() => unFavoriteUser(fav.id)}>
          X
        </div>
      </div>
      <AmbassadorPicture
        url={fav?.profile_pic || ProfilePictureMissing}
        size={20}
      />
      <h1 className={titleClasses}>{fav.username || fav.name}</h1>
      <div className={conClasses} id={val}>
        <div
          className="bg-blue-800 rounded-full px-3 py-2 hover:bg-white"
          onClick={() => history.push(`/messages/${fav.id}`)}
        >
          <FontAwesomeIcon icon={faCommentAlt} className="bgFavIconColor p-0" />
        </div>
        <div
          className="bg-blue-800 rounded-full px-3 py-2 hover:bg-white"
          onClick={() => callUser(fav.id, fav.is_available_voiceCall, "voice")}
        >
          <FontAwesomeIcon icon={faPhoneAlt} className="bgFavIconColor p-0" />
        </div>
        <div
          className="bg-blue-800 rounded-full px-3 py-2 hover:bg-white"
          onClick={() => callUser(fav.id, fav.is_available_videoCall, "video")}
        >
          <FontAwesomeIcon icon={faVideo} className="bgFavIconColor p-0" />
        </div>
      </div>
    </div>
  );
}
FavoritesCard.propTypes = {
  fav: PropTypes.object,
  val: PropTypes.string,
  selectedKey: PropTypes.string,
  setSelectedKey: PropTypes.func,
  history: PropTypes.object,
  callUser: PropTypes.func,
  unFavoriteUser: PropTypes.func
};

export default FavoritesCard;
