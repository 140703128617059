import React from "react";
import Modal from "react-bootstrap/Modal";
import "./BootstrapModalCSS.css";
import PropTypes from "prop-types";

const StaticModalBase = ({
  show,
  setShow,
  children,
  mt = 0,
  isVideo = false,
  isVoice = false
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        animation={false}
        className={`mt-${mt} ${isVideo ? "video-modal" : ""} ${
          isVoice ? "voice-modal" : ""
        }`}
        backdrop="static"
      >
        {children}
      </Modal>
    </>
  );
};

StaticModalBase.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  children: PropTypes.any,
  mt: PropTypes.number,
  isVideo: PropTypes.bool,
  isVoice: PropTypes.bool
};
export default StaticModalBase;
