import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const ErrorHandler = props => {
  return (
    <>
      {props.err ? (
        <div className="page-not-found-container w-8/12 flex justify-between">
          <div className="arrow-container flex">
            <div
              className="arrow-holder mr-2"
              onClick={() => props.history.push("/")}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="grey" />
            </div>
            <p onClick={() => props.history.push("/")}>Home</p>
          </div>
          <div className="warning-container ">
            <div className="warning-box flex shadow p-8 rounded">
              <div className="triangle-container">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  onClick={() => props.history.push("/")}
                  color="#F9C70C"
                  size="4x"
                />
              </div>
              <div className="error-message-container pt-4 pl-4">
                <p>
                  Sorry, but the page you&apos;re looking for wasn&apos;t found.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
ErrorHandler.propTypes = {
  err: PropTypes.bool,
  history: PropTypes.object,
  children: PropTypes.any
};
export default ErrorHandler;
