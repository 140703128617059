import React, { useContext, useEffect, useState } from "react";
import Loading from "./Loading";
import { AmbassadorContext } from "../pages/Connect";
import MessagePicture from "./MessagePicture";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function FavoritesConnect() {
  const {
    setActiveAmbassador,
    activeAmbassador,
    favorites,
    setShowInfoWindow,
  } = useContext(AmbassadorContext);
  const [currentFavs, setCurrentFavorites] = useState([]);
  const [page, setPage] = useState(1);
  const renderBlueOrWhiteBar = (user) => {
    if (activeAmbassador && activeAmbassador.id === user.id) {
      return <div className=" mt-1 border-t-2 border-blue-600"> </div>;
    } else if (activeAmbassador) {
      return <div className=" mt-1 border-t-2 border-white"> </div>;
    }
  };
  const activateAmbassadorFromFavoriteRing = (user) => {
    if (activeAmbassador) {
      if (activeAmbassador.id !== user.id) {
        setActiveAmbassador(user);
        setShowInfoWindow(true);
      } else {
        console.log("do nothing");
      }
    } else {
      setActiveAmbassador(user);
      setShowInfoWindow(true);
    }
  };
  useEffect(() => {}, [activeAmbassador]);
  useEffect(() => {
    if (favorites.length > 0) {
      const pageFavs = [];
      favorites.forEach((fav, i) => {
        if (page === 1) {
          if (i < 16) {
            pageFavs.push(fav);
          }
        } else {
          if (i < page * 16 && i >= (page - 1) * 16) {
            pageFavs.push(fav);
          }
        }
      });
      setCurrentFavorites(pageFavs);
    }
  }, [page, favorites]);

  let favoriteComponents;
  if (currentFavs.length === undefined) {
    favoriteComponents = <Loading />;
  } else if (favorites.length === 0) {
    favoriteComponents = <div>No favorited users online.</div>;
  } else {
    favoriteComponents = currentFavs.map((user, i) => {
      return (
        <div
          key={i}
          onClick={() => activateAmbassadorFromFavoriteRing(user)}
          className="cursor-pointer inline-block mr-2 mb-2"
        >
          <MessagePicture url={user.profile_pic || ProfilePictureMissing} />
          {renderBlueOrWhiteBar(user)}
        </div>
      );
    });
  }
  const renderNextPageIcon = () => {
    if (currentFavs.length === 16 && favorites.length > 16) {
      return (
        <FontAwesomeIcon
          icon={faAngleRight}
          onClick={() => setPage(page + 1)}
          color="#a0afbf"
          size="lg"
          className="ml-2 add-pointer-hover"
        />
      );
    }
  };

  const renderBackPageIcon = () => {
    if (page > 1) {
      return (
        <FontAwesomeIcon
          icon={faAngleLeft}
          onClick={() => setPage(page - 1)}
          color="#a0afbf"
          size="lg"
          className="mr-2 add-pointer-hover"
        />
      );
    }
  };
  return (
    <div>
      <h2 className="text-oz-blue font-bold mb-2">Online Favorites</h2>
      <div className="flex w-full">
        <div className="h-full pt-4">{renderBackPageIcon()}</div>
        <div className="connect-fav-height overflow-y-scroll">
          {favoriteComponents}
        </div>
        <div className="h-full pt-4">{renderNextPageIcon()}</div>
      </div>
    </div>
  );
}

export default FavoritesConnect;
