import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

function AmbassadorVideo({ setPart }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const nextPart = () => {
    if (!isDisabled) {
      setPart(2);
    }
  };
  const buttonClassNames = classnames(
    "flex justify-center rounded  w-1/12 text-white add-pointer-hover p-2 font-bold button-width-600",
    {
      "bg-oz-blue hover:bg-blue-700": !isDisabled,
      "bg-gray-600": isDisabled
    }
  );
  useEffect(() => {
    const video = document.querySelector("video");
    video.addEventListener("ended", () => {
      setIsDisabled(false);
    });
  }, []);
  return (
    <div className="flex flex-col items-center mt-8">
      <video
        controls
        className="w-6/12"
        controlsList="nodownload"
        // style="margin-top: 30px;margin-bottom: 40px; height : 200px"
      >
        <source
          src="https://s3-us-west-2.amazonaws.com/oz-assets-prod/adminAssets/video/file/5/PsychArmor_SAVE_Training_300p.mp4"
          type="video/mp4"
        />
      </video>
      <div className="w-full flex justify-end" onClick={nextPart}>
        <div className={buttonClassNames}>Next</div>
      </div>
    </div>
  );
}
AmbassadorVideo.propTypes = {
  setPart: PropTypes.func
};
export default AmbassadorVideo;
