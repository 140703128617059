import React, { useContext } from "react";
import AmbassadorCard from "./AmbassadorCard";
import Loading from "./Loading";
import { AmbassadorContext } from "../pages/Connect";

function AmbassadorList() {
  const { ambassadors, currentUser } = useContext(AmbassadorContext);

  const cards =
    ambassadors &&
    ambassadors.map((ambassador, i) => {
      return <AmbassadorCard key={i} ambassador={ambassador} />;
    });
  const renderRightTitle = () => {
    if (!currentUser) return;
    if (currentUser.isAmbassador) return "Users";
    if (!currentUser.isAmbassador) return "Ambassadors";
  };

  return (
    <div>
      <h2 className="text-oz-blue font-bold">{renderRightTitle()}</h2>
      <div className="overflow-y-auto ambassadorListHeight">
        {ambassadors ? cards : <Loading />}
      </div>
    </div>
  );
}

export default AmbassadorList;
