import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ProfileSubSectionOption from "./profileSubSectionOption";
import PropTypes from "prop-types";

function GenericSubSection({
  data,
  subSection,
  setSubSection,
  userData,
  setUserData,
  setShowSave
}) {
  const [selected, setSelected] = useState("");
  const capitalizeString = str => {
    if (str === "service_member") {
      return "Background";
    }
    if (str === "military_academy") {
      return "Academy";
    }
    if (str === "military_academy_year") {
      return "Graduating Class";
    }
    if (str === "veteran") {
      return "Campaigns Served";
    }
    if (str === "service") {
      return "Branch of Service";
    } else {
      const addSpaces = str.split("_").map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return addSpaces.join(" ");
    }
  };
  useEffect(() => {
    if (userData[subSection]) {
      setSelected(userData[subSection]);
    }
  }, [userData, subSection]);
  const saveAndReturnToMainScreen = () => {
    setSubSection();
    setShowSave(true);
    let stateToChange = { ...userData };
    stateToChange[subSection] = selected;
    setUserData(stateToChange);
  };
  return (
    <div className="w-full">
      <div className="flex w-4/12 font-bold text-blue-400 mt-2">
        <FontAwesomeIcon
          icon={faChevronLeft}
          color="#60A5FA"
          size="lg"
          onClick={saveAndReturnToMainScreen}
          className="mr-4"
        />{" "}
        {capitalizeString(subSection)}
      </div>
      <div className="pl-8 mt-4">
        {data.map((item, i) => {
          return (
            <ProfileSubSectionOption
              key={i}
              name={item}
              setSelected={setSelected}
              selected={selected}
            />
          );
        })}
      </div>
    </div>
  );
}
GenericSubSection.propTypes = {
  data: PropTypes.array,
  subSection: PropTypes.string,
  setSubSection: PropTypes.func,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  setShowSave: PropTypes.func
};
export default GenericSubSection;
