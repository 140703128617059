import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { InfoWindow } from "google-maps-react";
import PropTypes from "prop-types";

export default function InfoWindowEx(props) {
  const infoWindowRef = React.createRef();
  useEffect(() => {
    if (props.visible) {
      const contentElement = document.createElement(`div`);
      ReactDOM.render(React.Children.only(props.children), contentElement);
      infoWindowRef.current.infowindow.setContent(contentElement);
    }
  }, [props.children, infoWindowRef, props.visible]);
  return <InfoWindow ref={infoWindowRef} {...props} google={window.google} />;
}

InfoWindowEx.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool
};
