import React, { useState, useEffect, useRef, useContext } from "react";
import { MessagingContext } from "../pages/Messages";
import OwnMessage from "./OwnMessage";
import TheirMessage from "./TheirMessage";
import customToast from "./customToast";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  errorUnResponsiveAmbassador,
  successToNewAmbassador,
  successMessageSent,
  successToUserReachingOut,
  messageToFirstAvailableAmbassador,
  waitingRoomError,
  userAlreadyInWaitingRoom,
  messageLengthError,
} from "../constants/notificationMessages";
import { AMBASSADOR_INACTIVE } from "../constants/ChatConstants";
import useAuth from "../hooks/useAuth";
import jwt from "jsonwebtoken";
import useWindowSize from "../hooks/useWindowSize";
const IGNORE_PREFIX = "!@#$%^&*qwerty!@#$%^&*QWERTY";

function ChannelMessages() {
  const {
    twilio,
    partner,
    activeChannel,
    setActiveChannel,
    id,
    channels,
    setChannels,
    currentUser,
    v1,
    v0,
  } = useContext(MessagingContext);
  const { setSenderPayload, setShowCallModal, setUnavailableAmbassadorModal } =
    useAuth();
  const chatbox = useRef(null);
  const [channel, setChannel] = useState();
  const [messages, setMessages] = useState([]);
  const [outgoingMessage, setOutgoingMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const [width] = useWindowSize();
  let timeout;
  const isAuthoredByCurrentUser = (message) => {
    return message.author === jwt.decode(twilio.fpaToken).grants.identity;
  };

  const sendNotificationAndMessage = (e) => {
    e.preventDefault();
    if (outgoingMessage.length > 0) {
      if (!disabled) {
        setDisabled(true);
        twilio.getUserChannelDescriptors().then((paginator) => {
          let descriptor = paginator.items.find(
            (ch) => ch.uniqueName === channel.channelState.uniqueName
          );
          if (!descriptor) return;
          if (descriptor.membersCount === 1 && descriptor.messagesCount < 3) {
            customToast.info(
              currentUser.isAmbassador
                ? successToUserReachingOut
                : successToNewAmbassador
            );
            sendMessage();
            if (descriptor.messagesCount < 1) {
              descriptor.getChannel().then((ch) => {
                ch.invite(partner.twilio_identity);
                const newChannelListItem = {
                  channel: {
                    sender: currentUser.id,
                    receiver: partner.id,
                    channel_name: ch.channelState.uniqueName,
                    friendly_name: ch.channelState.friendlyName,
                    is_channel_created: true,
                  },
                  chatPartnerName: partner.name || "",
                  chatPartnerUsername: partner.username,
                  lastMessageEpochMilliseconds: moment().unix() * 1000,
                  unreadMessages: 0,
                };

                const channelCheck = channels.filter(
                  (chan) =>
                    chan.channel.channel_name === ch.channelState.uniqueName
                );
                setActiveChannel(newChannelListItem);
                if (channelCheck.length === 0) {
                  setChannels((channels) => [...channels, newChannelListItem]);
                }
                v0.post("twilio_chat_notification", {
                  receiver_id: partner.id,
                  channel_name: ch.channelState.uniqueName,
                })
                  .then((r) => console.log(r))
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          }
          if (descriptor.membersCount === 1 && descriptor.messagesCount >= 3) {
            customToast.warn(errorUnResponsiveAmbassador);
          }
          if (descriptor.membersCount > 1) {
            sendMessage();
            customToast.info(successMessageSent, { autoClose: 1500 });
          }
        });
      }
    } else {
      if (!toast.isActive("0-message")) {
        customToast.warn(messageLengthError, {
          toastId: "0-message",
          autoClose: 5000,
        });
      }
    }
  };

  const sendMessage = () => {
    channel.sendMessage(outgoingMessage).then(() => {
      setDisabled(false);
      setOutgoingMessage("");
    });
  };

  const callUser = (id, is_available_voiceCall) => {
    if (is_available_voiceCall) {
      v1.post(`call/user/${id}`, { type: "voice" })
        .then((r) => {
          setSenderPayload(r.data);
        })
        .catch((err) => {
          console.log(err);
        });

      setShowCallModal(true);
    } else {
      setUnavailableAmbassadorModal(true);
    }
  };

  useEffect(() => {
    setMessages([]);
    if (twilio) {
      twilio.getUserChannelDescriptors().then((paginator) => {
        let descriptor = paginator.items.find(
          (ch) => ch.uniqueName === activeChannel.channel.channel_name
        );
        if (!descriptor) {
          twilio
            .createChannel({
              uniqueName: `${activeChannel.channel.channel_name}`,
              friendlyName: `${activeChannel.channel.friendly_name}`,
            })
            .then((r) => {
              r.join();
              twilio.on("channelJoined", function (channel) {
                setChannel(channel);
              });
            })
            .catch((err) => {
              //This error is a rare error that occurred while I was testing and repeatedly deleting channels I just created, unlikely to happen to a user but just incase
              //to fix you must go into the DB and delete the twilio_channels row that associates the two users and 1 channel
              customToast.warn(
                "Sorry something went wrong, please contact support from the settings menu or try connecting with a different user/ambassador"
              );
              console.log(err);
            });
        }
        if (descriptor)
          descriptor.getChannel().then((ch) => {
            // ch.delete();
            setChannel(ch);
          });
      });
    }
  }, [twilio, activeChannel, setMessages, id]);

  useEffect(() => {
    if (!channel) return;

    channel.getMessages().then((messages) => {
      setMessages(
        messages.items.filter((m) => !m.body.includes(IGNORE_PREFIX))
      );
    });

    channel.on("messageAdded", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (
        !message.body.includes(IGNORE_PREFIX) &&
        message.state.author === currentUser.twilio_identity
      ) {
        if (!currentUser.isAmbassador && !timeoutDisabled) {
          if (!timeout) {
            timeout = setTimeout(() => {
              console.log("inactive ambassador");
              const inactiveMessage = {
                state: {
                  author: "system",
                  body: AMBASSADOR_INACTIVE,
                },
                dateCreated: message.dateCreated,
              };
              setMessages((messages) => [...messages, inactiveMessage]);
            }, 120000);
          }
        }
      }
      if (
        !message.body.includes(IGNORE_PREFIX) &&
        message.state.author !== currentUser.twilio_identity
      ) {
        console.log("clearing timeout");
        clearTimeout(timeout);
        setTimeoutDisabled(false);
      }
    });
    return () => channel.removeAllListeners();
  }, [channel, setMessages]);

  useEffect(() => {
    if (!channel) return;
    channel.setAllMessagesConsumed();
  }, [channel]);

  useEffect(() => {
    if (chatbox.current) {
      chatbox.current.scrollTop = chatbox.current.scrollHeight;
    }
  }, [messages, chatbox]);

  const disableTimeout = () => {
    setTimeoutDisabled(true);
    setMessages(messages.filter((m) => !m.state.body.includes(IGNORE_PREFIX)));
  };
  const getNewAmbassador = () => {
    v1.get("/chat/waitingRoomUsers/me")
      .then((r) => {
        if (r.status === 200) {
          customToast.warn(userAlreadyInWaitingRoom);
        } else {
          v1.post("/chat/waitingRoomUsers").then((r) => console.log(r));
          customToast.info(messageToFirstAvailableAmbassador);
          // history.push("/");
        }
      })
      .catch(() => {
        customToast.warn(waitingRoomError);
      });
  };
  return (
    <div className="flex flex-col h-full padding-bottom-add">
      <h2 className="text-oz-blue font-bold mb-8 margin-bottom-two">
        Messages with{" "}
        {activeChannel.chatPartnerName || activeChannel.chatPartnerUsername}
      </h2>

      <div className="flex-1 overflow-y-auto pr-4 pb-2" ref={chatbox}>
        {messages.map((message, i) => {
          if (isAuthoredByCurrentUser(message)) {
            return <OwnMessage key={i} message={message} />;
          } else {
            return (
              <TheirMessage
                key={i}
                user={partner}
                message={message}
                disableTimeout={disableTimeout}
                getNewAmbassador={getNewAmbassador}
              />
            );
          }
        })}
      </div>
      <div>
        <form onSubmit={(e) => sendNotificationAndMessage(e)} className="flex">
          <div
            className="bg-oz-blue rounded-full p-4 m-2 smaller-padding add-pointer-hover w-12 h-12"
            onClick={() => callUser(partner.id, partner.is_available_voiceCall)}
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              color="white"
              size={width < 860 ? "sm" : "2xl"}
              className={width < 860 ? "mt-4 ml-2" : "mb-2"}
            />
          </div>
          <input
            type="text"
            className="rounded border border-oz-lightgray h-12 self-center mr-8 flex-1 overflow-y-auto pl-2"
            value={outgoingMessage}
            onChange={(e) => setOutgoingMessage(e.target.value)}
          />

          <input
            type="submit"
            className="bg-white text-oz-blue  add-pointer-hover focus:outline-none"
            value="SEND"
          />
        </form>
      </div>
    </div>
  );
}

export default ChannelMessages;
