import React, { useState, useEffect, useContext } from "react";
import { AmbassadorContext } from "../pages/Connect";
import useAPI from "../hooks/useAPI";
import classnames from "classnames";

const SupportSwitch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPI(1);
  const {
    setUserType,
    getSupport,
    setGetSupport,
    giveSupport,
    setGiveSupport,
  } = useContext(AmbassadorContext);

  const barClassNames = classnames(
    "w-full text-white flex justify-evenly bg-black"
  );

  useEffect(() => {
    setIsLoading(true);
    api
      .get("user")
      .then((r) => {
        setIsLoading(false);
        if (r.data.ambassador.can_give_support === "1") {
          setGiveSupport(true);
        }
        if (r.data.ambassador.can_get_support === "1") {
          setGetSupport(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [api, setGetSupport, setGiveSupport]);
  const switchSupport = () => {
    if (!isLoading) {
      setIsLoading(true);
      setGetSupport(!getSupport);
      setGiveSupport(!giveSupport);
      api
        .patch("ambassador", {
          canGetSupport: !getSupport === true ? 1 : 0,
          canGiveSupport: !giveSupport === true ? 1 : 0,
        })
        .then((r) => {
          if (r.data.ambassador.can_get_support === "1") {
            setUserType("Ambassadors Only");
          } else {
            setUserType("Users Only");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={barClassNames} onClick={switchSupport}>
      <div>
        Click here to{" "}
        <span className="font-bold">{getSupport ? "give" : "get"}</span> support
      </div>
    </div>
  );
};

export default SupportSwitch;
