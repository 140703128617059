import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { AmbassadorContext } from "../pages/Connect";

const SubFilterSection = () => {
  const { setSubFilters, subFilters, updateCurrentFilters } = useContext(
    AmbassadorContext
  );

  const fireUpdateCurrentFilters = value => {
    const obj = {};
    switch (subFilters.label) {
      case "Branch of Service":
        obj["service"] = value;
        break;
      case "Campaigns Served":
        obj["veteran"] = value;
        break;
      default:
        obj[subFilters.label.toLowerCase().replace(" ", "_")] = value;
        break;
    }
    updateCurrentFilters(obj);
  };
  return (
    <>
      <div className="filter-section flex flex-col bg-white mt-2 w-full">
        <div className="flex w-full justify-between">
          <h5
            className="text-gray-500 text-sm font-bold add-pointer-hover"
            onClick={() => setSubFilters()}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              onClick={() => setSubFilters()}
              color="#3182ce"
              size="lg"
              className="mr-2"
            />
            {subFilters.label}
          </h5>
        </div>
        <div className="filter-section-cards p-2 ">
          {Object.values(subFilters.value).map((name, i) => (
            <div
              className="filter-option flex justify-between  p-3 mb-2 add-pointer-hover hover:shadow"
              onClick={() => fireUpdateCurrentFilters(name)}
              key={i}
            >
              <h5 className="text-gray-600">{name}</h5>
            </div>
          ))}
          {/* <div
            className="filter-option flex shadow p-3 mb-2"
            onClick={() => setSubFilters()}
          >
            
            <h5 className="text-blue-600 text-xs font-bold ml-2 add-pointer-hover">
              More Filters
            </h5>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default SubFilterSection;
