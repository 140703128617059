import React from "react";
import ModalBase from "./ModalBase";
import PropTypes from "prop-types";

const HelpModal = props => {
  return (
    <ModalBase
      show={props.showHelpModal}
      setShow={props.setShowHelpModal}
      mt={48}
    >
      <div className="flex w-full justify-end pr-2">
        <div
          className="add-pointer-hover"
          onClick={() => props.setShowHelpModal(false)}
        >
          x
        </div>
      </div>
      <div className="flex w-full justify-center mb-2 font-bold text-blue-600 text-xl p-2">
        Need some help?
      </div>
      <div className="m-1 px-8 text-center mb-4">
        If you are having thoughts about hurting yourself or others, or if you
        feel you are in imminent danger, please call
        <span className="font-bold text-red-600"> 911</span>
      </div>

      <div className="flex flex-col m-1 px-8 text-center mb-4">
        You can also call the Veterans Crisis Line for immediate aid 24/7
      </div>
      <div className="w-full flex justify-center mt-2">
        <button className=" mb-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-6/12">
          <a href="tel:1-800-273-8255">1-800-273-8255</a>
        </button>
      </div>
    </ModalBase>
  );
};
HelpModal.propTypes = {
  showHelpModal: PropTypes.bool,
  setShowHelpModal: PropTypes.func
};
export default HelpModal;
