import { useEffect } from "react";
import useAPI from "./useAPI";
import useLocalStorage from "./useLocalStorage";
import useAuth from "./useAuth";
import useErrModal from "./useErrModal";
function useCurrentUser() {
  const api = useAPI();
  const api0 = useAPI(0);
  const { authToken } = useAuth();
  const [user, setUser] = useLocalStorage("oz-user", null);
  const errorHandler = useErrModal();

  useEffect(() => {
    if (user) return;
    api
      .get("user")
      .then(resp => {
        let {
          id,
          lat,
          lng,
          twilio_identity,
          assistantSID1,
          name,
          username,
          profile_pic
        } = resp.data;
        let isAmbassador = false;
        if (resp.data.ambassador) {
          isAmbassador = true;
        }
        let obj = {
          id: id,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          isAmbassador: isAmbassador,
          twilio_identity: twilio_identity,
          assistantSID1: assistantSID1,
          name,
          username,
          profile_pic
        };
        if (obj.isAmbassador) {
          obj["ambassador"] = resp.data.ambassador;
        }
        setUser(obj);
      })
      .catch(err => errorHandler(err));
  }, [api, api0, authToken, errorHandler]);

  return user;
}

export default useCurrentUser;
