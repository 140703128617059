import { useCallback } from "react";
import useAuth from "./useAuth";
function useCadetModal() {
  const { setCadetInfo, setShowCadetModal } = useAuth();

  return useCallback(
    cadetInfo => {
      setCadetInfo(cadetInfo);
      setShowCadetModal(true);
    },
    [setCadetInfo, setShowCadetModal]
  );
}

export default useCadetModal;
