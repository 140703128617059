import React, { useState, useEffect } from "react";
import AboutObjectiveZero from "../components/AboutObjectiveZero";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions";
import SoftwareLicense from "../components/SoftwareLicense";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

function About() {
  const [about, setAbout] = useState("oz");
  const [isLoading, setIsLoading] = useState(true);
  const { type } = useParams();
  useEffect(() => {
    setAbout(type);
    setIsLoading(false);
  }, [type, setIsLoading, setAbout]);
  const renderCorrectInfo = () => {
    if (about === "oz") {
      return <AboutObjectiveZero />;
    }
    if (about === "pp") {
      return <PrivacyPolicy />;
    }
    if (about === "tc") {
      return <TermsAndConditions />;
    }
    if (about === "sl") {
      return <SoftwareLicense />;
    } else {
      return <AboutObjectiveZero />;
    }
  };
  return (
    <Loader isLoading={isLoading}>
      <div className="flex w-full justify-center">
        <div className="w-9/12 pl-6 main-div-padding-alt ">
          {renderCorrectInfo()}
        </div>
      </div>
    </Loader>
  );
}

export default About;
