import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { AuthContext } from "./hooks/useAuth";
import useLocalStorage from "./hooks/useLocalStorage";
import TopBar from "./components/TopBar";
import Navigation from "./components/Navigation";
import Login from "./pages/Login";
import Connect from "./pages/Connect";
import Messages from "./pages/Messages";
import Resources from "./pages/Resources";
import About from "./pages/About";
import Help from "./pages/Help";
import CodeValidator from "./pages/CodeValidator";
import ResourceDetails from "./pages/ResourceDetails";
import ErrorModal from "./components/ErrorModal";
import PageNotFound from "./pages/PageNotFound";
// import ChatBot from "./components/ChatBot";
import { ToastContainer } from "react-toastify";
import { Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnavailableAmbassadorModal from "./components/UnavailableCallAmbassadorModal";
import CallComponents from "./components/CallComponents";
import ProfileSettings from "./pages/ProfileSettings";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AmbassadorTest from "./pages/AmbassadorTest";
import Favorites from "./pages/Favorites";
import "./mediaQueries.css";
import OnMobileModal from "./components/OnMobileModal";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";
import CadetModal from "./components/CadetModal";

function App() {
  const [authToken, setAuthToken] = useLocalStorage("oz-token", null);
  const [location, setLocation] = useLocalStorage("oz-location", null);
  const [userID, setUserID] = useLocalStorage("oz-userID", null);
  const [isReceiver, setIsReceiver] = useState(false);
  const [senderPayload, setSenderPayload] = useState();
  const [appAudioTracks, setAppAudioTracks] = useState([]);
  const [remoteAudioTracks, setRemoteAudioTracks] = useState([]);
  const [remoteVideoTracks, setRemoteVideoTracks] = useState([]);
  const [showCallModal, setShowCallModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showGeoLocationModal, setShowGeoLocationModal] = useState(false);
  const [isMinimizedCallModal, setIsMinimizedCallModal] = useState(false);
  const [showCadetModal, setShowCadetModal] = useState(false);
  const [isFirstAvailableCall, setIsFirstAvailableCall] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [cadetInfo, setCadetInfo] = useState();
  const [rws, setWS] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [err, setErr] = useState({ message: "" });
  const [unavailableAmbassadorModal, setUnavailableAmbassadorModal] = useState(
    false
  );
  const [allowedLocation, setAllowedLocation] = useState();

  useEffect(() => {
    var chromeTest =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    setIsChrome(chromeTest);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setUserID,
        userID,
        rws,
        setWS,
        authToken,
        setAuthToken: setAuthToken,
        showCallModal,
        setShowCallModal,
        setSenderPayload,
        setUnavailableAmbassadorModal,
        unavailableAmbassadorModal,
        setIsMinimizedCallModal,
        isMinimizedCallModal,
        appAudioTracks,
        setAppAudioTracks,
        remoteAudioTracks,
        setRemoteAudioTracks,
        isReceiver,
        setIsReceiver,
        senderPayload,
        showErrorModal,
        setShowErrorModal,
        setErr,
        setShowNewUserModal,
        showNewUserModal,
        location,
        setLocation,
        setShowCadetModal,
        showCadetModal,
        setCadetInfo,
        setIsFirstAvailableCall,
        isFirstAvailableCall,
        remoteVideoTracks,
        setRemoteVideoTracks,
        setShowGeoLocationModal,
        showGeoLocationModal,
        allowedLocation,
        setAllowedLocation,
        isChrome,
      }}
    >
      <Router>
        <ErrorBoundaryWrapper>
          <TopBar />
          {authToken ? <Navigation></Navigation> : ""}
          <ToastContainer transition={Slide} />
          <div className="px-8 main-div-padding-alt ">
            <Switch>
              <AuthenticatedRoute exact path="/" component={Connect} />
              <Route path="/login" component={Login} />
              <AuthenticatedRoute
                path="/messages/:optionalId?"
                component={Messages}
              />
              <AuthenticatedRoute path="/about/:type" component={About} />
              <AuthenticatedRoute
                path="/ambassador_test"
                component={AmbassadorTest}
              />
              <AuthenticatedRoute path="/help/:type" component={Help} />
              <AuthenticatedRoute
                path="/profile_settings/:type"
                component={ProfileSettings}
              />
              <AuthenticatedRoute path="/404" component={PageNotFound} />
              <AuthenticatedRoute
                exact
                path="/resources"
                component={Resources}
              />
              <AuthenticatedRoute
                exact
                path="/favorites"
                component={Favorites}
              />
              <AuthenticatedRoute
                path="/details/:type/:id"
                component={ResourceDetails}
              />
              <Route
                exact
                path="/TermsAndConditions"
                component={TermsAndConditions}
              />
              <Route path="/PPolicy" component={PrivacyPolicy} />
              <Route path="/verify-code" component={CodeValidator} />
              <Redirect to="/" />
            </Switch>
          </div>
          {/* {authToken ? <ChatBot /> : ""} */}
          {authToken ? <CallComponents /> : ""}
          <ErrorModal err={err} />
          {authToken ? <CadetModal cadetInfo={cadetInfo} /> : ""}
          <UnavailableAmbassadorModal />
          <OnMobileModal />
        </ErrorBoundaryWrapper>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
