import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import GoogleLogin from "react-google-login";
import oz_logo from "../Assets/images/oz_logo_icon.png";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import oz_splash from "../Assets/images/oz-splash1.jpg";
import email_icon from "../Assets/images/email_icon-1.png";
import customToast from "../components/customToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
function Login() {
  const api = useAPI(2);
  const { setAuthToken, setUserID } = useAuth();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [subText, setSubText] = useState("");
  const [email, setEmail] = useState("");

  // const forceLogin = () => {
  //   setAuthToken("");
  //   setAuthenticated(true);
  // };

  const updateEmail = e => {
    setEmail(e.target.value);
  };
  const sendMagicLink = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      if (email.length > 0) {
        let params = {
          email: email,
          deviceId: "web",
          deviceType: "web",
          veteranData: null
        };
        api
          .post("auth/sendEmailLink", params)
          .then(r => {
            setIsDisabled(false);
            if (r.status === 200 || r.status === 201) {
              customToast.success(
                "An email has been sent to you, please follow the link to login",
                { autoClose: false }
              );
            }
          })
          .catch(err => {
            console.log(err);
            setIsDisabled(false);
            customToast.warn(
              "Something went wrong, please try entering your email again"
            );
          });
      } else {
        setSubText("*Please enter your email");
        setIsDisabled(false);
      }
    }
  };
  const fetchOZToken = (email, name, googleId, googleToken) => {
    setIsDisabled(true);
    let params = {
      email: email,
      name: name,
      google_id: googleId,
      google_token: googleToken,
      deviceId: "web",
      deviceType: "web"
    };
    api
      .post("auth/google", params)
      .then(resp => {
        setIsDisabled(false);
        setUserID(resp.data.id);
        setAuthToken(resp.data.access_token);
        setAuthenticated(true);
      })
      .catch(err => {
        console.log(err);
        setIsDisabled(false);
        customToast.warn("Something went wrong, please try again");
      });
  };

  let responseGoogle = response => {
    if (response.profileObj) {
      fetchOZToken(
        response.profileObj.email,
        response.profileObj.name,
        response.googleId,
        response.accessToken
      );
    }
  };

  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <div className="flex justify-center object-center w-full h-screen pt-16 pb-24 padding-top-alt-600">
      <div className="w-7/12 shadow flex justify-between bg-white rounded width-alt-1000 add-center-flex-alt custom-tablet-login-view">
        <div className="w-6/12 m-0 hidden-alt-700">
          <img
            src={oz_splash}
            alt="alt"
            className="object-fill h-full rounded-l m-0"
          />
        </div>
        <div className="flex flex-col items-center justify-between p-8 w-6/12 pb-2 almost-full-width-alt">
          <div className="w-3/12 self-center flex justify-center">
            <img src={oz_logo} alt="alt" className="w-full safari-half-image" />
          </div>
          <div className="w-full flex items-center flex flex-col p-2 ">
            <h1 className="text-blue-500 text-2xl font-bold mb-2 margin-bottom-none-600">
              Welcome!
            </h1>
            <p className="mb-2 login-button-alt">
              Enter your email address to sign in OR create an account. Tap the
              link we send to your email and you&apos;re all set. Easy!
            </p>
          </div>
          <div className="email_form pb-2 flex justify-center flex-col w-full padding-bottom-none-600">
            <div className="flex">
              <img
                src={email_icon}
                alt="alt"
                className="w-6 pb-2 mr-2 safari-half-image"
              />
              <input
                className="border-b border-gray-300 focus:outline-none w-full"
                placeholder="Email"
                onChange={updateEmail}
                value={email}
              />
            </div>
            <div className="text-sm text-red-500 pl-6 mt-2">{subText}</div>
            <div
              className="border-2 border-white p-2 login-button-alt text-white bg-oz-blue rounded w-8/12 m-2 self-center text-center add-pointer-hover"
              onClick={sendMagicLink}
            >
              Sign In With Email Link
            </div>
            <div className="flex flex-row justify-between w-full mt-4 mb-4 margin-y-alt">
              <div className="border-b-2 border-blue-500 w-4/12 h-1  mt-2"></div>
              <div className="w-4/12 flex justify-center text-blue-500">or</div>
              <div className="border-b-2 border-blue-500 w-4/12 h-1 mt-2"></div>
            </div>
          </div>
          <div className="other_logins flex flex-col items-center w-full">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  className="border-2 border-white p-2 login-button-alt text-white bg-blue-400 rounded w-8/12 m-2 justify-evenly"
                  disabled={isDisabled}
                >
                  <FontAwesomeIcon icon={faGoogle} className="mr-2" />
                  Sign in with Google
                </button>
              )}
            />
          </div>
          <div className="text-xs mt-2 align-end w-full">
            By signing in I agree to Objective Zero&apos;s{" "}
            <a
              className="text-blue-700"
              href="https://www.objectivezero.org/legal"
            >
              Terms & Conditions
            </a>{" "}
            and I have viewed the{" "}
            <a
              className="text-blue-700"
              href="https://www.objectivezero.org/privacy"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
