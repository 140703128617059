import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Loader from "../components/Loader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";

function CodeValidator() {
  const location = useLocation();
  const history = useHistory();
  const { setAuthToken, setUserID } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const api = useAPI(2);
  const [code, setCode] = useState();
  useEffect(() => {
    setCode(location.search.split("=")[1]);
  }, [location, setCode]);
  useEffect(() => {
    if (code && !isAuthenticated) {
      let params = {
        v_token: "web",
        deviceId: "web",
        deviceType: "web",
        appVersion: "1.0.0",
        code: code
      };
      api
        .post("auth/validateCode", params)
        .then(r => {
          if (r.status === 200) {
            setAuthToken(r.data.access_token);
            setUserID(r.data.id);
            setIsAuthenticated(true);
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [code, api, isAuthenticated, setUserID, setIsLoading]);
  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <Loader isLoading={isLoading}>
      <div>
        <div className="page-not-found-container w-8/12 flex justify-between mt-10">
          <div className="arrow-container flex">
            <div
              className="arrow-holder mr-2"
              onClick={() => history.push("/login")}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="grey" />
            </div>
            <p onClick={() => history.push("/login")}>Login</p>
          </div>
          <div className="warning-container ">
            <div className="warning-box flex shadow p-8 rounded">
              <div className="triangle-container">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  onClick={() => history.push("/login")}
                  color="#F9C70C"
                  size="4x"
                />
              </div>
              <div className="error-message-container pt-4 pl-4">
                <p>Sorry, but something went wrong please try again</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default CodeValidator;
