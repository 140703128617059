import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import classnames from "classnames";
import PropTypes from "prop-types";

function FavoritesButton2({ id, checkFavorite, favoriteOrUnfavorite, name }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const favClasses = classnames(
    "text-center rounded-full px-2 py-1 add-pointer-hover",
    {
      "border-2 border-blue-500 text-oz-blue  ": !isFavorite,
      "border-2 border-white bg-oz-blue text-white": isFavorite
    }
  );
  useEffect(() => {
    setIsFavorite(checkFavorite(id));
  }, [id, checkFavorite]);
  useEffect(() => {}, [isFavorite]);

  const favoriteUser = () => {
    setIsLoading(true);
    setIsFavorite(!isFavorite);
    favoriteOrUnfavorite(isFavorite, id, name);
    setIsLoading(false);
  };
  const returnCorrectWord = () => {
    if (isFavorite) {
      return "Unfavorite";
    }
    if (!isFavorite) {
      return "Favorite";
    }
  };
  return (
    <div className="flex justify-end" onClick={favoriteUser}>
      <Loader isLoading={isLoading}>
        <div className={favClasses}>{returnCorrectWord()}</div>
      </Loader>
    </div>
  );
}
FavoritesButton2.propTypes = {
  id: PropTypes.string,
  checkFavorite: PropTypes.func,
  favoriteOrUnfavorite: PropTypes.func,
  name: PropTypes.string
};

export default FavoritesButton2;
