import { toast } from "react-toastify";
import React from "react";
import ToastMessage from "./ToastMessage";
const customToast = {
  success(msg, options = {}) {
    return toast(<ToastMessage type={"success"} msg={msg} />, {
      ...options,
      className: "new-info add-blur",
      bodyClassName: "new-info-body ",
      hideProgressBar: true
    });
  },
  warn(msg, options = {}) {
    return toast(<ToastMessage type={"warn"} msg={msg} />, {
      ...options,
      className: "new-warn add-blur",
      bodyClassName: "new-warn-body",
      hideProgressBar: true
    });
  },
  info(msg, options = {}) {
    return toast(<ToastMessage type={"info"} msg={msg} />, {
      ...options,
      className: "new-info add-blur",
      bodyClassName: "new-info-body",
      hideProgressBar: true
    });
  },
  deny(msg, options = {}) {
    return toast(<ToastMessage type={"deny"} msg={msg} />, {
      ...options,
      className: "new-info add-blur",
      bodyClassName: "new-info-body",
      hideProgressBar: true
    });
  },
  call(msg, options = {}) {
    return toast(<ToastMessage type={"call"} msg={msg} />, {
      ...options,
      className: "new-info add-blur",
      bodyClassName: "new-info-body",
      hideProgressBar: true
    });
  }
};
export default customToast;
