import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { AmbassadorContext } from "../pages/Connect";
import useAuth from "../hooks/useAuth";

function GoogleMap({ options, className, onMount, width }) {
  const ref = useRef();
  const { int } = useContext(AmbassadorContext);
  const { isChrome } = useAuth();
  const [map, setMap] = useState();

  // Setup Google Maps <script> tag and callbacks
  useEffect(() => {
    if (options.center) {
      const onLoad = () =>
        setMap(new window.google.maps.Map(ref.current, { ...options }));

      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=geometry&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        document.head.append(script);
        script.addEventListener("load", onLoad);
        return () => script.removeEventListener("load", onLoad);
      } else onLoad();
    }
  }, [options.center]);

  useEffect(() => {
    if (map && typeof onMount === "function") onMount(map);
  }, [map]);

  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  const returnCorrectHeight = () => {
    if (isChrome) {
      return "100%";
    } else {
      return "100%";
    }
  };

  return (
    <div style={{ height: returnCorrectHeight() }} {...{ ref, className }} />
  );
}
GoogleMap.propTypes = {
  options: PropTypes.object,
  className: PropTypes.string,
  onMount: PropTypes.func
};
export default GoogleMap;
