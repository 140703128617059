import React from "react";
import Modal from "react-bootstrap/Modal";
import "./BootstrapModalCSS.css";
import PropTypes from "prop-types";

const ModalBase = ({
  show,
  setShow,
  children,
  mt = 0,
  isVideo = false,
  isUnavailable = false
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        animation={false}
        className={`mt-${mt} ${isVideo ? "video-modal" : ""} ${
          isUnavailable ? "unavailable-modal" : ""
        }`}
      >
        {children}
      </Modal>
    </>
  );
};

ModalBase.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  children: PropTypes.any,
  mt: PropTypes.number,
  isVideo: PropTypes.bool,
  isUnavailable: PropTypes.bool
};
export default ModalBase;
