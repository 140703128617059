import React from "react";
import PropTypes from "prop-types";

const Loader = props => {
  return (
    <>
      {props.isLoading ? (
        <div className="loader-holder">
          <div className="loader"></div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
Loader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any
};
export default Loader;
