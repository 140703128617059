import React, { useEffect, useState } from "react";
import useAPI from "../hooks/useAPI";
import FavoritesCard from "../components/FavoritesCard";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loader from "../components/Loader";
function Favorites() {
  const api = useAPI(1);
  const {
    setSenderPayload,
    setShowCallModal,
    setUnavailableAmbassadorModal
  } = useAuth();
  const [favorites, setFavorites] = useState([]);
  const [selectedKey, setSelectedKey] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const callUser = (id, is_available_voiceCall, type) => {
    if (is_available_voiceCall) {
      api
        .post(`call/user/${id}`, { type: type })
        .then(r => {
          setSenderPayload(r.data);
        })
        .catch(err => {
          console.log(err);
        });
      setShowCallModal(true);
    } else {
      setUnavailableAmbassadorModal(true);
    }
  };
  const unFavoriteUser = id => {
    api
      .delete(`favorite/${id}`)
      .then(() => {
        setFavorites(favorites.filter(fav => fav.id !== id));
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    api
      .get("favorite")
      .then(r => {
        setFavorites(r.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  }, [api, setFavorites, setIsLoading]);
  const renderFavoritesOrNone = () => {
    if (favorites.length > 0) {
      return favorites.map((fav, i) => (
        <FavoritesCard
          fav={fav}
          val={fav.id}
          key={i}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          history={history}
          callUser={callUser}
          unFavoriteUser={unFavoriteUser}
        />
      ));
    } else {
      return (
        <div className="flex justify-center w-full text-gray-600 font-bold text-2xl">
          No favorites to show
        </div>
      );
    }
  };
  return (
    <div>
      <div className="resource-header">Your Favorites</div>
      <Loader isLoading={isLoading}>
        <div className="flex justify-evenly flex-wrap mt-8 overflow-scroll">
          {renderFavoritesOrNone()}
        </div>
      </Loader>
    </div>
  );
}

export default Favorites;
