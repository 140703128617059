import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import PropTypes from "prop-types";

function AuthenticatedRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
AuthenticatedRoute.propTypes = {
  component: PropTypes.func,
};

export default AuthenticatedRoute;
