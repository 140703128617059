import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function profileSubSectionOption({ name, selected, setSelected }) {
  return (
    <div
      className="font-bold text-gray-600 border-b-2 border-gray-200 flex justify-between mb-2"
      onClick={() => setSelected(name)}
    >
      {name}
      {selected === name ? (
        <FontAwesomeIcon icon={faCheck} color="green" size="sm" />
      ) : (
        ""
      )}
    </div>
  );
}

export default profileSubSectionOption;
