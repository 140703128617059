import React, { useRef, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faVideo,
  faVideoSlash
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import useAuth from "../hooks/useAuth";
import { CallContext } from "./CallComponents";
import PropTypes from "prop-types";

const MinimizedCallModalIcon = ({ type }) => {
  const audioRef = useRef();
  const videoRef = useRef();
  const audioRefRemote = useRef();
  const {
    userBeingCalled,
    setShowCallModal,
    setIsMinimizedCallModal,
    stopwatch,
    remoteVideoMute
  } = useContext(CallContext);
  const { appAudioTracks, remoteAudioTracks, remoteVideoTracks } = useAuth();

  const componentClasses = classnames(
    "rounded flex justify-between pt-4 pb-4 pr-4 pl-4 add-pointer-hover relative",
    {
      " opacity-50 bg-black w-64": type === "voice",
      "video-dimensions": type === "video"
    }
  );

  useEffect(() => {
    const audioTrack = appAudioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [appAudioTracks]);

  useEffect(() => {
    const audioTrack = remoteAudioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRefRemote.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [remoteAudioTracks]);

  useEffect(() => {
    if (type === "video") {
      const videoTrack = remoteVideoTracks[0];
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        return () => {
          videoTrack.detach();
        };
      }
    }
  }, [remoteVideoTracks, type]);

  const returnName = () => {
    if (userBeingCalled) {
      return userBeingCalled.name || userBeingCalled.username;
    } else {
      return "";
    }
  };
  const returnRightContent = () => {
    if (type === "video") {
      return (
        <div className="flex flex-col relative">
          {remoteVideoMute ? (
            <div className="bg-black w-full h-full absolute custom-z">
              <div className="flex w-full h-full justify-center items-center">
                <FontAwesomeIcon
                  icon={faVideoSlash}
                  color={"white"}
                  size="lg"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <video
            ref={videoRef}
            autoPlay={true}
            className="relative mb-96 mr-10 w-full"
            playsInline
            id={`video-link-${userBeingCalled.id}`}
          />
          <div className="bg-black opacity-50 w-full flex justify-between p-4">
            <div className="flex">
              <FontAwesomeIcon
                icon={type === "video" ? faVideo : faPhoneAlt}
                color="white"
                size="lg"
                className="pt-1"
              />
              <div className="text-white mr-2 ml-2">|</div>
              <div className="text-white">{stopwatch}</div>
            </div>
            <div>
              <div className="text-white">{returnName()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="absolute add-blur mr-10 mb-20"></div>
          <div className="flex">
            <FontAwesomeIcon
              icon={type === "video" ? faVideo : faPhoneAlt}
              color="white"
              size="lg"
              className="pt-1"
            />
            <div className="text-white mr-2 ml-2">|</div>
            <div className="text-white">{stopwatch}</div>
          </div>
          <div>
            <div className="text-white">{returnName()}</div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="bot-holder right-0 mr-20 flex justify-end fixed pr-2 slide-up-animation">
      <div
        className={componentClasses}
        onClick={() => {
          setShowCallModal(true);
          setIsMinimizedCallModal(false);
        }}
      >
        {returnRightContent()}
      </div>
      <audio ref={audioRef} autoPlay={true} muted={true} />
      <audio ref={audioRefRemote} autoPlay={true} muted={false} />
    </div>
  );
};
MinimizedCallModalIcon.propTypes = {
  type: PropTypes.string
};
export default MinimizedCallModalIcon;
