export const USER_DECLINED_TEXT_FORWARDING =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,UserDeclinedTextForwarding";
export const USER_TEXT_FORWARDED =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,UserTextWasForwarded";
export const USER_OFFLINE =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,UserIsOfflineOnChat";
export const USER_ONLINE =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,UserIsOnlineOnChat";
export const USER_TEXT_FORWARDING_WAS_ANSWERED_PREFIX =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,UserTextForwardingWasAnswered,Message=";
export const AMBASSADOR_INACTIVE =
  "!@#$%^&*qwerty!@#$%^&*QWERTY,ObjectiveZero.OZ,INACTIVE_AMBASSADOR";
