import React from "react";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import useAPI from "../hooks/useAPI";
import PropTypes from "prop-types";
import useCadetModal from "../hooks/useCadetModal";
import { INCOMING_CALL } from "../constants/CadetConsentOptions";

const ToastCallNotification = ({
  name,
  isFirstAvailable,
  profilePicture,
  setShowCallModal,
  setSenderPayload,
  data,
  audio
}) => {
  const api = useAPI(1);
  const cadetModalHandler = useCadetModal();
  const answerCall = () => {
    audio.pause();
    if (data.senderDetail.isCadet === "0") {
      setSenderPayload(data);
      setShowCallModal(true);
    } else {
      api.get(`cadets_program/${data.senderDetail.id}`).then(r => {
        if (!r.data.hasConsented) {
          const cadetInfo = {
            ...data,
            method: INCOMING_CALL
          };
          cadetModalHandler(cadetInfo);
        } else {
          setSenderPayload(data);
          setShowCallModal(true);
        }
      });
    }
  };
  const cancelCall = () => {
    audio.pause();
    let obj = {
      state: "cancel"
    };
    if (isFirstAvailable) {
      obj.state = "busy";
    }
    api
      .post("call/cancel", obj)
      .then(r => {
        console.log(r);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const renderCorrectText = () => {
    if (data.type === "video") {
      return `Video call from ${name}`;
    } else {
      if (isFirstAvailable) {
        return `${name} is in need of support`;
      } else {
        return `Incoming call from ${name}`;
      }
    }
  };
  return (
    <div className="flex font-bold text-white justify-evenly">
      <div className="mr-4">
        <img
          src={profilePicture ? profilePicture : ProfilePictureMissing}
          alt="call notification"
          className="inline-block rounded-full border-white border-2 w-16 h-16 "
        />
      </div>
      <div className="flex items-center">{renderCorrectText()}</div>
      <div className="flex flex-col justify-center">
        <button
          onClick={answerCall}
          className="bg-green-500 hover:bg-green-700 text-white font-bold p-1 rounded mb-1"
        >
          Answer
        </button>
        <button
          onClick={cancelCall}
          className="bg-red-500 hover:bg-red-700 text-white font-bold p-1 rounded"
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};
ToastCallNotification.propTypes = {
  name: PropTypes.string,
  isFirstAvailable: PropTypes.bool,
  profilePicture: PropTypes.string,
  setShowCallModal: PropTypes.func,
  setSenderPayload: PropTypes.func,
  data: PropTypes.object,
  audio: PropTypes.object
};
export default ToastCallNotification;
