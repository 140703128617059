import React, { useState, useEffect, createContext } from "react";
import useAPI from "../hooks/useAPI";
import useTwilio from "../hooks/useTwilio";
import useCurrentUser from "../hooks/useCurrentUser";
import Favorites from "../components/Favorites";
import ChannelList from "../components/ChannelList";
import ChannelMessages from "../components/ChannelMessages";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import MessagePartner from "../components/MessagePartner";
import PropTypes from "prop-types";
import useCadetModal from "../hooks/useCadetModal";
import { EXISTING_NEW_MESSAGE } from "../constants/CadetConsentOptions";

export const MessagingContext = createContext();

const Messages = props => {
  const currentUser = useCurrentUser();
  const [channels, setChannels] = useState([]);
  const [activeChannel, setActiveChannel] = useState();
  const [partner, setPartner] = useState();
  const [favorites, setFavorites] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const cadetModalHandler = useCadetModal();
  const { optionalId } = useParams();
  const v0 = useAPI(0);
  const v1 = useAPI(1);
  const twilio = useTwilio();
  const history = props.history;

  const context = {
    channels,
    setChannels,
    activeChannel,
    setActiveChannel,
    favorites,
    setFavorites,
    id: optionalId,
    twilio,
    currentUser,
    partner,
    setIsLoading,
    isLoading,
    v0,
    v1,
    history
  };

  useEffect(() => {
    v0.get("message_history")
      .then(resp => {
        setChannels(resp.data);
        if (optionalId) {
          const filteredForPreviousMessageArray = resp.data.filter(
            obj => obj.channel.receiver === optionalId
          );
          if (filteredForPreviousMessageArray.length < 1) {
            v1.get(`user/${optionalId}`).then(resp => {
              setPartner(resp.data);
              v0.post("/twilio_channels", {
                sender: currentUser.id,
                receiver: optionalId
              }).then(r => {
                const newActiveChannel = {
                  channel: r.data.response,
                  chatPartnerName: resp.data.name,
                  chatPartnerUsername: resp.data.username,
                  unreadMessages: 0,
                  lastMessageEpochMilliseconds: new Date().getTime()
                };
                setIsLoading(false);
                setActiveChannel(newActiveChannel);
              });
            });
          } else {
            setIsLoading(false);
            setActiveChannel(filteredForPreviousMessageArray[0]);
          }
        } else {
          setIsLoading(false);
          setActiveChannel(resp.data[0]);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });

    v1.get("favorite")
      .then(resp => {
        setFavorites(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [currentUser.id, optionalId, v0, v1]);

  useEffect(() => {
    if (activeChannel) {
      let { sender, receiver } = activeChannel.channel;
      let partnerId = [receiver, sender].find(
        userId => currentUser.id !== userId
      );
      v1.get(`user/${partnerId}`).then(resp => {
        setPartner(resp.data);
        if (resp.data.isCadet === "1") {
          v1.get(`cadets_program/${resp.data.id}`).then(r => {
            if (!r.data.hasConsented) {
              const cadetInfo = {
                id: resp.data.id,
                method: EXISTING_NEW_MESSAGE,
                channel_name: activeChannel.channel.channel_name
              };
              cadetModalHandler(cadetInfo);
            }
          });
        }
      });
    }
  }, [activeChannel, currentUser, v1, cadetModalHandler]);
  const renderNoMessages = () => {
    if (isLoading) {
      return <Loader isLoading={isLoading} />;
    } else {
      if (!activeChannel) {
        return (
          <div className="flex h-full items-center">
            <div className="flex justify-center w-full text-gray-600 font-bold text-2xl text-center">
              Select an Ambassador or channel <br />
              to begin talking to them
            </div>
          </div>
        );
      } else {
        return;
      }
    }
  };

  return (
    <MessagingContext.Provider value={context}>
      <div
        className="flex mb-8 messages-container-alt"
        style={{
          height: "calc(100vh - 200px)",
          maxHeight: "calc(100vh - 200px)"
        }}
      >
        <div className="w-1/4 full-width">
          <div className="mb-4 display-none-alt">
            <Favorites />
          </div>
          <Loader isLoading={isLoading}>
            <ChannelList />
          </Loader>
        </div>

        <div
          id="divider"
          className="w-px bg-oz-lightgray mx-8 display-none-alt"
        ></div>

        <div className="flex-1">
          {twilio && activeChannel && (
            <ChannelMessages
              twilio={twilio}
              partner={partner}
              ozChannel={activeChannel}
            />
          )}
          {renderNoMessages()}
        </div>

        <div
          id="divider"
          className="w-px bg-oz-lightgray mx-8 display-none-alt"
        ></div>

        <div className="pr-8 w-1/4 message-partner-container">
          <MessagePartner user={partner} />
        </div>
      </div>
    </MessagingContext.Provider>
  );
};

Messages.propTypes = {
  history: PropTypes.object
};

export default Messages;
