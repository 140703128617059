import React, { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";
import classNames from "classnames";
import WaitingUserCard from "./WaitingUserCard";
import "./SlideUpAnimation.css";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";

const AmbassadorFirstAvailableBar = () => {
  const v1 = useAPI(1);
  const [showOptions, setShowOptions] = useState(true);
  const [waitingUsers, setWaitingUsers] = useState([]);
  const currentUser = useCurrentUser();
  const { isChrome } = useAuth();
  const subMenuClasses = classNames(
    "w-full bg-gray-200 p-2 flex flex-col items-center",
    { hidden: showOptions }
  );
  const barClasses = classNames(
    "w-full relative flex justify-center items-center flex-col divide-x divide-white text-white shadow-inner add-pointer-hover rounded",
    {
      "add-fade-in": !showOptions
    }
  );

  const subBarClasses = classNames(
    "w-full bg-black bg-opacity-50 just-blur flex justify-center  p-2",
    { "mb-6": currentUser.isAmbassador && showOptions }
  );

  useEffect(() => {
    v1.get("/chat/waitingRoomUsers")
      .then(r => setWaitingUsers(r.data))
      .catch(err => {
        console.log(err);
      });
    const interval = setInterval(() => {
      v1.get("/chat/waitingRoomUsers")
        .then(r => setWaitingUsers(r.data))
        .catch(err => {
          console.log(err);
        });
    }, 60000);
    return () => clearInterval(interval);
  }, [v1]);

  return (
    <div className="w-full absolute bottom-0 z-50 h-auto custom-max overflow-scroll">
      <div className={barClasses} onClick={() => setShowOptions(!showOptions)}>
        <div className={subBarClasses}>{waitingUsers.length} Users Waiting</div>
        <div className={subMenuClasses}>
          {waitingUsers.map(user => (
            <WaitingUserCard user={user} key={user.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default AmbassadorFirstAvailableBar;
