import React, { useState, useEffect, createContext } from "react";
import moment from "moment";
import useLocation from "../hooks/useLocation";
import useAPI from "../hooks/useAPI";
import Filters from "../components/Filters";
import SupportSwitch from "../components/SupportSwitch";
import ConnectMap from "../components/ConnectMap";
import AmbassadorList from "../components/AmbassadorList";
import FirstAvailableBar from "../components/FirstAvailableBar";
import AmbassadorFirstAvailableBar from "../components/AmbassadorFirstAvailableBar";
import FavoritesConnect from "../components/FavoritesConnect";
import { useHistory } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import useCurrentUser from "../hooks/useCurrentUser";
import CurrentlyShowing from "../components/CurrentlyShowing";
import classnames from "classnames";
import useAuth from "../hooks/useAuth";
export const AmbassadorContext = createContext();

function Connect() {
  const [ambassadors, setAmbassadors] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeAmbassador, setActiveAmbassador] = useState();
  const [currentDay, setCurrentDay] = useState("");
  const [currentFilters, setCurrentFilters] = useState({});
  const [allFilters, setAllFilters] = useState();
  const [subFilters, setSubFilters] = useState();
  const [user_type, setUserType] = useState();
  const currentUser = useCurrentUser();
  const [getSupport, setGetSupport] = useState(false);
  const [giveSupport, setGiveSupport] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSorry, setIsSorry] = useState(false);
  const [width] = useWindowSize();
  const history = useHistory();
  const api = useAPI();
  const apiZero = useAPI(0);
  const { location } = useLocation();
  const { allowedLocation } = useAuth();

  useEffect(() => {
    const day = moment().format("dddd");
    setCurrentDay(day);
  }, []);
  useEffect(() => {
    if (ambassadors.length > 0) {
      api
        .get("favorite")
        .then(r => {
          let onlineAmbassadors = [];
          r.data.forEach(obj => {
            ambassadors.forEach(fav => {
              if (fav.id === obj.id) {
                onlineAmbassadors.push(fav);
              }
            });
          });
          setFavorites(onlineAmbassadors);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [api, setFavorites, ambassadors]);
  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.ambassador) {
      if (
        currentUser.ambassador.can_get_support === 1 ||
        currentUser.ambassador.can_get_support === true
      ) {
        setUserType("Ambassadors Only");
      } else {
        setUserType();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser || !currentDay) return;
    api
      .get(
        `user/available?lat=${currentUser.lat ? currentUser.lat : 0.0}&lng=${
          currentUser.lng ? currentUser.lng : 0.0
        }&distance=${
          currentFilters.distance ? currentFilters.distance[0] : ""
        }&user_type=${user_type ? user_type : ""}&member_type=${
          currentFilters.member_type ? currentFilters.member_type[0] : ""
        }&mos=${currentFilters.mos ? currentFilters.mos[0] : ""}&veteran=${
          currentFilters.veteran ? currentFilters.veteran[0] : ""
        }&service=${
          currentFilters.service ? currentFilters.service[0] : ""
        }&gender=${currentFilters.gender ? currentFilters.gender[0] : ""}&age=${
          currentFilters.age ? currentFilters.age[0] : ""
        }&component=${
          currentFilters.component ? currentFilters.component[0] : ""
        }&time=51682&days_available=${currentDay}`
      )
      .then(resp => {
        setAmbassadors(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
    apiZero
      .get("/filters")
      .then(resp => {
        const data = { ...resp.data.response };
        const remodeledData = {
          Services: {
            "Member Type": data["Member Type"],
            "Branch of Service": data["Service"],
            "Campaigns Served": data["Veteran"],
            Component: data["Component"],
            MOS: data["MOS"]
          },
          ////the sketch doesn't have school and the mobile app does, by simply uncommenting this we can add it back it
          // School: {
          //   Academy: data["Military Academy"],
          //   "Graduating Class": data["Military Academy Year"],
          //   "ROTC Program": data["ROTC Program"]
          // },
          Distance: {
            Distance: data["Distance"]
          },
          "Personal Attributes": {
            Gender: data["Gender"],
            "Age Range": data["Age Range"]
          }
        };
        setAllFilters(remodeledData);
      })
      .catch(err => {
        console.log(err);
      });
  }, [currentFilters, currentUser, user_type, currentDay, api, apiZero]);

  const updateCurrentFilters = obj => {
    const stateToChange = { ...currentFilters };
    const keyValuePairsOfObject = Object.entries(obj);
    keyValuePairsOfObject.forEach(([key, value]) => {
      if (!stateToChange[key]) {
        stateToChange[key] = [value];
      } else if (!stateToChange[key].includes(value)) {
        stateToChange[key] = [...stateToChange[key], value];
      }
    });
    setCurrentFilters(stateToChange);
  };

  const renderCorrectBlueBar = () => {
    if (!currentUser) return;
    if (currentUser.ambassador && giveSupport) {
      return <AmbassadorFirstAvailableBar />;
    }
    return <FirstAvailableBar />;
  };
  const renderSupportSwitch = () => {
    if (!currentUser) return;
    if (currentUser.ambassador) {
      return <SupportSwitch />;
    } else {
      return "";
    }
  };
  const renderCurrentlyShowing = () => {
    if (!currentUser) return;
    if (currentUser.ambassador) {
      return <CurrentlyShowing user_type={user_type} />;
    } else {
      return "";
    }
  };

  const connectClasses = classnames("flex mb-8 pb-2", {
    "flex-col": width <= 800
  });
  const usersClasses = classnames(" relative", {
    "w-1/4": width > 800,
    "w-full p-4 mt-4": width <= 800
  });

  return (
    <AmbassadorContext.Provider
      value={{
        ambassadors,
        currentFilters,
        setCurrentFilters,
        activeAmbassador,
        setActiveAmbassador,
        setSubFilters,
        subFilters,
        setAllFilters,
        allFilters,
        updateCurrentFilters,
        history,
        currentUser,
        setUserType,
        user_type,
        favorites,
        setFavorites,
        getSupport,
        setGetSupport,
        giveSupport,
        setGiveSupport,
        setShowInfoWindow,
        showInfoWindow,
        setIsLoading,
        setIsSorry
      }}
    >
      <div className={connectClasses}>
        <div className="flex-1">
          {renderSupportSwitch()}
          <div className="relative w-full h-full">
            {isLoading ? (
              <div className="gmap-overlay" id="gmap-overlay">
                <div className="loader-holder flex-col items-center custom-padding-bottom">
                  <div className="loader"></div>
                  {isSorry ? (
                    <div className="font-bold text-white mt-4 text-shadow">
                      Almost there...
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {renderCurrentlyShowing()}
            <Filters />
            {renderCorrectBlueBar()}
            <ConnectMap center={allowedLocation || location} zoom={4} />
          </div>
        </div>

        <div id="divider" className="w-px bg-oz-lightgray mx-8"></div>

        <div className={usersClasses}>
          <FavoritesConnect />
          <AmbassadorList />
        </div>
      </div>
    </AmbassadorContext.Provider>
  );
}

export default Connect;
