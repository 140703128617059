import React from "react";
import age_icon from "../Assets/images/age_icon.png";
import city from "../Assets/images/city.png";
import component from "../Assets/images/component.png";
import gender_icon from "../Assets/images/gender_icon.png";
import mos_icon from "../Assets/images/mos_icon.png";
import servicesMember_icon from "../Assets/images/services-Member_icon.png";
import specialties_icon from "../Assets/images/specialities_icon.png";
import state from "../Assets/images/state.png";
import veteran_icon from "../Assets/images/veteran_icon.png";
import zip from "../Assets/images/zip.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PropTypes from "prop-types";

function MainEditSection({ data, setSubSection, updateState }) {
  const focusInput = () => {
    const el = document.getElementById("zip_code");
    el.focus();
  };
  const iconClasses = "w-8 mr-2 safari-full-image h-8";
  return (
    <div className="w-full">
      <div className="section-holder flex flex-col">
        <h1 className="font-bold text-blue-600 mt-2">Personal Info</h1>
        <div className="pl-20 less-padding">
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("gender")}
          >
            <div className="flex">
              <img src={gender_icon} className={iconClasses} alt="icon" />{" "}
              Gender
            </div>
            <div>
              {data.gender ? data.gender : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("date_of_birth")}
          >
            <div className="flex">
              <img src={age_icon} className={iconClasses} alt="icon" /> Age
            </div>
            <div>
              {data.date_of_birth
                ? moment().diff(data.date_of_birth, "years")
                : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 add-pointer-hover"
            onClick={focusInput}
          >
            <div className="flex">
              <img src={zip} className={iconClasses} alt="icon" /> Zip Code
            </div>
            <div className="flex justify-end">
              <input
                type="text"
                className="focus:outline-none w-6/12 text-right"
                id="zip_code"
                placeholder={data.zip_code ? data.zip_code : ""}
                onChange={e => updateState(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-holder flex flex-col">
        <h1 className="font-bold text-blue-600 mt-2">Career Info</h1>
        <div className="pl-20 less-padding">
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("member_type")}
          >
            <div className="flex">
              <img
                src={servicesMember_icon}
                className={iconClasses}
                alt="icon"
              />{" "}
              Member Type
            </div>
            <div>
              {data.member_type ? data.member_type : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("specialties")}
          >
            <div className="flex">
              <img src={specialties_icon} className={iconClasses} alt="icon" />{" "}
              Specialties
            </div>
            <div>
              {data.specialties ? data.specialties : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("service_member")}
          >
            <div className="flex">
              <img
                src={servicesMember_icon}
                className={iconClasses}
                alt="icon"
              />{" "}
              Background
            </div>
            <div>
              {data.service_member ? data.service_member : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-holder flex flex-col mb-10">
        <h1 className="font-bold text-blue-600 mt-2">Military Info</h1>
        <div className="pl-20 less-padding">
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("service")}
          >
            <div className="flex">
              <img
                src={servicesMember_icon}
                className={iconClasses}
                alt="icon"
              />{" "}
              Branch of Service
            </div>
            <div>
              {data.service ? data.service : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("mos")}
          >
            <div className="flex">
              <img src={mos_icon} className={iconClasses} alt="icon" /> MOS
            </div>
            <div>
              {data.mos ? data.mos : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("veteran")}
          >
            <div className="flex">
              <img src={veteran_icon} className={iconClasses} alt="icon" />{" "}
              Campaigns Served
            </div>
            <div>
              {data.veteran ? data.veteran : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("component")}
          >
            <div className="flex">
              <img src={component} className={iconClasses} alt="icon" />{" "}
              Component
            </div>
            <div>
              {data.component ? data.component : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("military_academy")}
          >
            <div className="flex">
              <img src={city} className={iconClasses} alt="icon" /> Academy
            </div>
            <div>
              {data.military_academy ? data.military_academy : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("military_academy_year")}
          >
            <div className="flex">
              <img
                src={servicesMember_icon}
                className="w-8 mr-2 safari-full-image z-50"
                alt="icon"
              />{" "}
              Graduating Class
            </div>
            <div>
              {data.military_academy_year ? data.military_academy_year : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
          <div
            className="border-b-2 border-gray-200 flex justify-between w-11/12 mt-4 hover:bg-gray-100 add-pointer-hover"
            onClick={() => setSubSection("rotc_program")}
          >
            <div className="flex">
              <img
                src={state}
                className="w-8 mr-2 safari-full-image z-50"
                alt="icon"
              />{" "}
              ROTC Program
            </div>
            <div>
              {data.rotc_program ? data.rotc_program : ""}{" "}
              <FontAwesomeIcon icon={faChevronRight} color="gray" size="sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
MainEditSection.propTypes = {
  data: PropTypes.object,
  setSubSection: PropTypes.func,
  updateState: PropTypes.func
};
export default MainEditSection;
