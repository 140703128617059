import React, { useContext, useEffect } from "react";
import Loading from "./Loading";
import { MessagingContext } from "../pages/Messages";
import MessagePicture from "./MessagePicture";
import ProfilePictureMissing from "../Assets/images/ProfilePictureMissing.png";
import { useHistory } from "react-router-dom";

function Favorites() {
  const { favorites, channels, setActiveChannel, activeChannel } = useContext(
    MessagingContext
  );
  const history = useHistory();
  const renderBlueOrWhiteBar = user => {
    if (
      activeChannel &&
      (activeChannel.channel.sender === user.id ||
        activeChannel.channel.receiver === user.id)
    ) {
      return <div className=" mt-1 border-t-2 border-blue-600"> </div>;
    } else if (activeChannel) {
      return <div className=" mt-1 border-t-2 border-white"> </div>;
    }
  };
  let favoriteComponents;
  const activateChannelWithFavorite = user => {
    let channel = channels.find(ch => {
      return ch.channel.sender == user.id || ch.channel.receiver == user.id;
    });
    if (channel) {
      setActiveChannel(channel);
    } else {
      history.push(`/messages/${user.id}`);
    }
  };
  useEffect(() => {}, [activeChannel]);

  if (favorites === undefined) {
    favoriteComponents = <Loading />;
  } else if (favorites.length === 0) {
    favoriteComponents = <div>No favorited users.</div>;
  } else {
    favoriteComponents = favorites.map((user, i) => {
      return (
        <div
          key={i}
          onClick={() => activateChannelWithFavorite(user)}
          className="cursor-pointer inline-block mr-2 mb-2"
        >
          <MessagePicture url={user.profile_pic || ProfilePictureMissing} />
          {renderBlueOrWhiteBar(user)}
        </div>
      );
    });
  }

  return (
    <div>
      <h2 className="text-oz-blue font-bold mb-2">Favorites</h2>
      <div className="">{favoriteComponents}</div>
    </div>
  );
}

export default Favorites;
