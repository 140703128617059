import React, { useState } from "react";
import {
  messageToFirstAvailableAmbassador,
  userAlreadyInWaitingRoom
} from "../constants/notificationMessages";
import useAPI from "../hooks/useAPI";
import customToast from "./customToast";
import classNames from "classnames";
import "./SlideUpAnimation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faVideo } from "@fortawesome/free-solid-svg-icons";
import messagesIcon from "../Assets/images/messages_inactive.png";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";

const FirstAvailableBar = () => {
  const v1 = useAPI(1);
  const [showOptions, setShowOptions] = useState(true);
  const currentUser = useCurrentUser();
  const {
    setShowCallModal,
    setSenderPayload,
    setIsFirstAvailableCall,
    isChrome
  } = useAuth();

  const messageFirstAvailableAmbassador = () => {
    v1.get("/chat/waitingRoomUsers/me").then(r => {
      if (r.status === 200) {
        customToast.warn(userAlreadyInWaitingRoom);
      } else {
        v1.post("/chat/waitingRoomUsers").then(r => console.log(r));
        customToast.info(messageToFirstAvailableAmbassador);
      }
    });
  };

  const startFirstAvailableCall = type => {
    setIsFirstAvailableCall(true);
    v1.post("call/available", { type: type })
      .then(r => {
        setSenderPayload(r.data);
      })
      .catch(err => {
        console.log(err);
      });
    setShowCallModal(true);
  };

  const subMenuClasses = classNames(
    "w-full relative z-50 bottom-0 grid grid-cols-3 divide-x divide-white pt-1",
    { hidden: showOptions }
  );
  const barClasses = classNames(
    "flex relative justify-center items-center flex-col bg-black bg-opacity-50 just-blur divide-x divide-white text-white add-pointer-hover p-1 py-2",
    {
      "add-fade-in": !showOptions,
      "mb-6": currentUser ? currentUser.isAmbassador : false
    }
  );

  return (
    <div className="w-full absolute bottom-0 z-50 h-auto overflow-hidden">
      <div className={barClasses} onClick={() => setShowOptions(!showOptions)}>
        Contact First Available Ambassador
        <div className={subMenuClasses}>
          <div
            className="text-holder text-white text-center py-2 add-pointer-hover flex justify-center"
            onClick={messageFirstAvailableAmbassador}
          >
            <img src={messagesIcon} alt="alt name" className="w-6 mr-4" />
            Message
          </div>
          <div
            className="text-holder text-white text-center p-2 add-pointer-hover flex justify-center"
            onClick={() => startFirstAvailableCall("voice")}
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              color={"white"}
              size=""
              className="mr-4 mt-1"
            />{" "}
            Call
          </div>
          <div
            className="text-holder text-white text-center p-2 add-pointer-hover flex justify-center"
            onClick={() => startFirstAvailableCall("video")}
          >
            <FontAwesomeIcon
              icon={faVideo}
              color={"white"}
              size=""
              className="mr-4 mt-1"
            />{" "}
            Video
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstAvailableBar;
